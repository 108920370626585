import React from "react";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import ButtonForModal from "./ButtonForModal";
import checkmark from "./images/Path 97/Path 97.png";

export default function SettingInstructionsInternational() {
  const { dispatch, setIsOpenPopup } = useSelectPopupPomponent();
  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col">
      <div className="save-payment-method  w-[calc(151px+15.729vw)]    md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="w-[calc(151px+15.729vw)] space-y-4 p-[calc(10px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="bold-26 teal">SETTINGS INSTRUCTIONS</div>
        <div className="bold-26 teal">International Travel</div>
        <div className="normal-18 rounded-md bg-teal-500/60  p-3">
          Take a screenshot of these instructions for when you want to use Ride
          Mobile internationally
        </div>
        <div className="bold-18">
          Just before arriving at your international destination:{" "}
        </div>
        <ol className="normal-18 list-inside list-decimal space-y-3">
          <li>
            Go to Settings {`>`} Cellular or Mobile Data
            <div className="flex gap-1">
              <div>- </div>
              <div>
                Select your Ride eSIM (If you haven't labeled your eSIM to
                “Ride” it might appear with a different name e.g. Travel or
                Business)
              </div>
            </div>
            <div className="flex gap-1">
              <div>- </div>
              <div>Turn the Ride Mobile eSIM ON</div>
            </div>
            <div className="flex gap-1">
              <div>- </div>
              <div>Turn ON “Data Roaming” on your Ride Mobile eSIM</div>
            </div>
          </li>
          <li>
            Go to Settings {`>`} Cellular or Mobile Data {`>`} Cellular Data or
            Mobile Data
            <div className="flex gap-1">
              <div>- </div>
              <div>
                Select your Ride Mobile eSIM to be used for Cellular/Mobile Data
                (the check mark moves to Ride
                {
                  <img
                    src={checkmark}
                    alt="√"
                    className="inline-block h-3 md:h-2 sm:h-2"
                  ></img>
                }
                )
              </div>
            </div>
            <div className="flex gap-1">
              <div>- </div> On the same screen ensure “Allow Cellular Data
              Switching” is OFF
            </div>
          </li>
          <li>
            Return to the Ride Mobile App to activate your plan on the Manage
            Plans screen
          </li>
        </ol>
        <div className="flex gap-5 md:flex-col md:gap-3 sm:mt-4">
          <ButtonForModal
            color="teal"
            onClick={() => {
              dispatch(setIsOpenPopup(false));
            }}
          >
            Continue
          </ButtonForModal>
        </div>
      </div>
    </div>
  );
}
