import { createSlice } from "@reduxjs/toolkit";
let initialState = {
  toastMsg: "",
  type: "",
};

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setToaster: (state, action) => {
      state.toastMsg = action.payload.msg;
      state.type = action.payload.type;
    },
  },
});
export const { setToaster } = toastSlice.actions;
export default toastSlice.reducer;
