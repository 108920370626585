import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const vouchersApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      redeemProduct: builder.mutation({
        query: (body) => ({
          url: endpoints.vouchers.redeemProduct,
          body: body,
          method: "POST",
        }),
      }),

      history: builder.query({
        query: () => {
          return {
            url: endpoints.vouchers.history,
            method: "GET",
          };
        },
      }),
      manualAddition: builder.mutation({
        query: (body) => ({
          url: endpoints.vouchers.manualAddition,
          body: body,
          method: "POST",
        }),
      }),

      conversionDetails: builder.query({
        query: () => {
          return {
            url: endpoints.vouchers.conversionDetails,
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {
  useLazyHistoryQuery,
  useLazyConversionDetailsQuery,
  useManualAdditionMutation,
  useRedeemProductMutation,
  useConversionDetailsQuery,
} = vouchersApi;
export { vouchersApi };
