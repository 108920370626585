import React from "react";
import { useSelector } from "react-redux";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import ButtonForModal from "./ButtonForModal";
import "./Model.css";
export default function OtpVerifiationForCreateAccount({ setIsOpen }) {
  const { productPlanForModal } = useSelector((state) => state.modalState);
  const { dispatch, setIsOpenPopup } = useSelectPopupPomponent();
  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col">
      <div className="didnt-recive-email w-[calc(151px+15.729vw)]  md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="normal-18 w-[calc(151px+15.729vw)] space-y-3 px-[calc(13px+1.389vw)] py-14 md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full sm:py-6">
        <div className="medium-26 pink mb-14 sm:mb-6">
          We've sent a verification code to your email
        </div>
        <ButtonForModal
          onClick={() => {
            setIsOpen(false);
          }}
        >
          Continue
        </ButtonForModal>
      </div>
    </div>
  );
}
