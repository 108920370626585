import { Field, Form, Formik } from "formik";
import React from "react";
import ButtonForModal from "../ButtonForModal";
import "../Model.css";
import slashIcon from "../images/-/-@2x.png";
import cardIcon from "../images/Layer 46/Layer 46@2x.png";
import {
  useAddNewCardMutation,
  useUpdateDefaultCardMutation,
} from "../../../utils/store";
import useSelectPopupPomponent from "../../../Hooks/use-select-popup-component";
import ApiFetchingErrorMsg from "../../../components/ApiFetchingErrorMsg.component";
import ErrorMessageComponent from "../../../components/ErrorMessage.component";

export default function AddPaymentMethodForAutoRefill() {
  const [addNewCard, res] = useAddNewCardMutation();
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  const [defaultCard, respMakeDefault] = useUpdateDefaultCardMutation();

  const handleKeyPress = (event, maxLength, isMonth) => {
    const input = event.target;
    if (event.charCode >= 48 && event.charCode <= 57) {
      if (input.value.length >= maxLength) {
        // if (input.value.length >= 5) {
        event.preventDefault();
      }
    } else {
      event.preventDefault();
    }
  };
  const formFieldValues = [
    {
      name: "number",
    },
    {
      name: "exp_month",
    },
    {
      name: "exp_year",
    },
    {
      name: "name",
    },
    {
      name: "cvc",
    },
  ];

  return (
    <div className="flex w-full sm:w-[90vw]  sm:flex-col sm:pb-4">
      <div className="card-details w-[calc(151px+15.729vw)]  md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="normal-18 mb-7 w-[calc(151px+15.729vw)] space-y-6 p-[calc(13px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full sm:space-y-2 sm:px-10">
        <div className="medium-26 teal  sm:text-center">Add Payment Method</div>
        <div className="medium-26 sm:text-center">Enter card details</div>

        <Formik
          initialValues={{
            number: "",
            exp_month: "",
            exp_year: "",
            cvc: "",
            name: "",
          }}
          validate={(values) => {
            const errors = {};
            if (values.number.toString().length !== 16) {
              errors.number = "Invalid card number";
            } else if (!values.exp_month) {
              errors.exp_month = "Invalid month";
            } else if (values.exp_month > 12 || values.exp_month <= 0) {
              errors.exp_month = "Invalid month";
            } else if (!values.exp_year) {
              errors.exp_year = "Invalid year";
            } else if (values.cvc.toString().length !== 3) {
              errors.cvc = "Invalid cvv";
            } else if (!values.name) {
              errors.name = "Name cannot be empty";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const cardDetails = await addNewCard(values);
            const makeDefault = await defaultCard(cardDetails.data.id);
            if (makeDefault.data)
              dispatch(setPopupComponent("EnableAutoRefillAccept"));
          }}
        >
          {({ isSubmitting }) => (
            <Form className="formField-spacing space-y-6  sm:space-y-2">
              <div>
                <div>Card number</div>
                <Field
                  as="input"
                  name="number"
                  type={"number"}
                  maxLength={16}
                  size={16}
                  id="card"
                  onKeyPress={(event) => handleKeyPress(event, 16, false)}
                  className=" normal-18  block   h-[calc(22px+2.326vw)]  w-full rounded-md border border-[#52B1AE] bg-inherit tracking-wide  text-black placeholder:text-black/70 autofill:bg-transparent  focus:ring-teal-500  focus-visible:outline-none  sm:h-12"
                />
              </div>
              <div>
                <div className="flex gap-4">
                  <span>Expiry date</span>
                  <span className="normal-14 font-light">e.g. 10/20</span>
                </div>
                <div className="flex gap-3">
                  <div className="flex flex-col">
                    <span>Month</span>
                    <Field
                      name="exp_month"
                      maxLength={2}
                      type={"number"}
                      id="exp_month"
                      onKeyPress={(event) => handleKeyPress(event, 2, true)}
                      className=" normal-18 block h-[calc(22px+2.326vw)] w-[calc(23px+2.361vw)] rounded-md border  border-[#52B1AE] bg-inherit text-center text-black placeholder:text-black/70 autofill:bg-transparent focus:ring-teal-500   focus-visible:outline-none    sm:h-12 sm:w-12"
                    />
                  </div>
                  <div className="flex h-[calc(18px+2.326vw)] items-center self-end sm:h-12">
                    <img
                      src={slashIcon}
                      alt=""
                      className="h-[calc(13px+1.389vw)]"
                    ></img>
                  </div>
                  <div className="flex flex-col">
                    <span>Year</span>
                    <Field
                      name="exp_year"
                      onKeyPress={(event) => handleKeyPress(event, 2, false)}
                      type="number"
                      id="exp_year"
                      className="normal-18 block h-[calc(22px+2.326vw)] w-[calc(23px+2.361vw)] rounded-md border  border-[#52B1AE] bg-inherit text-center text-black placeholder:text-black/70 autofill:bg-transparent focus:ring-teal-500   focus-visible:outline-none    sm:h-12 sm:w-12"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div>Name on card</div>
                <Field
                  name="name"
                  maxLength={50}
                  id="card"
                  className="normal-18 block h-[calc(22px+2.326vw)] w-full  rounded-md border border-[#52B1AE] bg-inherit px-3   py-2  tracking-wide text-black placeholder:text-black/70  autofill:bg-transparent  focus:ring-teal-500 focus-visible:outline-none sm:h-12"
                />
              </div>
              <div className="space-y-1">
                <div>Card security code</div>
                <div className="normal-14 font-light">
                  The 3 digits of your security code
                </div>
                <div className="flex items-center gap-5">
                  <Field
                    name="cvc"
                    onKeyPress={(event) => handleKeyPress(event, 3, false)}
                    id="cvc"
                    className="normal-18  block h-[calc(22px+2.326vw)] w-[calc(55px+5.694vw)] rounded-md  border border-[#52B1AE] bg-inherit px-3 py-2 text-black  placeholder:text-black/70   autofill:bg-transparent focus:ring-teal-500 focus-visible:outline-none sm:h-12"
                  />
                  <img
                    src={cardIcon}
                    alt=""
                    className="h-[calc(13px+1.354vw)]"
                  ></img>
                </div>
              </div>
              <div>
                <ButtonForModal
                  type="submit"
                  color={"teal"}
                  isSubmitting={res.status === "pending"}
                >
                  Continue
                </ButtonForModal>
              </div>
              <div className="relative">
                <ErrorMessageComponent
                  formFieldValues={formFieldValues}
                  submissionError={res.error}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
