import React, { useEffect } from "react";
import DateFormator from "../../components/DateFormator.component";
import { BiLoaderAlt } from "react-icons/bi";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import {
  setProductPlanForModal,
  useSubscribePlanMutation,
  useProductsQuery,
  useLazyGetEsimStatusQuery,
  useLazyProfileQuery,
} from "../../utils/store";
import { nanoid } from "@reduxjs/toolkit";
import { useState } from "react";
import { useSelector } from "react-redux";
import { MixpanelTrackEvent } from "../../mixpanelEvents";
import RenderTotalData from "../../components/RenderTotalData.component";
import useSettingsInstructions from "../../Hooks/use-settings-instructions";

export default function Pending({ plans }) {
  const [getProfileData, profileData] = useLazyProfileQuery();
  const auth = useSelector((state) => state.auth);
  const { SettingInstructions } = useSettingsInstructions();

  useEffect(() => {
    if (auth) getProfileData(auth.token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);
  const { data, refetch: refetchProductData } = useProductsQuery();
  const activetPlan = data?.filter((plan) => {
    return plan.status === "active";
  });
  const [subscribePlan, subscribePlanResult] = useSubscribePlanMutation();
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  const [refetchEsim, refetchEsimSatus] = useLazyGetEsimStatusQuery();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const handleActivatePlan = async (plan) => {
    dispatch(setProductPlanForModal(plan));
    setSelectedPlan(plan);
    if (!activetPlan?.length) {
      const esimStatus = await refetchEsim();
      if (esimStatus?.data.esimStatus !== "installed") {
        return dispatch(setPopupComponent("SimInstallation"));
      }
      const resp = await subscribePlan({
        productId: plan._id,
        body: {
          productId: plan._id,
          transactionId: plan.transactionId,
          planId: plan.productId,
          upcoming: plan.status === "upcoming",
          country: plan.country,
        },
      });
      if (resp.data) {
        refetchProductData();
        profileData?.currentData?.isFirstTimeActivator &&
          dispatch(setPopupComponent("FirstPlanActivatedSuccessfully"));
        MixpanelTrackEvent.planActivation(plan, "Activated");
      } else throw new Error(resp.error.data.message);
    } else {
      dispatch(setPopupComponent("ActivatePendingPlan"));
      MixpanelTrackEvent.planActivation(plan, "Failed");
    }
  };
  if (!plans?.length)
    return (
      <div className="medium-20 h-64 pt-10 text-center">No pending plans</div>
    );
  const renderPendingPlans = plans?.map((plan) => {
    return (
      <div className="flex justify-center" key={nanoid()}>
        <div className="normal-28 w-[calc(213px+22.188vw)] space-y-2 rounded-[calc(3px+0.347vw)] bg-[#d05929] p-[calc(13px+1.389vw)]  text-white md:w-[calc(213px+41.602vw)] md:py-[calc(13px+2.604vw)] md:px-[calc(18px+3.581vw)] sm:w-[calc(236px+21.188vw)]">
          <div className="medium-32">
            Your Plan: <span className="uppercase">Not active</span>
          </div>
          {/* <div>Small Data Plan -India IGB -Valid for 7 days</div>  {plan.productCategory} - */}
          <div>
            {plan.country} <RenderTotalData data={plan.data}></RenderTotalData>
            -Valid for {plan.validity} days
          </div>
          <div>
            Purchased on -
            <DateFormator date={plan.purchaseDate} />
          </div>
          <div>
            This plan will expire unless activated by{" "}
            <DateFormator date={plan.purchaseDate} days={90} />
          </div>
          <div>Auto Refill {plan.isAutoRefillOn ? "ON" : "OFF"}</div>
          <div className="normal-20 flex justify-between md:flex-col md:items-center sm:flex-col sm:items-center">
            <button
              className="mt-4 w-[45%] rounded-lg border border-white py-4 text-center md:w-[70%] sm:w-[80%] sm:py-2"
              onClick={() => handleActivatePlan(plan)}
            >
              {(refetchEsimSatus.isFetching ||
                subscribePlanResult.status === "pending") &&
              selectedPlan._id === plan._id ? (
                <BiLoaderAlt
                  className={`mx-auto animate-spin text-xl`}
                ></BiLoaderAlt>
              ) : (
                "Activate Now"
              )}
            </button>
            {!profileData?.currentData?.isFirstTimeActivator && (
              <button
                className="mt-4 w-[45%] rounded-lg border border-white py-4 text-center md:w-[70%] sm:w-[80%] sm:py-2"
                onClick={() => SettingInstructions()}
              >
                Settings Instructions
              </button>
            )}
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className="flex justify-center">
      <div className="scroll mb-8  max-h-[calc(467px+12.153vw)] space-y-6 overflow-y-auto px-6 md:h-96 md:px-3 sm:mb-4 sm:max-h-96 sm:px-0">
        {renderPendingPlans}
      </div>
    </div>
  );
}
