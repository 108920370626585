import { useDispatch } from "react-redux";
import { setToaster } from "../utils/store";

export default function useToaster() {
  const dispatch = useDispatch();
  const setToast = ({ type, msg }) => {
    dispatch(setToaster({ type, msg }));
  };
  return { setToast };
}
