import useToaster from "./useToaster";

export default function useValidateSignup() {
  const { setToast } = useToaster();
  const validate = (values) => {
    let error;
    if (!values.firstName) {
      error = "First Name Required";
    } else if (values.firstName.length > 15) {
      error = "First Name should not contain more than 15 characters";
    } else if (!values.lastName) {
      error = "Last Name Required";
    } else if (values.lastName.length > 20) {
      error = "Last Name should not contain more than 20 characters";
    } else if (!values.email) {
      error = "Email Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      error = "Invalid email address";
    } else if (!values.mobile?.split(".")[1].length) {
      error = "Mobile Number Required";
    } else if (values.mobile?.split(".")[1].length > 18) {
      error = "Mobile Number should not be more than 18 digits";
    } else if (!values.password) {
      error = "Password Required";
    } else if (!/^\S*$/.test(values.password)) {
      error = "Password should not contain space.";
    } else if (values.password?.length < 8) {
      error = "Password should contain minimum 8 characters.";
    } else if (values["confirm-password"] !== values["password"]) {
      error = "Password does not match";
    } else if (!values.toggle1) {
      error =
        "Please accept that you have an eSIM enabled and Carrier Unlocked phone";
    } else if (!values.toggle2) {
      error = "Please accept the Privacy Policy and Terms & Conditions";
    }
    setToast({
      msg: error,
      type: "error",
    });
    if (error) return true;
  };

  return { validate, setToast };
}
