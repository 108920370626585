import React from "react";
import { BiLoaderAlt } from "react-icons/bi";
export default function SubmitButton({
  isSubmitting = false,
  children,
  ...rest
}) {
  return (
    <button
      type="submit"
      disabled={isSubmitting}
      className={`form-button bg-pink mt-10 flex w-full items-center justify-center rounded-md border border-transparent py-5  px-4 text-xl  font-medium text-white md:h-16 sm:h-12`}
    >
      {isSubmitting ? (
        <BiLoaderAlt className="animate-spin text-xl"></BiLoaderAlt>
      ) : (
        children
      )}
    </button>
  );
}
