import React from "react";
import { BiLoaderAlt } from "react-icons/bi";

export default function ButtonForModal({
  color = "pink",
  children,
  isSubmitting = false,
  ...rest
}) {
  return (
    <button
      disabled={isSubmitting}
      {...rest}
      className={`form-button sm:h-12 bg-${color} mt-10 flex w-full items-center justify-center rounded-lg border border-transparent  py-5 px-4  text-xl font-medium text-white focus-visible:outline-inherit `}
    >
      {isSubmitting ? (
        <BiLoaderAlt className="animate-spin text-xl"></BiLoaderAlt>
      ) : (
        children
      )}
    </button>
  );
}
