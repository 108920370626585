import { useSelector } from "react-redux";
import { useConversionDetailsQuery } from "../utils/store";

export default function useRideMilesDetails(planPrice) {
  const profile = useSelector((state) => state.profile);
  const { data: conversionData } = useConversionDetailsQuery();

  const redeemableRideMiles = profile?.redeemableRideMiles;

  const calculateValue = (miles) => {
    return (
      miles /
      (conversionData?.points / conversionData?.moneyValue)
    ).toFixed(2);
  };

  let redeemableRideMilesValue = 0;
  if (redeemableRideMiles && conversionData?.moneyValue) {
    redeemableRideMilesValue = calculateValue(redeemableRideMiles);
  }

  let usableRideMiles = 0;
  if (
    planPrice * (conversionData?.points / conversionData?.moneyValue) <=
    redeemableRideMiles
  ) {
    usableRideMiles =
      planPrice * (conversionData?.points / conversionData?.moneyValue);
  } else usableRideMiles = redeemableRideMiles;

  let usableRideMilesValue = calculateValue(usableRideMiles);

  let remainingRideMiles = redeemableRideMiles - usableRideMiles;
  let remainingRideMilesValue = calculateValue(remainingRideMiles);

  return {
    redeemableRideMilesValue,
    redeemableRideMiles,
    usableRideMiles,
    usableRideMilesValue,
    remainingRideMiles,
    remainingRideMilesValue,
  };
}
