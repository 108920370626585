import React from "react";
// import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
// import { setIsTermsChecked } from "../../utils/store";
import ButtonForModal from "./ButtonForModal";
import "./Model.css";
export default function TermsAndConditions({ setIsOpen, setIsTermsChecked }) {
  // const { dispatch, setPopupComponent } = useSelectPopupPomponent();

  return <div className="flex w-full sm:flex-col"></div>;
}
