import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  setIsFreeProduct,
  setVoucher,
  useCreateCheckoutSessionMutation,
  useLazyConversionDetailsQuery,
  useVoucherVerifyMutation,
} from "../../utils/store";
import ButtonForModal from "./ButtonForModal";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import discountIcon from "./images/Group 775.svg";
import { Field, Form, Formik } from "formik";
import { BiLoaderAlt } from "react-icons/bi";
import ApiFetchingErrorMsg from "../../components/ApiFetchingErrorMsg.component";
import { BsQuestionCircle } from "react-icons/bs";
import NestedModal from "../../components/NestedModal.component";
import InfoAutoRefill from "./InfoAutoRefill";
import useAuth from "../../utils/Auth/authChecker";
import { useNavigate } from "react-router-dom";
import useRideMilesDetails from "../../Hooks/useRideMiles";
import RenderTotalData from "../../components/RenderTotalData.component";

export default function BuyNow() {
  const { logout } = useAuth();
  sessionStorage.removeItem("voucher");
  sessionStorage.removeItem("ride-miles");
  const [value, setValue] = useState(false);
  const [useRideMiles, setUseRideMiles] = useState(false);

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  sessionStorage.setItem("isAutoRefillOn", value);
  const { productPlanForModal } = useSelector((state) => state.modalState);

  const { dispatch, setPopupComponent, setIsOpenPopup } =
    useSelectPopupPomponent();
  const [createCheckoutSession, response] = useCreateCheckoutSessionMutation();
  const profile = useSelector((state) => state.profile);

  useEffect(() => {
    if (useRideMiles) dispatch(setPopupComponent("BuyNowWithRideMiles"));
  }, [useRideMiles]);

  const handleBuyNowClick = async () => {
    if (!localStorage.getItem("jwt")) {
      logout();
      dispatch(setIsOpenPopup(false));
      navigate("/auth");
      return;
    }
    if (useRideMiles) {
      return dispatch(setPopupComponent("BuyNowWithRideMiles"));
    }
    const resp = await createCheckoutSession({
      productId: productPlanForModal.stripeProductId,
    });
    sessionStorage.setItem("plan", JSON.stringify(productPlanForModal));
    if (resp.data) window.location.href = resp.data.url;
    if (
      resp.error.data.message.message === "This plan is not available anymore."
    ) {
      dispatch(setPopupComponent("PlanNotAvailableToReOrderInfo"));
    } else if (resp.error) throw new Error(resp.error.data.message);
  };

  const [verifyVoucher, verifyVoucherResp] = useVoucherVerifyMutation();
  dispatch(setIsFreeProduct(false));

  const {
    redeemableRideMilesValue,
    redeemableRideMiles,
    remainingRideMiles,
    remainingRideMilesValue,
  } = useRideMilesDetails(productPlanForModal.priceBundle);

  const renderEnterVoucherDetails = (
    <Formik
      initialValues={{
        code: "",
      }}
      onSubmit={async (values) => {
        if (!values.code) return;
        const resp = await verifyVoucher({
          code: values.code,
          country: productPlanForModal.country,
          productId: productPlanForModal.productId,
        });
        if (resp.data) {
          sessionStorage.setItem("usedVoucher", values.code);
          dispatch(setVoucher(resp.data));
          sessionStorage.setItem("voucher", JSON.stringify(resp.data));
          dispatch(setPopupComponent("BuyNowWithVoucher"));
        }
      }}
    >
      {({ values, resetForm }) => (
        <Form className="">
          <div className="mb-5 flex items-center gap-5 sm:justify-center sm:gap-2">
            <div
              className={`relative  text-gray-400 focus-within:text-gray-600 `}
            >
              <Field
                onKeyPress={(event) => {
                  if (event.key === " ") {
                    event.preventDefault();
                  }
                }}
                disabled={useRideMiles}
                value={useRideMiles ? "" : values.code}
                type="text"
                name="code"
                placeholder={useRideMiles ? "" : "Enter Promo Code"}
                id="code"
                className={`${
                  useRideMiles
                    ? "border-[#D9D9D9] bg-[#D9D9D9] "
                    : "border-[#52B1AE] focus:border-[#52B1AE]  focus:ring-[#52B1AE]"
                } normal-18 form-input  relative block h-[calc(22px+2.326vw)] w-full  rounded-md border  bg-inherit pl-14  text-black  placeholder:text-black/[0.5] autofill:bg-transparent  sm:h-12`}
              />
              {!useRideMiles && (
                <img
                  src={discountIcon}
                  className="formIcon-left absolute top-1/2 left-4 block -translate-y-1/2  transition-all md:h-5 sm:h-5"
                  alt={""}
                ></img>
              )}
            </div>
            <button
              type="submit"
              className={`medium-20 flex h-[calc(22px+2.326vw)] w-[35%] items-center justify-center rounded-lg border border-transparent ${
                values.code && !useRideMiles ? "bg-[#52B1AE]" : "bg-[#CCCCCC]"
              } py-4 px-4 text-white sm:h-12`}
            >
              {verifyVoucherResp.status === "pending" ? (
                <BiLoaderAlt className="animate-spin text-xl"></BiLoaderAlt>
              ) : (
                "Apply"
              )}
            </button>
          </div>

          <div>
            <div className="teal normal-22 pt-2 text-center">OR</div>
            <div className="medium-16 teal">Ride Miles</div>
            <div className="flex gap-16">
              <div className="flex items-center gap-1 rounded-md border border-slate-50 p-2 shadow-md">
                <div className="teal medium-18 ">
                  {useRideMiles ? remainingRideMiles : redeemableRideMiles}
                </div>
                <div className="normal-14">
                  (US $
                  {useRideMiles
                    ? remainingRideMilesValue
                    : redeemableRideMilesValue}
                  )
                </div>
              </div>
              <label className="medium-20 flex items-center gap-3">
                <span className={`${useRideMiles ? "" : "text-[#848484]"}`}>
                  Use Ride Miles
                </span>
                <input
                  disabled={redeemableRideMiles === 0}
                  checked={useRideMiles}
                  value={useRideMiles}
                  onChange={(e) => {
                    setUseRideMiles(!useRideMiles);
                    resetForm();
                  }}
                  type={"checkbox"}
                  className={`${
                    useRideMiles
                      ? "border-[#52B1AE] checked:bg-[#52B1AE] hover:checked:bg-[#52B1AE] focus:checked:bg-[#52B1AE]"
                      : "border-[#848484]"
                  } rm-font-overpass -mt-1 bg-inherit focus:ring-transparent  md:-mt-0.5 sm:-mt-0.5`}
                />
              </label>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div>
      <NestedModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <InfoAutoRefill setIsOpen={setIsOpen} />
      </NestedModal>
      <div
        className={`${
          isOpen ? "hidden" : "flex "
        } w-full sm:w-[90vw] sm:flex-col`}
      >
        <div className="save-payment-method relative w-[calc(141px+15.729vw)]   md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
        <div className="w-[calc(151px+18.729vw)] py-[calc(3px+1.389vw)] px-[calc(9px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
          <div className="">
            <div className="teal medium-26 mb-3 sm:mt-5">
              <div className="capitalize">{profile?.firstName}, </div>
              <div>you selected:</div>
            </div>
            <div className="teal medium-26 ">
              {productPlanForModal?.productCategory} $
              {productPlanForModal?.priceBundle?.toFixed(2)}
            </div>
            <div className="normal-22 font-normal text-[#383838] ">
              {productPlanForModal?.country} -{" "}
              <RenderTotalData
                data={productPlanForModal.data}
              ></RenderTotalData>{" "}
              - Valid for {productPlanForModal?.validity} days
            </div>
          </div>
          <div className="mb-[calc(13px+1.389vw)] mt-[calc(8px+0.868vw)] space-y-[calc(8px+0.768vw)]">
            <div className="my-5 flex">
              <label className="teal normal-18 flex gap-2 font-light">
                <input
                  checked={value}
                  value={value}
                  onChange={(e) => setValue(!value)}
                  type={"checkbox"}
                  className="rm-font-overpass mr-[0.83vw] mt-1 border-[#52B1AE] bg-inherit checked:bg-[#52B1AE] hover:checked:bg-[#52B1AE] focus:ring-transparent  focus:checked:bg-[#52B1AE] md:mt-0 sm:mt-0"
                />
                Enable Auto Refill
              </label>
              <BsQuestionCircle
                className="teal ml-3 mt-1 inline  md:mt-0 sm:mt-0"
                onClick={() => setIsOpen(true)}
              />
            </div>

            {renderEnterVoucherDetails}

            <div className="relative w-full">
              <ApiFetchingErrorMsg submissionError={verifyVoucherResp?.error} />
            </div>
          </div>

          <div className="mt-[calc(20px+2.778vw)] sm:mb-3">
            <ButtonForModal
              color="teal"
              isSubmitting={response.status === "pending"}
              onClick={handleBuyNowClick}
            >
              Buy Now
            </ButtonForModal>
          </div>
        </div>
      </div>
    </div>
  );
}
