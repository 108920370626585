import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import { useGetStripeCustomerDetails_Query } from "../../utils/store";
import ButtonForModal from "./ButtonForModal";
import "./Model.css";
export default function MyAccount() {
  const profile = useSelector((state) => state.profile);
  const { dispatch, setIsOpenPopup, setPopupComponent } =
    useSelectPopupPomponent();

  const [cardToShow, setCardToShow] = useState("");
  const { data: stripeCardDetails } = useGetStripeCustomerDetails_Query();
  useEffect(() => {
    if (stripeCardDetails) {
      const defaultCard = stripeCardDetails?.filter(
        (card) => card.default_source
      );
      if (defaultCard?.length) {
        setCardToShow(defaultCard[0]);
      } else setCardToShow(stripeCardDetails[0]);
    }
  }, [stripeCardDetails]);

  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col">
      <div className="my-account  w-[calc(151px+15.729vw)]  md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="w-[calc(151px+15.729vw)] space-y-8 p-[calc(20px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full sm:space-y-6">
        <div className="pink medium-26 text-center">My Account</div>
        <div className="space-y-3">
          <div className="normal-16 text-[#383838]">Name</div>
          <div className="medium-18">{profile.displayName}</div>
        </div>
        <div className="space-y-3">
          <div className="normal-16 text-[#383838]">Email</div>
          <div className="medium-18">{profile.email}</div>
        </div>
        <div className="space-y-3">
          <div className="normal-16 text-[#383838]">Mobile Number</div>
          <div className="medium-18">
            {profile.countryCode}
            {profile.mobile}
          </div>
        </div>
        <div className="space-y-3">
          <div className="normal-16 text-[#383838]">Password</div>
          <div className="medium-18">************</div>
        </div>
        <div className="space-y-3">
          <div className="normal-16 text-[#383838]">
            Your Saved Payment Method
          </div>
          <div className={`medium-18 ${!cardToShow && "invisible"}`}>
            {cardToShow?.card?.brand} - *********{cardToShow?.card?.last4}
          </div>
        </div>
        <div>
          <ButtonForModal
            onClick={() => {
              dispatch(setPopupComponent("MyAccountEdit"));
            }}
          >
            Edit
          </ButtonForModal>
        </div>
      </div>
    </div>
  );
}
