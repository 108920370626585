import { Form, Formik } from "formik";
import React from "react";
import "./Model.css";
import passwordIcon from "../../resources/icons/FormFieldIcons/Layer_1.svg";
import FormFieldPopup from "./FormFieldPopup.component";
import SubmitButton from "../../components/SubmitButton.component";
import { useSelector } from "react-redux";
import { useChangePasswordMutation } from "../../utils/store";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import ErrorMessageComponent from "../../components/ErrorMessage.component";
import { MixpanelTrackEvent } from "../../mixpanelEvents";

const formFieldValues = [
  {
    name: "currentPassword",
    type: "password",
    placeholder: "Current Password",
    icon: passwordIcon,
  },
  {
    name: "newPassword",
    type: "password",
    placeholder: "New Password",
    icon: passwordIcon,
  },
  {
    name: "verifyPassword",
    type: "password",
    placeholder: "Re-enter New Password",
    icon: passwordIcon,
  },
];

const renderFormFields = formFieldValues.map((field) => {
  return <FormFieldPopup field={field} key={field.name} />;
});

export default function ChangePassword({ setIsOpen }) {
  const profile = useSelector((state) => state.profile);
  const [changePassword, resp] = useChangePasswordMutation();
  const { dispatch, setIsOpenPopup } = useSelectPopupPomponent();

  const handleChangePassword = async (values) => {
    const resp = await changePassword({ ...values, email: profile.email });

    if (resp.data) {
      //to set isPopupOpen to false --> if redirected from moadal signup to signin page
      // dispatch(setIsOpenPopup(false));
      setIsOpen(false);
    }
  };

  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col">
      <div className="auto-refill relative w-[calc(151px+15.729vw)]   md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="mb-10 w-[calc(151px+15.729vw)] p-[calc(13px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <Formik
          initialValues={{
            currentPassword: "",
            newPassword: "",
            verifyPassword: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.currentPassword) {
              errors.currentPassword = "Password Required";
            } else if (!/^\S*$/.test(values.currentPassword)) {
              errors.currentPassword = "Password should not contain space.";
            } else if (values.currentPassword?.length < 8) {
              errors.currentPassword =
                "Password should contain minimum 8 characters.";
            } else if (!values.newPassword) {
              errors.newPassword = "Password Required";
            } else if (!/^\S*$/.test(values.newPassword)) {
              errors.newPassword = "Password should not contain space.";
            } else if (values.newPassword?.length < 8) {
              errors.newPassword =
                "Password should contain minimum 8 characters.";
            } else if (!values.verifyPassword) {
              errors.verifyPassword = "Password Required";
            } else if (!/^\S*$/.test(values.verifyPassword)) {
              errors.verifyPassword = "Password should not contain space.";
            } else if (values.verifyPassword?.length < 8) {
              errors.verifyPassword =
                "Password should contain minimum 8 characters.";
            } else if (values.newPassword !== values.verifyPassword) {
              errors.verifyPassword = "Passwords do not match";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            MixpanelTrackEvent.updatedPasswordEvent();
            handleChangePassword(values);
          }}
        >
          {({ isSubmitting }) => (
            <Form className="space-y-6">
              <div className="medium-26 pink mb-8 text-center">
                Change Password
              </div>
              <div className="space-y-6 text-center">{renderFormFields}</div>
              <div className="mt-8">
                <SubmitButton isSubmitting={resp?.status === "pending"}>
                  Save Changes
                </SubmitButton>
              </div>
              <div className="relative mb-2 w-full">
                <ErrorMessageComponent
                  formFieldValues={formFieldValues}
                  submissionError={resp.error}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
