import React from "react";

import ButtonForModal from "./ButtonForModal";
import "./Model.css";
import useInstallESim from "../../Hooks/useInstallEsim";
export default function SimInstallation() {
  const { installESim } = useInstallESim();

  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col">
      <div className="sim-installation w-[calc(144px+15.000vw)]  md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="w-[calc(158px+16.458vw)] p-[calc(13px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="medium-26 orange mt-[calc(20px+2.444vw)] sm:text-center">
          You have not installed your eSIM
        </div>
        <div className="normal-20  mt-3 text-center sm:mb-4">
          Please install your eSIM before
          <br /> activating your plan
        </div>
        <div className="mb-4">
          <ButtonForModal color={"orange"} onClick={installESim}>
            Installation Instructions
          </ButtonForModal>
        </div>
      </div>
    </div>
  );
}
