import React from "react";
import { useNavigate } from "react-router-dom";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import ButtonForModal from "./ButtonForModal";
import "./Model.css";
export default function ThanxForInquiry() {
  const navigate = useNavigate();
  const { dispatch, setIsOpenPopup } = useSelectPopupPomponent();
  return (
    <div className="flex w-full sm:flex-col">
      <div className="didnt-recive-email w-[calc(134px+13.924vw)]  md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="normal-18 w-[calc(149px+15.521vw)] space-y-3 p-[calc(20px+1.989vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="orange medium-26">Thank you for your inquiry</div>
        <div>We will get back to you as soon as possible</div>
        <div>
          <ButtonForModal
            color="orange"
            onClick={() => {
              navigate("/");
              dispatch(setIsOpenPopup(false));
            }}
          >
            Return to home page
          </ButtonForModal>
        </div>
      </div>
    </div>
  );
}
