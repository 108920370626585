import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import backbutton from "../pages/Model Popups/images/arrow-down-sign-to-navigate/arrow-down-sign-to-navigate@2x.png";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { setBackButtonEvent, setIsOpenPopup } from "../utils/store";
import { useState } from "react";
import Toaster from "./Toaster";

export default function UnivarsalModal({ children }) {
  const toaster = useSelector((state) => state.toaster);
  const { backButtonEvent } = useSelector((state) => state.modalState);
  const { isOpenPopup, popupComponent } = useSelector(
    (state) => state.popupComponent
  );
  const dispatch = useDispatch();
  const [color, setColor] = useState();

  useEffect(() => {
    if (
      popupComponent === "ChangePassword" ||
      popupComponent === "CreateAccount" ||
      popupComponent === "DidntReceiveEmail" ||
      popupComponent === "MyAccount" ||
      popupComponent === "Otp" ||
      popupComponent === "MyAccountEdit" ||
      popupComponent === "OtpVerification" ||
      popupComponent === "InfoOnSignupPage" ||
      popupComponent === "OtpVerificationSent"
    ) {
      setColor("pink");
    } else if (
      popupComponent === "EsimActivation" ||
      popupComponent === "EsimActivationInternational" ||
      popupComponent === "PrivacyPolicy" ||
      popupComponent === "SimInstallation" ||
      popupComponent === "TermsAndConditions" ||
      popupComponent === "ThanxForInquiry" ||
      popupComponent === "VoucherAutoRefill" ||
      popupComponent === "InfoOnSignupPage"
    ) {
      setColor("orange");
    } else setColor("teal");
  }, [popupComponent]);
  function closeModal() {
    ////customise back button behaviour
    if (backButtonEvent) {
      backButtonEvent();
      dispatch(setBackButtonEvent(null));
    } else dispatch(setIsOpenPopup(false));
  }
  return (
    <Transition appear show={isOpenPopup} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => ""}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto sm:p-2">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="popup transform overflow-hidden rounded-[20px]  bg-white text-left align-middle shadow-xl transition-all">
                {children}
                {popupComponent !== "SuccessfullyLoggedIn" &&
                  popupComponent !== "IphoneOnly" &&
                  popupComponent !== "ReceivedRideMiles" &&
                  popupComponent !== "PlanNotAvailableToReOrderInfo" && (
                    <button
                      className="normal-12 absolute top-6 left-6 flex items-center gap-3  whitespace-nowrap rounded-lg bg-white px-4 py-2 focus-visible:outline-none sm:h-8"
                      onClick={closeModal}
                    >
                      <MdOutlineArrowBackIosNew className={color} />
                      Go Back
                    </button>
                  )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
        <Toaster />
      </Dialog>
    </Transition>
  );
}
