import useSelectPopupPomponent from "./use-select-popup-component";

export default function useInstallESim() {
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();

  const installESim = () => {
    // dispatch(setPopupComponent("EsimgeographicScope"));
    // dispatch(setPopupComponent("InstallationInCountry"));
    dispatch(setPopupComponent("EsimActivation"));
  };
  return { installESim };
}
