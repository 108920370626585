import { useDispatch, useSelector } from "react-redux";
import { setPopupComponent, setIsOpenPopup } from "../utils/store";
import ActivatePlanManually from "../pages/Model Popups/ActivatePlanManually";
import ActivatePlanNow from "../pages/Model Popups/ActivatePlanNow";
import AddPaymentMethod from "../pages/Model Popups/AddPaymentMethod";
import AutoRefillOff from "../pages/Model Popups/AutoRefillOff";
import ChangePassword from "../pages/Model Popups/ChangePassword";
import ContactUs from "../pages/Model Popups/ContactUs";
import CreateAccount from "../pages/Model Popups/CreateAccount";
import DidntReceiveEmail from "../pages/Model Popups/DidntReceiveEmail";
import EnableAutoRefillAccept from "../pages/Model Popups/EnableAutoRefillAccept";
import EnableAutoRefillSavePaymentMethode from "../pages/Model Popups/EnableAutoRefillSavePaymentMethode";
import MyAccount from "../pages/Model Popups/MyAccount";
import MyAccountEdit from "../pages/Model Popups/MyAccountEdit";
import Otp from "../pages/Model Popups/Otp";
import PrivacyPolicy from "../pages/Model Popups/PrivacyPolicy";
import PurchaseSuccess from "../pages/Model Popups/PurchaseSuccess";
import SavedPaymentMethodes from "../pages/Model Popups/SavedPaymentMethodes";
import SimInstallation from "../pages/Model Popups/SimInstallation";
import TermsAndConditions from "../pages/Model Popups/TermsAndConditions";
import ThanxForInquiry from "../pages/Model Popups/ThanxForInquiry";
import ZoneComingSoon from "../pages/Model Popups/ZoneComingSoon";
import EsimActivation from "../pages/Model Popups/EsimActivation";
import SavePaymentMethodAutorefill from "../pages/Model Popups/SavePaymentMethodAutorefill";
import BuyNow from "../pages/Model Popups/BuyNow";
import SavedPaymentMethodesAfterEnableAutoRefill from "../pages/Model Popups/Enabling Default Card Before AutoRefill/SavedPaymentMethodesAfterEnableAutoRefill";
import AddPaymentMethodForAutoRefill from "../pages/Model Popups/Enabling Default Card Before AutoRefill/AddPaymentMethodForAutoRefill";
import SuccessfullyLoggedIn from "../pages/Model Popups/SuccessfullyLoggedIn";
import BuyNowWithVoucher from "../pages/Model Popups/BuyNowWithVoucher";
import PaymentSuccessful from "../pages/Model Popups/PaymentSuccessfulPage";
import ActivatePlanNowAfterPurchase from "../pages/Model Popups/ActivatePlanNowAfterPurchase";
import InfoInCountry from "../pages/Model Popups/InfoInCountry";
import EsimActivationInternational from "../pages/Model Popups/EsimActivationInternational";
import EsimgeographicScope from "../pages/Model Popups/EsimgeographicScope";
import SettingInstructions from "../pages/Model Popups/SettingInstructions";
import SettingInstructionsInternational from "../pages/Model Popups/SettingInstructionsInternational";
import InstallationInstructionsInternationalTravel from "../pages/Model Popups/InstallationInstructionsInternationalTravel";
import InstallationInCountry from "../pages/Model Popups/InstallationInCountry";
import OtpVerification from "../pages/Model Popups/OtpVerification";
import InfoOnSignupPage from "../pages/Model Popups/InfoOnSignupPage";
import IphoneOnly from "../pages/Model Popups/IphoneOnly";
import NewCountryPlanBuy from "../pages/Model Popups/NewCountryPlanBuy";
import SettingsGeo from "../pages/Model Popups/SettingsGeo";
import ActivatePendingPlan from "../pages/Model Popups/ActivatePendingPlan";
import FirstPlanActivatedSuccessfully from "../pages/Model Popups/FirstPlanActivatedSuccessfully";
import ReceivedRideMiles from "../pages/Model Popups/ReceivedRideMiles";
import ReferAndRide from "../pages/Model Popups/ReferAndRide";
import RideMilesHistory from "../pages/Model Popups/RideMilesHistory";
import BuyNowWithRideMiles from "../pages/Model Popups/BuyNowWithRideMiles";
import PlanNotAvailableToReOrderInfo from "../pages/Model Popups/PlanNotAvailableToReOrderInfo";
import React from "react";
import Notifications from "../pages/Model Popups/Notifications";

export default function useSelectPopupPomponent() {
  const { popupComponent } = useSelector((state) => state.popupComponent);
  const renderPopupComponent =
    popupComponent === "ActivatePlanNow" ? (
      <ActivatePlanNow />
    ) : popupComponent === "ActivatePlanManually" ? (
      <ActivatePlanManually />
    ) : popupComponent === "AddPaymentMethod" ? (
      <AddPaymentMethod />
    ) : popupComponent === "AutoRefillOff" ? (
      <AutoRefillOff />
    ) : popupComponent === "ChangePassword" ? (
      <ChangePassword />
    ) : popupComponent === "ContactUs" ? (
      <ContactUs />
    ) : popupComponent === "CreateAccount" ? (
      <CreateAccount />
    ) : popupComponent === "DidntReceiveEmail" ? (
      <DidntReceiveEmail />
    ) : popupComponent === "EnableAutoRefillAccept" ? (
      <EnableAutoRefillAccept />
    ) : popupComponent === "EnableAutoRefillSavePaymentMethode" ? (
      <EnableAutoRefillSavePaymentMethode />
    ) : popupComponent === "MyAccount" ? (
      <MyAccount />
    ) : popupComponent === "MyAccountEdit" ? (
      <MyAccountEdit />
    ) : popupComponent === "Otp" ? (
      <Otp />
    ) : popupComponent === "PrivacyPolicy" ? (
      <PrivacyPolicy />
    ) : popupComponent === "PurchaseSuccess" ? (
      <PurchaseSuccess />
    ) : popupComponent === "SavedPaymentMethodes" ? (
      <SavedPaymentMethodes />
    ) : popupComponent === "SimInstallation" ? (
      <SimInstallation />
    ) : popupComponent === "TermsAndConditions" ? (
      <TermsAndConditions />
    ) : popupComponent === "ThanxForInquiry" ? (
      <ThanxForInquiry />
    ) : popupComponent === "ZoneComingSoon" ? (
      <ZoneComingSoon />
    ) : popupComponent === "EsimActivation" ? (
      <EsimActivation />
    ) : popupComponent === "SavePaymentMethodAutorefill" ? (
      <SavePaymentMethodAutorefill />
    ) : popupComponent === "BuyNow" ? (
      <BuyNow />
    ) : popupComponent === "SavedPaymentMethodesAfterEnableAutoRefill" ? (
      <SavedPaymentMethodesAfterEnableAutoRefill />
    ) : popupComponent === "AddPaymentMethodForAutoRefill" ? (
      <AddPaymentMethodForAutoRefill />
    ) : popupComponent === "SuccessfullyLoggedIn" ? (
      <SuccessfullyLoggedIn />
    ) : popupComponent === "BuyNowWithVoucher" ? (
      <BuyNowWithVoucher />
    ) : popupComponent === "PaymentSuccessful" ? (
      <PaymentSuccessful />
    ) : popupComponent === "ActivatePlanNowAfterPurchase" ? (
      <ActivatePlanNowAfterPurchase />
    ) : popupComponent === "InfoInCountry" ? (
      <InfoInCountry />
    ) : popupComponent === "EsimActivationInternational" ? (
      <EsimActivationInternational />
    ) : popupComponent === "EsimgeographicScope" ? (
      <EsimgeographicScope />
    ) : popupComponent === "InstallationInCountry" ? (
      <InstallationInCountry />
    ) : popupComponent === "InstallationInstructionsInternationalTravel" ? (
      <InstallationInstructionsInternationalTravel />
    ) : popupComponent === "SettingInstructionsInternational" ? (
      <SettingInstructionsInternational />
    ) : popupComponent === "SettingInstructions" ? (
      <SettingInstructions />
    ) : popupComponent === "OtpVerificationSent" ? (
      <OtpVerification />
    ) : popupComponent === "InfoOnSignupPage" ? (
      <InfoOnSignupPage />
    ) : popupComponent === "IphoneOnly" ? (
      <IphoneOnly />
    ) : popupComponent === "SettingsGeo" ? (
      <SettingsGeo />
    ) : popupComponent === "NewCountryPlanBuy" ? (
      <NewCountryPlanBuy />
    ) : popupComponent === "ActivatePendingPlan" ? (
      <ActivatePendingPlan />
    ) : popupComponent === "FirstPlanActivatedSuccessfully" ? (
      <FirstPlanActivatedSuccessfully />
    ) : popupComponent === "ReceivedRideMiles" ? (
      <ReceivedRideMiles />
    ) : popupComponent === "ReferAndRide" ? (
      <ReferAndRide />
    ) : popupComponent === "RideMilesHistory" ? (
      <RideMilesHistory />
    ) : popupComponent === "BuyNowWithRideMiles" ? (
      <BuyNowWithRideMiles />
    ) : popupComponent === "PlanNotAvailableToReOrderInfo" ? (
      <PlanNotAvailableToReOrderInfo />
    ) : popupComponent === "Notifications" ? (
      <Notifications />
    ) : (
      ""
    );

  const dispatch = useDispatch();
  // dispatch(setPopupComponent("BuyNowWithRideMiles"));

  return { dispatch, setPopupComponent, setIsOpenPopup, renderPopupComponent };
}
