import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function ProgressionBar({ dataUsage = "" }) {
  let dataRemainingPercentage =
    ((dataUsage?.data?.total_data_size_in_MB -
      dataUsage?.data?.used_data_size_in_MB) /
      dataUsage?.data?.total_data_size_in_MB) *
    100;
  if (
    dataUsage?.data?.total_data_size_in_MB -
      dataUsage?.data?.used_data_size_in_MB ===
    0
  )
    dataRemainingPercentage = 0;

  let dataUsed = 0;
  if (dataUsage?.data?.used_data_size_in_MB) {
    dataUsed = (dataUsage?.data?.used_data_size_in_MB / 1024).toFixed(2);
    if (dataUsed.toString().split(".")[1] === "00") {
      dataUsed = dataUsed.split(".")[0];
    }
  }

  return (
    <div className="mt-2 h-[calc(62px+6.493vw)] w-[calc(62px+6.493vw)] text-sm md:h-[calc(56px+10.872vw)] md:w-[calc(56px+10.872vw)] sm:h-[calc(102px+6.493vw)] sm:w-[calc(102px+6.493vw)]">
      <CircularProgressbarWithChildren
        counterClockwise={true}
        strokeWidth={5}
        value={dataRemainingPercentage}
        styles={{
          path: {
            stroke: "#d05929",
          },
        }}
      >
        <div className="orange medium-24 mb-4 md:mb-2 sm:mb-2">
          {dataUsage?.data?.used_data_size_in_MB ? dataUsed : 0} GB
        </div>
        <div className="normal-24">Used Data</div>
      </CircularProgressbarWithChildren>
    </div>
  );
}
