import React, { useState } from "react";
import { useEffect } from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";

export default function ApiFetchingErrorMsg({ submissionError }) {
  const [showError, setShowError] = useState();
  useEffect(() => {
    setShowError(submissionError);
  }, [submissionError]);

  useEffect(() => {
    let clearErrorTimeout = setTimeout(() => {
      if(showError) {
        setShowError("");
      }
    }, 5000);
    return (() => {
      clearTimeout(clearErrorTimeout);
    })
  }, [showError]);

  return (
    <div className="absolute mt-3 w-full">
      {showError && (
        <div
          className={`normal-14 mb-3  flex w-full items-center justify-between rounded-md bg-[#c42f52] py-2 font-light text-white`}
        >
          <span className="px-[calc(7px+0.694vw)] text-center">
            {submissionError?.data.message}
          </span>
          <div className="mr-[calc(7px+0.694vw)] flex h-[calc(8px+0.833vw)] w-[calc(8px+0.833vw)] cursor-pointer items-center">
            <RxCross2 onClick={() => setShowError("")} />
          </div>
        </div>
      )}
    </div>
  );
}
