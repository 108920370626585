import React from "react";
import ButtonForModal from "./ButtonForModal";
import "./Model.css";
import { useDeleteCardMutation } from "../../utils/store";
import { useDispatch } from "react-redux";
import { setIsAutoRefill } from "../../utils/store";
export default function DeleteDefaultCard({ card, setIsOpen }) {
  const [deleteCard, response] = useDeleteCardMutation();
  const dispatch = useDispatch();

  const handleDeleteCard = () => {
    deleteCard(card.id);
    dispatch(setIsAutoRefill(false));
    setIsOpen(false);
  };

  return (
    <div className="flex w-full sm:flex-col">
      <div className="card-details w-[calc(151px+15.729vw)]   md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="normal-18 w-[calc(151px+15.729vw)] space-y-6 p-[calc(13px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="medium-26 teal text-center">Delete Card</div>

        <div className="text-center">
          If you delete the default card, your auto-refill will be disabled. Are
          you sure you want to delete the default card?
        </div>

        <div>
          <ButtonForModal
            type="submit"
            color={"teal"}
            isSubmitting={response.status === "pending"}
            onClick={handleDeleteCard}
          >
            Delete
          </ButtonForModal>
        </div>
      </div>
    </div>
  );
}
