import React from "react";
import useToggleShowPassword from "../Hooks/use-toggleShowPassword";
import hideIcon from "../resources/icons/FormFieldIcons/Group586.svg";
import emailIcon from "../resources/icons/FormFieldIcons/email.svg";
import passwordIcon from "../resources/icons/FormFieldIcons/Layer_1.svg";
import phoneIcon from "../resources/icons/FormFieldIcons/Group582.svg";
import showIcon from "../resources/icons/view.svg";
// import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Field, ErrorMessage } from "formik";
// import PhoneInputField from "./PhoneInputField.component";
import PhoneInput from "react-phone-input-2";
import PhoneInputField from "./PhoneInputField.component";
export default function FormField({ field }) {
  const { showPassword, setShowPassword } = useToggleShowPassword();
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div
      className={`relative  w-full text-gray-400 focus-within:text-gray-600 ${
        field.name === "mobile"
          ? "phone-form-control form-input flex items-center rounded-md border border-[#c42f52] bg-inherit text-sm text-white md:text-black sm:text-black"
          : ""
      }`}
    >
      {field.name === "mobile" ? (
        <Field
          name={field.name}
          component={PhoneInputField}
          isEdit={false}
          isPopUp={false}
          profile={field.profile}
          isStoreData={field.isStoreData}
        />
      ) : (
        <Field
          type={
            field.type !== "password"
              ? field.type
              : showPassword
              ? "text"
              : "password"
          }
          name={field.name}
          placeholder={field.placeholder}
          id={field.name}
          className="rm-font-overpass form-input relative block h-[calc(32px+2.4vh)] w-full rounded-md border  border-[#c42f52] bg-inherit pl-16 text-lg font-light text-white  placeholder:text-white/70 autofill:bg-transparent focus:border-[#c42f52] focus:ring-[#c42f52] md:h-16  md:text-black  md:placeholder:text-black/70 sm:h-12 sm:text-black sm:placeholder:text-black/70"
        />
      )}
      <img
        src={field.icon}
        className={classNames(
          "formIcon-left   sm:max-w-4 absolute  top-1/2 left-7  block -translate-y-1/2 transition-all sm:max-h-4",
          field.icon === passwordIcon
            ? "max-h-[calc(7px+0.736vw)] max-w-[calc(6px+0.638vw)] md:max-h-5 md:max-w-[18px]  sm:max-h-5 sm:max-w-[18px]"
            : field.icon === emailIcon
            ? "max-h-[calc(5px+0.508vw)] max-w-[calc(7px+0.692vw)] md:max-h-[15px] md:max-w-[18px] sm:max-h-[15px] sm:max-w-[18px]"
            : field.icon === phoneIcon
            ? "max-h-[calc(7px+0.694vw)] max-w-[calc(7px+0.694vw)] md:max-h-[21px] md:max-w-[18px] sm:max-h-[21px] sm:max-w-[18px]"
            : "max-h-[calc(6px+0.625vw)] max-w-[calc(6px+0.625vw)] md:max-h-[18px] md:max-w-[16px] sm:max-h-[18px] sm:max-w-[16px]"
        )}
        alt={field.name}
      ></img>
      {field.type === "password" && (
        <img
          src={!showPassword ? hideIcon : showIcon}
          className="formIcon-right max-w-[calc(7px + 0.694vw)] absolute  right-7 top-1/2  block max-h-[calc(6px+0.625vw)] -translate-y-1/2 text-sm  font-medium transition-all"
          alt={field.name}
          onClick={() => setShowPassword(!showPassword)}
        ></img>
      )}
      {/* <ErrorMessage name={field.name} component="div" className="absolute" /> */}
    </div>
  );
}
