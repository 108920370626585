import React from "react";
import { Formik, Form } from "formik";
import { useResetPasswordMutation } from "../../utils/store";
import FormField from "../../components/FormField.component";
import passwordIcon from "../../resources/icons/FormFieldIcons/Layer_1.svg";
import SubmitButton from "../../components/SubmitButton.component";
import ErrorMessageComponent from "../../components/ErrorMessage.component";

const ResetPassword = ({ otp, setRenderedComponent }) => {
  const [resetPassword, response] = useResetPasswordMutation();

  const handleResetPassword = async (values) => {
    const resp = await resetPassword({
      newPassword: values.password,
      verifyPassword: values["confirm-password"],
      otp: otp,
    });
    if (resp.data) {
      setRenderedComponent("signin");
    } else if (resp.error) throw new Error(resp.error.data.message);
  };

  const formFieldValues = [
    {
      name: "password",
      type: "password",
      placeholder: "New Password",
      icon: passwordIcon,
    },
    {
      name: "confirm-password",
      type: "password",
      placeholder: "Confirm Password",
      icon: passwordIcon,
    },
  ];

  const renderFormFields = formFieldValues.map((field) => {
    return <FormField field={field} key={field.name} />;
  });

  return (
    <div className="mt-6 flex h-4/5 flex-col justify-center">
      <div className="signin-msgSection px-auto  mx-2 space-y-4  pb-8 text-center text-white md:text-black sm:text-black">
        <div className="normal-26 ">Reset Password</div>
        <div className="form-bottomText text-lg  font-light text-white md:text-black sm:text-black">
          Must be at least 8 alphanumeric characters
        </div>
      </div>
      <Formik
        initialValues={{ "confirm-password": "", password: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.password) {
            errors.password = "Password Required";
          } else if (!/^\S*$/.test(values.password)) {
            errors.password = "Password should not contain space.";
          } else if (values.password?.length < 8) {
            errors.password = "Password should contain minimum 8 characters.";
          } else if (values["confirm-password"] !== values["password"]) {
            errors["confirm-password"] = "Password does not match";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleResetPassword(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="formField-spacing space-y-6">
            {renderFormFields}

            <SubmitButton isSubmitting={response.status === "pending"}>
              Submit
            </SubmitButton>
            <div className="relative">
              <ErrorMessageComponent
                formFieldValues={formFieldValues}
                submissionError={response.error}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPassword;
