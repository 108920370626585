import React from "react";

import "./Model.css";
import ButtonOutlineForModal from "./ButtonOutlineForModal";
import ButtonForModal from "./ButtonForModal";
export default function InfoAutoRefill({ setIsOpen }) {
  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col">
      <div className="didnt-recive-email w-[calc(151px+15.729vw)]  md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="normal-18 w-[calc(151px+15.729vw)] space-y-3 px-[calc(13px+1.389vw)] py-14 md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full sm:py-6">
        <div className="normal-24 mb-14 sm:mb-6">
          By enabling Auto Refill your Ride Mobile plan will automatically be
          refilled when you run out of data or your plan expires{" "}
        </div>
        <ButtonForModal color="teal" onClick={() => setIsOpen(false)}>
          OK
        </ButtonForModal>
      </div>
    </div>
  );
}
