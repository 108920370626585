import React from "react";
import FormField from "../../components/FormField.component";
import { useForgotPasswordMutation } from "../../utils/store";
import emailIcon from "../../resources/icons/FormFieldIcons/email.svg";
import { Formik, Form } from "formik";
import SubmitButton from "../../components/SubmitButton.component";
import ErrorMessageComponent from "../../components/ErrorMessage.component";

const formFieldValues = [
  {
    name: "email",
    type: "email",
    placeholder: "Enter your email address",
    icon: emailIcon,
  },
];

const renderFormFields = formFieldValues.map((field) => {
  return <FormField field={field} key={field.name} />;
});

const ForgotPassword = ({ setRenderedComponent, setEmail }) => {
  const [sendMail, resp] = useForgotPasswordMutation();
  const handleForgotPassword = async (values) => {
    setEmail(values.email);
    const resp = await sendMail(values.email);
    if (resp.data) {
      setRenderedComponent("otp-verification");
    }
  };

  return (
    <div className="mt-6 flex h-4/5 flex-col justify-center">
      <div className="signin-msgSection  px-auto mx-2 space-y-4  pb-8 text-center text-white md:text-black sm:text-black">
        <div className="normal-26 ">Oops.. Did you forget your password?</div>
        <div className="form-bottomText text-lg  font-light text-white/80 md:text-black sm:text-black">
          Enter your email address below and we will send you a verification
          code to reset your password
        </div>
      </div>
      <Formik
        initialValues={{ email: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Email Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleForgotPassword(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="formField-spacing space-y-6">
            {renderFormFields}

            <SubmitButton isSubmitting={resp.status === "pending"}>
              Submit
            </SubmitButton>

            <div
              className="rm-font-overpass form-bottomText my-3 cursor-pointer text-center text-lg font-light text-white hover:text-orange-500 md:text-black sm:text-black"
              onClick={() => {
                setRenderedComponent("signin");
              }}
            >
              Go back to login screen
            </div>
            <div className="relative">
              <ErrorMessageComponent
                formFieldValues={formFieldValues}
                submissionError={resp.error}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
