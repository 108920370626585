import moment from "moment";
import React from "react";
import DateFormator from "../../components/DateFormator.component";
import { setProductPlanForModal } from "../../utils/store";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import RenderTotalData from "../../components/RenderTotalData.component";

export default function Expired({ plans }) {
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  if (!plans.length)
    return (
      <div className="medium-20 h-64 pt-10 text-center">No expired plans</div>
    );
  const handleBuyNowClick = (plan) => {
    dispatch(
      setProductPlanForModal({
        productId: plan.productId,
        planId: plan.planId,
        country: plan.country,
        upcoming: true,
        productCategory: plan.productCategory,
        priceBundle: plan.priceBundle ? plan.priceBundle : plan.retailPrice,
        data: plan.data,
        cycle: plan.cycle,
        cycleUnits: plan.cycleUnits,
        validity: plan.validity,
        stripeProductId: plan.stripeProductId,
      })
    );
    dispatch(setPopupComponent("BuyNow"));
  };

  const renderExpiredPlans = plans?.map((plan) => {
    return (
      <div className="rounded-[calc(3px+0.347vw)] bg-[#d05929] py-[calc(8px+1.389vw)] px-[calc(12px+0.6vw)] text-white  md:w-[calc(213px+41.602vw)] md:py-[calc(13px+2.604vw)] md:px-[calc(18px+3.581vw)] sm:w-[calc(236px+21.188vw)]">
        <div className="medium-28">
          Your Plan: EXPIRED, <DateFormator date={plan?.expiredDate} /> at{" "}
          {moment(plan?.expiredDate).format("LT")?.split(":")[0]?.length === 1
            ? "0" + moment(plan?.expiredDate).format("LT")
            : moment(plan?.expiredDate).format("LT")}
        </div>
        <div className="medium-26 mt-4">
          {plan.country} <RenderTotalData data={plan.data}></RenderTotalData> -
          Valid for {plan.validity} days
        </div>
        <div className="flex justify-center">
          <button
            className="normal-20 mt-4 w-[70%] rounded-lg border border-white py-4 text-center md:w-[60%] sm:w-[70%] sm:py-2"
            onClick={() => {
              handleBuyNowClick(plan);
            }}
          >
            Reorder Plan
          </button>
        </div>
      </div>
    );
  });

  return (
    <div className="flex justify-center ">
      <div className="scroll mb-8  max-h-[calc(467px+12.153vw)] space-y-6 overflow-y-auto overflow-x-hidden px-6 md:h-96 md:px-3 sm:mb-4 sm:max-h-96 sm:px-0">
        {renderExpiredPlans}
      </div>
    </div>
  );
}
