import { Field, Form, Formik } from "formik";
import React from "react";
import ButtonForModal from "./ButtonForModal";
import "./Model.css";
import phoneIcon from "../../resources/icons/FormFieldIcons/Group582.svg";
import emailIcon from "../../resources/icons/FormFieldIcons/email.svg";
import userIcon from "../../resources/icons/FormFieldIcons/Group578.svg";
import FormFieldPopup from "./FormFieldPopup.component";
import { useCreateSupportRequestMutation } from "../../utils/store";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";

const formFieldValues = [
  {
    name: "name",
    type: "text",
    placeholder: "Name",
    icon: userIcon,
    isRequired: true,
  },
  {
    name: "mobile",
    type: "number",
    placeholder: "Phone Number Needing Assistance",
    icon: phoneIcon,
  },
  {
    name: "email",
    type: "email",
    placeholder: "Email Address",
    icon: emailIcon,
    isRequired: true,
  },

  {
    name: "device",
    type: "text",
    placeholder: "make & model of device used e.g.iPhone 11",
    icon: phoneIcon,
  },
];

const renderFormFields = formFieldValues.map((field) => {
  return <FormFieldPopup field={field} key={field.name} />;
});

export default function ContactUs() {
  const [createSupportRequest, resp] = useCreateSupportRequestMutation();
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  return (
    <div className="flex w-full sm:flex-col">
      <div className="auto-refill w-[calc(151px+15.729vw)]   md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="w-[calc(286px+29.757vw)] p-[calc(13px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <Formik
          initialValues={{
            email: "",
            name: "",
            device: "",
            mobile: "",
            message: "",
          }}
          validate={(values) => {}}
          onSubmit={async (values, { setSubmitting }) => {
            const resp = await createSupportRequest(values);
            if (resp.data) {
              dispatch(setPopupComponent("ThanxForInquiry"));
            } else if (resp.error) throw new Error(resp.error.data.message);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="pink medium-26 mb-6 text-center">Contact Us</div>
              <div className="flex flex-wrap gap-8 text-center">
                {renderFormFields}
              </div>
              <div className="my-5">Message</div>
              <Field
                required
                as="textarea"
                name="message"
                className="focus-visible:transparent   h-[calc(74px+7.743vw)] w-[calc(256px+26.632vw)] appearance-none  rounded-lg  border border-[#c42f52] focus:ring-transparent focus-visible:border-[#c42f52]"
                placeholder="Please provide as much detail as possible regarding your question to help us respond to and resolve your Issue"
              ></Field>
              <div className="flex items-center justify-center">
                <div className=" w-[calc(123px+12.847vw)] ">
                  <ButtonForModal
                    type="submit"
                    isSubmitting={resp?.status === "pending"}
                  >
                    Send
                  </ButtonForModal>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
