/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  setProductPlanForModal,
  useCreateUserSessionMutation,
  useLazyProductsQuery,
  useProductsQuery,
} from "../utils/store";
import { useProductsMutation } from "../utils/store/api/activationApi";
import useSelectPopupPomponent from "../Hooks/use-select-popup-component";
import Dashboard from "./Dashboard.page";
import { MixpanelTrackEvent } from "../mixpanelEvents";
export default function PaymentSusccessfullPage() {
  const isAutoRefillOn =
    sessionStorage.getItem("isAutoRefillOn") === "true" ? true : false;
  const { data } = useProductsQuery();
  const [refetchProductList] = useLazyProductsQuery();

  const navigate = useNavigate();
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  const plan = JSON.parse(sessionStorage.getItem("plan"));

  const { id } = useParams();
  const [, transactionId] = id.split("=");
  const [createUserSessionMutation, res] = useCreateUserSessionMutation();
  const [productsMutation, resp] = useProductsMutation();
  const voucher = JSON.parse(sessionStorage.getItem("voucher"));

  const addProduct = async () => {
    let respCreateSession;
    let respProduct;
    if (voucher?.code) {
      respCreateSession = await createUserSessionMutation({
        transactionId: transactionId,
        coupon: voucher.code,
      });
    } else {
      respCreateSession = await createUserSessionMutation({
        transactionId: transactionId,
      });
    }
    if (respCreateSession.data) {
      respProduct = await productsMutation({
        ...plan,
        transactionId,
        isAutoRefillOn: isAutoRefillOn,
      });
    }
    if (respProduct.data) {
      dispatch(setProductPlanForModal(respProduct.data));
      refetchProductList();
      MixpanelTrackEvent.paymentSuccessfulEvent(plan);
      if (voucher?.code) {
        MixpanelTrackEvent.couponRedeemEvent(plan, voucher);
      }
    }
  };

  //for fetching only one time
  useEffect(() => {
    const id = setTimeout(() => {
      addProduct();
    }, 0);
    return () => {
      clearTimeout(id);
    };
  }, []);

  ////Navigate to dashboard and display modal
  useEffect(() => {
    dispatch(setPopupComponent("TermsAndConditions"));
    if (!resp.data) return;
    if (data?.length) {
      navigate("/", { replace: true });
      setTimeout(() => {
        dispatch(setPopupComponent("PurchaseSuccess"));
      }, 1000);
    } else if (data?.length === 0) {
      navigate("/", { replace: true });
      setTimeout(() => {
        dispatch(setPopupComponent("PaymentSuccessful"));
      }, 1000);
    }
  }, [resp]);

  return <Dashboard />;
}
