import { createSlice } from "@reduxjs/toolkit";
let initialState = {
  popupComponent: "",
  isOpenPopup: false,
};

export const selectPopupComponentSlice = createSlice({
  name: "popupComponent",
  initialState,
  reducers: {
    setPopupComponent: (state, action) => {
      state.popupComponent = action.payload;
      state.isOpenPopup = true;
    },
    setIsOpenPopup: (state, action) => {
      state.isOpenPopup = action.payload;
    },
  },
});
export const { setPopupComponent, setIsOpenPopup } =
  selectPopupComponentSlice.actions;
export default selectPopupComponentSlice.reducer;
