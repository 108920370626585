import React from "react";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import ButtonForModal from "./ButtonForModal";
import useSettingsInstructions from "../../Hooks/use-settings-instructions";

export default function NewCountryPlanBuy() {
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  const { SettingInstructions } = useSettingsInstructions();

  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col">
      <div className="purchase-successful  w-[calc(151px+15.729vw)]    md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="w-[calc(151px+15.729vw)] p-[calc(13px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="normal-22 px-8 pt-10 text-center md:px-[3vw] md:pt-4 md:pb-4 sm:pt-4">
          You are purchasing a Ride Mobile plan for a new country. Make sure
          your settings in your iPhone are correct.{" "}
          <span
            className="teal cursor-pointer underline"
            onClick={() => SettingInstructions()}
          >
            Click Here
          </span>{" "}
          to view the settings instructions for your Ride Mobile plan
        </div>
        <div className="flex gap-5 md:flex-col md:gap-3 sm:mt-4">
          <ButtonForModal
            color="teal"
            onClick={() => dispatch(setPopupComponent("BuyNow"))}
          >
            Continue
          </ButtonForModal>
        </div>
      </div>
    </div>
  );
}
