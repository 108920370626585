import { Mixpanel } from "./Mixpanel";

export const MixpanelTrackEvent = {
  // clicked on a plan event
  clickedOnPlanBuy: (plan) => {
    Mixpanel.track("Clicked On A Plan / Buy Now", {
      "Screen Called From": "DashboardScreen",
      "Plan Name": plan.name,
      "Plan ID": plan._id,
      "Stripe Product ID": plan.stripeProductId,
      "Visited From": "Web App",
    });
  },

  clickedOnCountryEvent: (location) => {
    Mixpanel.track("Clicked On A Country", {
      "Screen Called From": "DashboardScreen",
      "Country Name": location.name,
      "Country DialCode": location.dial_code,
      "Is Country Popular": location.isPopular,
      "Country Flag": location.flag,
      "Country Shortcode": location.code,
      "Visited From": "Web App",
    });
  },

  clickedOnZoneEvent: (location) => {
    Mixpanel.track("Clicked On A Zone", {
      "Screen Called From": "DashboardScreen",
      // "Zone Name": location?.name,
      // "Is Zone Popular": location?.isPopular,
      //"Visited From":"Web App"
    });
  },

  loginEvent: () => {
    Mixpanel.track("Login", {
      "API Endpoint": "/auth/signin",
      Status: 200,
      "Screen Called From": "LoginScreen",
      "Visited From": "Web App",
    });
    // Mixpanel.identify(values.email);
  },

  logoutEvent: (screen) => {
    Mixpanel.track("Logout", {
      Status: 200,
      "Screen Called From": screen,
      "Visited From": "Web App",
    });
  },

  registrationEvent: (signupData) => {
    Mixpanel.track("Account Created", {
      "API Endpoint": "/auth/signup",
      Status: 200,
      "Screen Called From": "RegistrationScreen",
      "Visited From": "Web App",
    });
  },

  paymentSuccessfulEvent: (plan) => {
    Mixpanel.track("Plan Purchased", {
      "API Endpoint": "/create-user-session",
      Status: 200,
      "Screen Called From": "PaymentScreen",
      "Plan Name": plan.name,
      "Plan Category": plan.productCategory,
      "Plan ID": plan._id,
      "Product ID": plan.productId,
      "Stripe Product ID": plan.stripeProductId,
      "Visited From": "Web App",
    });
  },

  iccidGeneratedEvent: (iccid, status, isSuccessful) => {
    Mixpanel.track("ICCID Generation", {
      "API Endpoint": "/activation/get-iccid",
      Status: status,
      "Screen Called From": "InstallationScreen",
      ICCID: iccid,
      "Is Successful": isSuccessful,
      "Visited From": "Web App",
    });
  },

  activationStringGeneratedEvent: (activationString, isSuccessful, status) => {
    Mixpanel.track("Activation Code Generation", {
      "API Endpoint": "/activation/get-activation-code",
      Status: status,
      "Screen Called From": "InstallationScreen",
      "Activation String": activationString,
      "Is Successful": isSuccessful,
      "Visited From": "Web App",
    });
  },

  autoRefillEvent: (plan, status) => {
    Mixpanel.track("Auto-Refill", {
      "API Endpoint": `products/${plan.productId}`,
      Status: 200,
      "Screen Called From": "ManagePlanScreen",
      "Auto Refill Status": status, ///check what is received in status param
      "Plan Name": plan.name,
      "Plan Category": plan.productCategory,
      "Plan ID": plan._id,
      "Product ID": plan.productId,
      "Stripe Product ID": plan.stripeProductId,
      "Visited From": "Web App",
    });
  },

  updatedPasswordEvent: () => {
    Mixpanel.track("Updated Password", {
      "API Endpoint": "/auth/changePassword",
      Status: 200,
      "Screen Called From": "Change Password Modal",
      "Visited From": "Web App",
    });
  },

  updatedProfileEvent: () => {
    Mixpanel.track("Updated Profile", {
      "API Endpoint": "/users",
      Status: 200,
      "Screen Called From": "My Account Modal",
      "Visited From": "Web App",
    });
  },

  couponRedeemEvent: (plan, voucher) => {
    Mixpanel.track("Coupon Redeemed", {
      "API Endpoint": "/create-user-session",
      Status: 200,
      "Screen Called From": "PaymentDetailsScreen",
      "Plan ID": plan._id,
      coupon: voucher.code,
      "Visited From": "Web App",
    });
  },

  planActivation: (plan, status, message) => {
    Mixpanel.track("Plan Activated", {
      "API Endpoint": `/products/${plan.productId}/subscribe`,
      Status: status,
      "Screen Called From": "ActivationScreen", ///check --- can be activated from multiple screens
      "Plan Name": plan.name,
      "Plan ID": plan._id,
      "Product ID": plan.productId,
      "Stripe Product ID": plan.stripeProductId,
      Message: "activated",
      "Visited From": "Web App",
    });
  },

  ////////////////////////////////////////// 14//////////

  logoClick: (screen) => {
    Mixpanel.track("Clicked On A Ride Mobile Logo", {
      "Screen Called From": screen,
      "Visited From": "Web App",
    });
  },

  aboutUsClick: (screen) => {
    Mixpanel.track("Clicked On A About Us Link", {
      "Screen Called From": screen,
      "Visited From": "Web App",
    });
  },

  contactUsClick: (screen) => {
    Mixpanel.track("Clicked On A Contact Us Link", {
      "Screen Called From": screen,
      "Visited From": "Web App",
    });
  },

  faqClick: (screen) => {
    Mixpanel.track("Clicked On A FAQ Link", {
      "Screen Called From": screen,
      "Visited From": "Web App",
    });
  },

  loginButtonClick: (screen) => {
    Mixpanel.track("Clicked On A Login Button", {
      "Screen Called From": screen,
      "Visited From": "Web App",
    });
  },

  eSIMEnabledClick: (screen) => {
    Mixpanel.track("Clicked On A eSIM enabled Link", {
      "Screen Called From": screen,
      "Visited From": "Web App",
    });
  },

  carrierUnlockedClick: (screen) => {
    Mixpanel.track("Clicked On A Carrier Unlocked Link", {
      "Screen Called From": screen,
      "Visited From": "Web App",
    });
  },

  countryTabClick: () => {
    Mixpanel.track("Clicked On A Country Tab", {
      "Screen Called From": "DashboardScreen",
      "Visited From": "Web App",
    });
  },

  clickedOnPlanLearnMore: (plan) => {
    Mixpanel.track("Clicked On A Plan / Learn more", {
      "Screen Called From": "DashboardScreen",
      "Plan Name": plan.name,
      "Plan ID": plan._id,
      "Stripe Product ID": plan.stripeProductId,
      "Visited From": "Web App",
    });
  },

  clickedOn40Country: () => {
    Mixpanel.track("Clicked On 40+ Countries Button", {
      "Screen Called From": "DashboardScreen",
      "Visited From": "Web App",
    });
  },

  clickedCreateAccountButton: (screen, userData) => {
    Mixpanel.track("Clicked On A Create an Account button", {
      "Screen Called From": screen,
      "Visited From": "Web App",
      Email: userData.email,
    });
  },

  startedAccountCreation: (screen) => {
    Mixpanel.track("Started registration in Create Account screen", {
      "Screen Called From": screen,
      "Visited From": "Web App",
    });
  },

  // clickedSearchDestination: (searchInput) => {
  //   Mixpanel.track("", {
  //     "Screen Called From": "DashboardScreen",
  //     "Searched For": searchInput,
  //     "Visited From": "Web App",
  //   });
  // },

  errorOnClickedCreateAccountButton: (screen, userData) => {
    Mixpanel.track("Getting error on Account Creation", {
      Email: userData.email,
      Status: 400,
      "Screen Called From": screen,
      "Visited From": "Web App",
    });
  },
};
