import copyIcon from "../../resources/icons/copy/copy2x.png";
import { useRef } from "react";
import { useConversionDetailsQuery } from "../../utils/store";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import coinIcon from "../../resources/icons/coin.svg";
import supportICon from "../../resources/icons/support_icon/technical-support2x.png";
import { useSelector } from "react-redux";
import useRideMilesDetails from "../../Hooks/useRideMiles";
import NestedModal from "../../components/NestedModal.component";
import { useState } from "react";
import RideMilesHistory from "./RideMilesHistory";
import useToaster from "../../Hooks/useToaster";
import { useEffect } from "react";
import useSetProfileData from "../../Hooks/useSetProfileData";

export default function ReferAndRide() {
  const profile = useSelector((state) => state.profile);
  const { redeemableRideMilesValue, redeemableRideMiles } =
    useRideMilesDetails();
  const [isOpen, setIsOpen] = useState(false);
  const { data: conversionData } = useConversionDetailsQuery();
  const { setToast } = useToaster();
  const textToCopy1 = useRef(null);
  const { setProfile } = useSetProfileData();

  useEffect(() => {
    setProfile();
  }, []);
  return (
    <>
      <NestedModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <RideMilesHistory setIsOpen={setIsOpen} />
      </NestedModal>
      <div
        className={`${
          isOpen ? "hidden" : "flex "
        } w-full sm:w-[90vw] sm:flex-col`}
      >
        <div className="save-payment-method  w-[calc(151px+15.729vw)]    md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
        <div className="w-[calc(151px+15.729vw)] space-y-4 p-[calc(10px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
          <div className="medium-30 orange text-center">Refer & Ride</div>

          <div className="normal-18 rounded-md bg-[#E9E7E3] px-4 pb-2 pt-4">
            <div className="semibold-22">My Ride Miles Balance</div>
            <div className="medium-24 orange mt-1 flex justify-between">
              <div className="flex gap-2">
                <img
                  src={coinIcon}
                  alt=""
                  className="mt-1 h-5 md:mt-0.5 md:h-3.5 sm:mt-0.5 sm:h-3 "
                />{" "}
                <span>{redeemableRideMiles}</span>
              </div>
              <div>US ${redeemableRideMilesValue}</div>
            </div>
          </div>

          {profile?.referralCode && (
            <>
              <div className="semibold-22 text-center">
                Share Your Referral Code
              </div>
              <div className="ml-5">
                <ol className="list-decimal">
                  <li>
                    Tell your friends about Ride Mobile and share your referral
                    code
                  </li>
                  <li>
                    Ask them to use your referral code when signing up for a
                    Ride Mobile account
                  </li>
                  <li>
                    Both of you will earn{" "}
                    {conversionData?.referee ? conversionData?.referee : 0} Ride
                    Miles when they complete their first purchase
                  </li>
                </ol>
              </div>
              <div className="normal-16 mb-5 rounded-lg border border-[#D05929]">
                <div className="flex items-center justify-between py-2 px-4 ">
                  <div>
                    <div className="medium-18 orange">Your Referral Code</div>
                    <div className="normal-22 orange" ref={textToCopy1}>
                      {profile?.referralCode}
                    </div>
                  </div>
                  <img
                    src={copyIcon}
                    alt=""
                    className="h-6 cursor-pointer"
                    onClick={() => {
                      setToast({ msg: "Referral code copied to clipboard", type: "success" });
                      navigator.clipboard.writeText(
                        textToCopy1.current.innerText
                      );
                    }}
                  ></img>
                </div>
              </div>
            </>
          )}

          {/* <ButtonForModal color="orange" onClick={() => {}}>
            Redeem Ride Miles
          </ButtonForModal> */}
          <button
            onClick={() => {
              setIsOpen(true);
            }}
            className={`form-button relative mt-10 flex w-full  items-center justify-start rounded-lg border  border-[#D05929] py-5 px-4 text-xl font-medium  text-teal-500 sm:h-10 `}
          >
            <span className="orange  ml-12 text-[#383838] md:ml-10 sm:ml-8">
              Ride Miles History
            </span>
            <MdOutlineArrowForwardIos className="orange absolute top-1/2 right-3 -translate-y-1/2 transition-all" />
            <img
              src={coinIcon}
              alt=""
              className="orange absolute top-1/2 left-4 w-[calc(11px+1.111vw)] -translate-y-1/2 transition-all md:w-6 sm:left-3 sm:w-4"
            />
          </button>
          <button
            onClick={() => {
              window.open(
                "https://ridemobile.zohodesk.com/portal/en/kb/ridemobile/general/ride-miles-rewards"
              );
            }}
            className={`form-button relative mt-10 flex  w-full  items-center justify-start  rounded-lg border  border-[#D05929] py-5 px-4 text-xl font-medium  text-teal-500 sm:h-10 `}
          >
            <span className="orange  ml-12 text-[#383838] md:ml-10 sm:ml-8">
              Ride Miles FAQ
            </span>
            <MdOutlineArrowForwardIos className="orange absolute top-1/2 right-3 -translate-y-1/2 transition-all" />
            <img
              src={supportICon}
              alt=""
              className="orange absolute top-1/2 left-4 w-[calc(11px+1.111vw)] -translate-y-1/2 transition-all md:w-6 sm:left-3 sm:w-4"
            />
          </button>
        </div>
      </div>
    </>
  );
}
