/////display for first purchase

import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import { useSelector } from "react-redux";
import discountIcon from "./images/Group 775.svg";
import { setIsShowReceivedRideMiles } from "../../utils/store";
import useInstallESim from "../../Hooks/useInstallEsim";
import RenderTotalData from "../../components/RenderTotalData.component";

export default function PaymentSuccessful() {
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  dispatch(setIsShowReceivedRideMiles(true));
  const { installESim } = useInstallESim();

  const profile = useSelector((state) => state.profile);
  const plan = JSON.parse(sessionStorage.getItem("plan"));
  const isAutoRefillOn = sessionStorage.getItem("isAutoRefillOn");

  const { isFreeProduct } = useSelector((state) => state.modalState);
  const { voucher = JSON.parse(sessionStorage.getItem("voucher")) } =
    useSelector((state) => state.modalState);
  const finalAmmount = () => {
    if (voucher?.discount.type === "PERCENT") {
      return (
        plan.priceBundle -
        (plan.priceBundle * voucher?.discount?.percent_off) / 100
      ).toFixed(2);
    }
    if (voucher?.discount.type === "AMOUNT") {
      return (plan.priceBundle - voucher?.discount.amount_off / 100).toFixed(2);
    }
  };
  const renderCouponDetails = (
    <div className="teal border-collapse">
      <div className="bg-teal w-full rounded-t-md border border-[#52B1AE] py-1 text-center  text-white">
        Promo Code : {voucher?.code}
      </div>
      <div className="flex w-full items-center rounded-b-md border border-[#52B1AE] ">
        <img src={discountIcon} className="ml-4" alt={""}></img>
        <div className="normal-20 mx-3">|</div>
        <div className="normal-14 mr-3 flex-1 py-1">
          <div className="flex justify-between">
            <div>Discount :</div>
            <div>
              {voucher?.discount.percent_off
                ? voucher?.discount.percent_off + "%"
                : "$" + (voucher?.discount.amount_off / 100)?.toFixed(2)}{" "}
              OFF
            </div>
          </div>
          <div className="flex justify-between">
            <div>Final Amount:</div>
            <div>${voucher?.freeProduct ? "0.00" : finalAmmount()}</div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col sm:pb-4">
      <div className="save-payment-method relative w-[calc(151px+15.729vw)]   md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="w-[calc(151px+18.729vw)] py-[calc(3px+1.389vw)] px-[calc(9px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="space-y-[calc(5px+0.694vw)]">
          <div className={` ${isFreeProduct ? "hidden" : ""} medium-26 teal`}>
            <span className="capitalize">{profile?.firstName}</span>, your
            purchase was successful and your Ride eSIM is ready to be installed
          </div>
          <div className="medium-20  space-y-3 ">
            <div className="teal">
              {plan?.productCategory} ${plan?.priceBundle?.toFixed(2)}
            </div>
            {(plan?.couponCode || voucher) && renderCouponDetails}

            <div className="text-[#383838]">
              {plan?.country} -{" "}
              <RenderTotalData data={plan?.data}></RenderTotalData> - Valid for{" "}
              {plan?.validity} days
            </div>
            <div className="text-[#383838]">
              Auto Refill is{" "}
              {isAutoRefillOn === "true" ? "Enabled" : "Disabled"}
            </div>
          </div>
        </div>

        <button
          onClick={installESim}
          className={`form-button mt-5 flex w-full items-center justify-center rounded-md border border-transparent bg-[#52B1AE] py-4 text-xl font-medium text-white focus-visible:outline-inherit sm:h-10`}
        >
          Continue
        </button>
      </div>
    </div>
  );
}
