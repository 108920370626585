import { Link } from "react-router-dom";
import FormFieldPopup from "./FormFieldPopup.component";
import phoneIcon from "../../resources/icons/FormFieldIcons/Group582.svg";
import emailIcon from "../../resources/icons/FormFieldIcons/email.svg";
import userIcon from "../../resources/icons/FormFieldIcons/Group578.svg";
import passwordIcon from "../../resources/icons/FormFieldIcons/Layer_1.svg";
import React, { useEffect, useState } from "react";
import "./Model.css";
import { Field, Form, Formik, useFormikContext } from "formik";
import {
  setBackButtonEvent,
  setProductPlanForModal,
  setSignupData,
  useResendMutation,
  useSignupMutation,
} from "../../utils/store";
import SubmitButton from "../../components/SubmitButton.component";
import ErrorMessageComponent from "../../components/ErrorMessage.component";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import { BsInfoCircle } from "react-icons/bs";
import NestedModal from "../../components/NestedModal.component";
import InfoOnSignupPage from "./InfoOnSignupPage";
import Otp from "./Otp";
import { MixpanelTrackEvent } from "../../mixpanelEvents";
import giftIcon from "../../resources/icons/gift-card-pink.svg";
import useValidateSignup from "../../Hooks/useValidateSignup";

const formFieldValues = [
  {
    name: "firstName",
    type: "text",
    placeholder: "First Name",
    icon: userIcon,
  },
  {
    name: "lastName",
    type: "text",
    placeholder: "Last Name",
    icon: userIcon,
  },
  {
    name: "email",
    type: "text",
    placeholder: "Email Address",
    icon: emailIcon,
  },
  {
    name: "mobile",
    type: "number",
    placeholder: "Mobile Number",
    icon: phoneIcon,
  },
  {
    name: "password",
    type: "password",
    placeholder: "Create Password",
    icon: passwordIcon,
  },
  {
    name: "confirm-password",
    type: "password",
    placeholder: "Re-enter Password",
    icon: passwordIcon,
  },
];

const renderFormFields = formFieldValues.map((field) => {
  return <FormFieldPopup field={field} key={field.name} />;
});

export default function CreateAccount() {
  const { dispatch, setPopupComponent, setIsOpenPopup } =
    useSelectPopupPomponent();
  const [resendOtpCode] = useResendMutation();
  const { validate, setToast } = useValidateSignup();

  const [isOpen, setIsOpen] = useState(false);
  const [isOtpPageOpen, setIsOtpPageOpen] = useState(false);

  const [signup, resp] = useSignupMutation();
  const handleSignup = async (values, resetForm) => {
    const singnupObj = { ...values };
    delete singnupObj.mobile;
    const resp = await signup({
      ...singnupObj,
      countryCode: values.mobile.split(".")[0],
      mobile: values.mobile.split(".")[1],
    });
    if (resp.data) {
      sessionStorage.setItem("email", values.email);
      setIsOtpPageOpen(true);
      dispatch(
        setSignupData({
          email: values.email,
          mobile: values.mobile,
          firstName: values.firstName,
          lastName: values.lastName,
        })
      );
    } else if (resp.error.data.verificationPending) {
      resendOtpCode(values.email);
      sessionStorage.setItem("email", values.email);
      setIsOtpPageOpen(true);
    } else {
      MixpanelTrackEvent.errorOnClickedCreateAccountButton(
        "Create Account Modal",
        values
      );
      setToast({ msg: resp.error.data.message, type: "error" });
    }
  };

  return (
    <div>
      <NestedModal
        isOpen={isOtpPageOpen}
        setIsOpen={setIsOtpPageOpen}
        arrowColor="pink"
      >
        <Otp setIsOtpPageOpen={setIsOtpPageOpen} />
      </NestedModal>
      <div
        className={`${
          isOpen || isOtpPageOpen ? "hidden" : "flex sm:flex-col"
        } w-full sm:w-[90vw]`}
      >
        <div className="create-account w-[calc(151px+15.729vw)]   md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
        <div
          className={`w-[calc(151px+15.729vw)] p-[calc(13px+1.389vw)] text-black md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)]  sm:w-full`}
        >
          <div className="pink medium-26 mb-4 text-center">
            Create an Account
          </div>
          <div className="mt-6 flex flex-col justify-center ">
            <div className="">
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                  firstName: "",
                  lastName: "",
                  mobile: "",
                  "confirm-password": "",
                  toggle1: false,
                  toggle2: false,
                  refereeCode: "",
                }}
                validate={(values) => {
                  // const errors = {};
                  // if (!values.firstName) {
                  //   errors.firstName = "First Name Required";
                  // } else if (values.firstName.length > 15) {
                  //   errors.firstName =
                  //     "First Name should not contain more than 15 characters";
                  // } else if (!values.lastName) {
                  //   errors.lastName = "Last Name Required";
                  // } else if (values.lastName.length > 20) {
                  //   errors.lastName =
                  //     "Last Name should not contain more than 20 characters";
                  // } else if (!values.email) {
                  //   errors.email = "Email Required";
                  // } else if (
                  //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  //     values.email
                  //   )
                  // ) {
                  //   errors.email = "Invalid email address";
                  // } else if (!values.mobile?.split(".")[1].length) {
                  //   errors.mobile = "Mobile no. Required";
                  // } else if (values.mobile?.split(".")[1].length > 18) {
                  //   errors.mobile =
                  //     "Mobile no. should not be more than 18 digits";
                  // } else if (!values.password) {
                  //   errors.password = "Password Required";
                  // } else if (!/^\S*$/.test(values.password)) {
                  //   errors.password = "Password should not contain space.";
                  // } else if (values.password?.length < 8) {
                  //   errors.password =
                  //     "Password should contain minimum 8 characters.";
                  // } else if (
                  //   values["confirm-password"] !== values["password"]
                  // ) {
                  //   errors["confirm-password"] = "Password does not match";
                  // } else if (!values.toggle1) {
                  //   errors.toggle1 =
                  //     "Please accept that you have an eSIM enabled and Carrier Unlocked phone";
                  // } else if (!values.toggle2) {
                  //   errors.toggle2 =
                  //     "Please accept the Privacy Policy and Terms & Conditions";
                  // }
                  // return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  const isError = validate(values);
                  if (isError) return;
                  handleSignup(values, resetForm);
                  MixpanelTrackEvent.clickedCreateAccountButton(
                    "Create Account Modal",
                    values
                  );
                }}
                validateOnBlur={false}
              >
                {({ isSubmitting }) => (
                  <Form
                    className="formField-spacing space-y-6"
                    // onSubmit={handleSubmit}
                  >
                    <CalculateForMixpanal />
                    {renderFormFields}
                    <div className=" normal-14 ml-[0.83vw] font-light sm:ml-3">
                      <div className="rm-privacy-container mb-4 flex sm:mb-2">
                        <label className="rm-font-overpass">
                          <Field
                            type="checkbox"
                            name="toggle1"
                            className="rm-font-overpass mr-[0.83vw] border-[#c42f52]  bg-inherit checked:bg-[#c42f52] hover:text-[#c42f52] focus:ring-transparent focus:ring-offset-0 focus:checked:bg-[#c42f52] sm:mr-3"
                          />
                          I confirm my phone is an{" "}
                          <a
                            target={"_blank"}
                            rel="noreferrer"
                            href="https://ridemobile.zohodesk.com/portal/en/kb/articles/what-esim-enabled-devices-does-ride-mobile-support"
                            className="text-[#c42f52] underline "
                            onClick={() =>
                              MixpanelTrackEvent.eSIMEnabledClick(
                                "Create Account Modal"
                              )
                            }
                          >
                            eSIM enabled
                          </a>{" "}
                          and{" "}
                          <a
                            target={"_blank"}
                            rel="noreferrer"
                            href="https://ridemobile.zohodesk.com/portal/en/kb/articles/how-do-i-know-if-my-iphone-is-unlocked"
                            className="text-[#c42f52] underline "
                            onClick={() =>
                              MixpanelTrackEvent.carrierUnlockedClick(
                                "Create Account Modal"
                              )
                            }
                          >
                            Carrier Unlocked
                          </a>{" "}
                          iPhone
                        </label>
                      </div>
                      <div className="rm-terms-container flex">
                        <label className="rm-font-overpass">
                          <Field
                            type="checkbox"
                            name="toggle2"
                            className="rm-font-overpass mr-[0.83vw] border-[#c42f52]  bg-inherit checked:bg-[#c42f52] hover:text-[#c42f52] focus:ring-transparent focus:ring-offset-0 focus:checked:bg-[#c42f52] sm:mr-3"
                          />
                          I agree to the Ride Mobile{" "}
                          <a
                            target={"_blank"}
                            rel="noreferrer"
                            href="https://www.ridemobile.com/privacy-policy"
                            className="text-[#c42f52] underline "
                          >
                            Privacy Policy
                          </a>{" "}
                          and{" "}
                          <a
                            target={"_blank"}
                            rel="noreferrer"
                            href="https://www.ridemobile.com/terms-and-conditions"
                            className="text-[#c42f52] underline "
                          >
                            Terms & Conditions
                          </a>
                        </label>
                      </div>
                    </div>

                    <div className="relative text-gray-400 focus-within:text-gray-600 md:w-full sm:w-full">
                      <Field
                        type={"text"}
                        name="refereeCode"
                        placeholder="Referral Code"
                        id="refereeCode"
                        className="rm-font-overpass form-input relative block h-[calc(22px+2.326vw)] w-full rounded-md border  border-[#c42f52] bg-inherit pl-16 text-[calc(6px+0.625vw)] text-black placeholder:text-black/[0.65]  autofill:bg-transparent focus:border-[#c42f52] focus:ring-[#c42f52]  md:h-16 sm:h-12"
                      />
                      <img
                        src={giftIcon}
                        className="formIcon-left  absolute top-1/2  left-7 block  max-h-[calc(10px+0.736vw)] max-w-[calc(9px+0.638vw)] -translate-y-1/2 text-sm font-medium text-gray-700 transition-all sm:max-h-5 sm:max-w-[18px]"
                        alt={""}
                      ></img>
                    </div>

                    <SubmitButton isSubmitting={resp.status === "pending"}>
                      Create Account
                    </SubmitButton>
                    <div className="normal-18 text-center">
                      Have an account?{" "}
                      <Link
                        to="/auth"
                        className="pink"
                        onClick={() => {
                          dispatch(setIsOpenPopup(false));
                          MixpanelTrackEvent.loginButtonClick(
                            "Create Account Modal (Log in here link)"
                          );
                        }}
                      >
                        Log in here
                      </Link>
                    </div>
                    {/* <div className="relative w-full">
                      <ErrorMessageComponent
                        formFieldValues={[
                          ...formFieldValues,
                          { name: "toggle1" },
                          { name: "toggle2" },
                        ]}
                        submissionError={resp.error}
                      />
                    </div> */}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const CalculateForMixpanal = () => {
  const { values } = useFormikContext();
  useEffect(() => {
    if (values.firstName.length === 1)
      MixpanelTrackEvent.startedAccountCreation("Create Account Modal");
  }, [values.firstName]);
};
