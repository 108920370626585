import React from "react";
import { useSelector } from "react-redux";
import { useProfileQuery } from "../utils/store";

export default function Profile() {
  const token = JSON.parse(localStorage.getItem("jwt"));
  const { data: auth } = useProfileQuery(token.token);
  // const auth = useSelector((state) => state.auth);

  return (
    <ul className="margin-left" style={{ overflow: "scroll" }}>
      <List auth={auth} />
    </ul>
  );
}

function List({ auth }) {
  const content = [];
  for (let property in auth) {
    if (
      typeof auth[property] !== "object" &&
      // !Array.isArray(auth[property]) &&
      property !== "password"
    ) {
      content.push(
        <li>
          {property}:{auth[property]}
        </li>
      );
    } else if (typeof auth[property] == "object" && property !== "password") {
      content.push(
        <ol>
          {property} - {<List className="ml-5" auth={auth[property]} />}
        </ol>
      );
    }
  }
  return content;
}
