import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { endpoints, BASE_URL } from "../../config/endpoint.config";

const productPlansApi = createApi({
  reducerPath: "productPlansApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth?.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints(builder) {
    return {
      products: builder.query({
        query: (status) => {
          return {
            url: endpoints.productPlans.products,
            params: { status },
            method: "GET",
          };
        },
      }),
    };
  },
});

export const { useProductsQuery, useLazyProductsQuery } = productPlansApi;
export { productPlansApi };
