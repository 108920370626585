import React from "react";
// import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import { useUsedForMutation } from "../../utils/store";
import ButtonForModal from "./ButtonForModal";
import checkmark from "./images/Path 97/Path 97.png";
import NestedModal from "../../components/NestedModal.component";
import { useState } from "react";
import EsimActivationInternational from "./EsimActivationInternational";
export default function InstallationInstructionsInternationalTravel() {
  const [isOpen, setIsOpen] = useState(false);
  const [usedFor, resp] = useUsedForMutation();
  // const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  return (
    <div>
      <NestedModal isOpen={isOpen} setIsOpen={setIsOpen} arrowColor="orange">
        <EsimActivationInternational setIsOpen={setIsOpen} />
      </NestedModal>
      <div
        className={`${
          isOpen ? "hidden" : "flex "
        } w-full sm:w-[90vw] sm:flex-col`}
      >
        <div className="save-payment-method  w-[calc(151px+15.729vw)]    md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
        <div className="w-[calc(151px+15.729vw)] space-y-4 p-[calc(10px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
          <div className="bold-26 teal">INSTALLATION INSTRUCTIONS</div>
          <div className="bold-26 teal">International Travel</div>

          <ol className="normal-18 list-inside list-decimal space-y-3">
            <li>
              Take a screenshot of these steps and Settings on the next screens
              to install your Ride Mobile eSIM, or find them in our FAQs
            </li>
            <li>
              Install/activate your eSIM before you depart to your international
              destination following the instructions on your phone
            </li>
            <li>
              To avoid unnecessary roaming charges, immediately prior to
              arriving at any international destination:{" "}
              <div className="my-1 flex gap-1">
                <div>- </div>
                Follow the International Travel Settings instructions on the
                next screens
              </div>
              <div className="flex gap-1">
                <div>- </div> Return to the Ride Mobile App to activate your
                plan on the Manage Plans screen
              </div>
            </li>
          </ol>
          <div className="flex gap-5 md:flex-col md:gap-3 sm:mt-4">
            <ButtonForModal
              isSubmitting={resp.status === "pending"}
              color="teal"
              onClick={async () => {
                const resp = await usedFor({
                  geographicScope: "international",
                });
                if (resp) setIsOpen(true);
                // dispatch(setPopupComponent("EsimActivationInternational"));
              }}
            >
              Install my eSIM
            </ButtonForModal>
          </div>
        </div>
      </div>
    </div>
  );
}
