import React from "react";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
export default function PlanNotAvailableToReOrderInfo() {
  const { dispatch, setIsOpenPopup } = useSelectPopupPomponent();
  const navigate = useNavigate();
  const shopForPlanButtonClick = () => {
    dispatch(setIsOpenPopup(false));
    navigate("/");
    setTimeout(() => {
      var element = document.getElementById("explore-plans");
      var headerOffset = 108;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }, 200);
  };
  return (
    <div className="flex w-full sm:flex-col">
      <div className="info-country w-[calc(151px+15.729vw)]  md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="w-[calc(151px+15.729vw)] space-y-4 p-[calc(13px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="normal-20 orange mt-6 text-center font-bold sm:mt-3 sm:text-left">
          Oops
        </div>
        <div className="normal-18 text-center sm:text-left">
          It seems this plan is no longer available,
          <br />
          go to shop to find another plan.
        </div>
        <div className="">
          <button
            className="form-button mt-10 flex  w-full  items-center justify-center rounded-lg border  border-[#d05929] py-5 px-4 text-xl font-medium  text-[#d05929] sm:h-10"
            color="orange"
            onClick={shopForPlanButtonClick}
          >
            Shop for a Plan
          </button>
        </div>
      </div>
      <button
        className="normal-12 absolute right-4 top-4 flex items-center gap-3  whitespace-nowrap rounded-lg bg-white px-4 py-2 focus-visible:outline-none sm:h-8"
        onClick={() => dispatch(setIsOpenPopup(false))}
      >
        <span className="hidden text-[12px] sm:inline-block">Close</span>
        <RxCross2 className="orange text-2xl sm:text-[14px]" />
      </button>
    </div>
  );
}
