import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { endpoints, BASE_URL } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const activationApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      products: builder.mutation({
        providesTags: ["products"],
        query: (body) => ({
          url: endpoints.activation.products,
          body: body,
          method: "POST",
        }),
      }),
      getIccid: builder.query({
        query: () => {
          return {
            url: endpoints.activation.getIccid,
            method: "GET",
          };
        },
      }),

      getIpaCode: builder.query({
        query: (data) => {
          return {
            url: endpoints.activation.getIpaCode(data),
            method: "GET",
          };
        },
      }),
      subscribePlan: builder.mutation({
        invalidatesTags: ["products"],
        query: ({ productId, body }) => ({
          url: endpoints.activation.subscribePlan(productId),
          body: body,
          method: "POST",
        }),
      }),
      getUsageData: builder.query({
        query: (data) => {
          return {
            url: endpoints.activation.getUsageData,
            method: "GET",
          };
        },
      }),
      getEsimStatus: builder.query({
        query: (data) => {
          return {
            url: endpoints.activation.getEsimStatus,
            method: "GET",
          };
        },
      }),
      autoRefill: builder.mutation({
        query: ({ productId, flag }) => ({
          url: endpoints.activation.autoRefill(productId),
          body: { flag },
          method: "PUT",
        }),
      }),
      setEsimDataLimit: builder.mutation({
        query: (body) => ({
          url: endpoints.activation.setEsimDataLimit,
          body: body,
          method: "PUT",
        }),
      }),
      webHookData: builder.mutation({
        query: (body) => ({
          url: endpoints.activation.webHookData,
          body: body,
          method: "POST",
        }),
      }),
      voucherVerify: builder.mutation({
        query: (body) => ({
          url: endpoints.activation.voucherVerify,
          body: body,
          method: "POST",
        }),
      }),
      voucherRedeem: builder.mutation({
        query: (body) => ({
          url: endpoints.activation.voucherRedeem,
          body: body,
          method: "POST",
        }),
      }),
      reSubscribePlan: builder.mutation({
        query: (body) => ({
          url: endpoints.activation.reSubscribePlan(body.productId),
          body: body,
          method: "POST",
        }),
      }),
      usedFor: builder.mutation({
        query: (body) => ({
          url: endpoints.activation.usedFor,
          body: body,
          method: "PUT",
        }),
      }),
    };
  },
});

export const {
  useGetIccidQuery,
  useLazyGetIccidQuery,
  useLazyGetIpaCodeQuery,
  useProductsMutation,
  useAutoRefillMutation,
  useLazyGetEsimStatusQuery,
  useLazyGetUsageDataQuery,
  useSubscribePlanMutation,
  useReSubscribePlanMutation,
  useWebHookDataMutation,
  useVoucherVerifyMutation,
  useVoucherRedeemMutation,
  useSetEsimDataLimitMutation,
  useGetEsimStatusQuery,
  useGetUsageDataQuery,
  useUsedForMutation,
} = activationApi;
export { activationApi };
