import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { endpoints, BASE_URL } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const usersApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      profile: builder.query({
        query: (token) => {
          return {
            url: endpoints.users.profile,
            headers: {
              Authorization: "Bearer " + token,
            },
            method: "GET",
          };
        },
        providesTags: ["User"],
      }),
      updateUserInfo: builder.mutation({
        query: (body) => ({
          url: endpoints.users.updateUserInfo,
          body: body,
          method: "PUT",
        }),
      }),
      updateProfilePic: builder.mutation({
        query: (body) => ({
          url: endpoints.users.updateProfilePic,
          body: body,
          method: "POST",
        }),
      }),
      getCountryCode: builder.query({
        query: () => {
          return {
            url: endpoints.users.getCountryCode,
            method: "GET",
          };
        },
      }),
      getUserNotifications: builder.query({
        query: () => {
          return {
            url: endpoints.users.getNotifications,
            method: "GET",
          };
        },
      }),
      updateNotification: builder.mutation({
        query: ({notificationId, body}) => ({
          url: endpoints.users.updateNotification(notificationId),
          body: body,
          method: "PUT",
        }),
      }),
    };
  },
});

export const {
  useProfileQuery,
  useLazyProfileQuery,
  useUpdateProfilePicMutation,
  useGetCountryCodeQuery,
  useUpdateUserInfoMutation,
  useGetUserNotificationsQuery,
  useLazyGetUserNotificationsQuery,
  useUpdateNotificationMutation
} = usersApi;
export { usersApi };
