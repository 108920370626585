import React from "react";
import rideMobileLogo from "../resources/icons/ride-Mobile-Footer-Logo.png";
import logos from "../resources/icons/Group 460/Group460.png";
const Footer = () => {
  const links = [
    // {
    //   title: "Popular countries",
    //   subtitles: [
    //     { name: "United States" },
    //     { name: "United Kingdom" },
    //     { name: "China" },
    //     { name: "Germany" },
    //     { name: "Spain" },
    //     { name: "Italy" },
    //   ],
    // },
    {
      title: "About Us",
      subtitles: [
        {
          name: "About Ride Mobile",
          link: "https://ridemobile.com/about-us/",
        },
        { name: "Installation", link: "https://www.ridemobile.com/#how" },
        {
          name: "Ride Mobile Advantage",
          link: "https://www.ridemobile.com/#advantage",
        },
      ],
    },
    {
      title: "Help Center",
      subtitles: [
        {
          name: "Support",
          link: "https://ridemobile.zohodesk.com/portal/en/kb/ridemobile",
        },
        {
          name: "FAQs",
          link: "https://ridemobile.zohodesk.com/portal/en/kb/ridemobile/faq",
        },
        { name: "Contact", link: "https://www.ridemobile.com/contact" },
      ],
    },
    {
      title: "hide label",
      subtitles: [
        {
          name: "Terms & Conditions",
          link: "https://www.ridemobile.com/terms-and-conditions",
        },
        {
          name: "Privacy Policy",
          link: "https://www.ridemobile.com/privacy-policy",
        },
        {
          name: "Acceptable Use Policy",
          link: "https://www.ridemobile.com/acceptable-use-policy",
        },
      ],
    },
  ];
  const content = links.map((item, index) => {
    return (
      <div
        key={item.title + index}
        className={`${
          item.title === "More Info" || item.title === "FAQ" ? "sm:hidden" : ""
        } normal-18`}
      >
        <div
          className={`mb-[calc(5px+0.556vw)] text-white ${
            item.title === "hide label" ? "invisible" : ""
          }`}
        >
          {item?.link ? (
            <a href={item.link} target={"_blank"} rel="noreferrer">
              {item.title}
            </a>
          ) : (
            <>{item.title}</>
          )}
        </div>
        {item.subtitles.map((e, ind) => {
          return (
            <div className="mb-[calc(5px+0.521vw)]" key={ind}>
              {e.link ? (
                <a
                  key={item.subtitles + ind}
                  href={e.link !== "#" ? e.link : ""}
                  className=" cursor-pointer text-white/70 "
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {e.name}
                </a>
              ) : (
                <span key={item.subtitles + ind} className=" text-white/70 ">
                  {e.name}
                </span>
              )}
            </div>
          );
        })}
      </div>
    );
  });
  return (
    <div className="rm-footer normal-16 items-center">
      <div className="rm-footer-logo-menu-container flex sm:flex-col">
        <img
          src={rideMobileLogo}
          className="rm-logo  tab-rm-logo ml-[calc(13.02vw-10px)] sm:ml-6"
          alt="rideMobileLogo"
        />
        <div className="rm-footer-menu-container tab-rm-options ml-[calc(11.56vw-20px)] mt-[calc(20px+2.083vw)] mr-[calc(15.83vw-30px)] flex flex-1 justify-between sm:mr-8 sm:ml-6 sm:flex-wrap sm:gap-x-5 sm:gap-y-4 sm:pr-4 ">
          {content}
        </div>
      </div>
      <div className="tab-terms-container flex justify-between gap-4 py-[calc(14px+1.493vw)] px-[28.43vw] xl:pl-[20.43vw] xl:pr-[10.43vw] sm:mr-6 sm:flex-wrap sm:px-6 sm:pl-6">
        <span className="text-white/90 sm:order-3 sm:self-center sm:pr-8">
          @2023 Ride Mobile
        </span>
        {/* <a
          href="https://www.ridemobile.com/privacy-policy"
          className="cursor-pointer text-white/90 sm:order-1 sm:self-center sm:pr-8"
          target={"_blank"}
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        <a
          href="https://www.ridemobile.com/terms-and-conditions"
          className="cursor-pointer text-white/90 sm:order-2 sm:self-center"
          target={"_blank"}
          rel="noreferrer"
        >
          Terms & Conditions
        </a> */}
        {/* <span className="ml-5 flex h-6 gap-3 sm:order-4">
          <img src={logos} alt=""></img>
        </span> */}
      </div>
    </div>
  );
};
export default Footer;
