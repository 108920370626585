import React from "react";
// import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
// import { setIsPrivacyChecked } from "../../utils/store";
import ButtonForModal from "./ButtonForModal";
import "./Model.css";
export default function PrivacyPolicy({ setIsOpen, setIsPrivacyChecked }) {
  // const { dispatch, setPopupComponent } = useSelectPopupPomponent();

  return (
    <div className="flex w-full sm:flex-col">
      <div className="privacy-policy w-[calc(151px+15.729vw)]  md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="w-[calc(151px+15.729vw)] space-y-3 p-[calc(13px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="medium-30 orange text-center">Privacy Policy</div>
        <div>
          •torem ipspm dolor sit tur adipisclnq elit. sed tempor in+d•dunt ut
          magna Ut enim veniam. q•4s nostrud ullamco nisi ut nuna pariatur.
          cupidatat non qui otticia ieserunt est laborurh. nuna pariatur. conset
          skjbrg eiusmod Vths IÅdS et iys sjk minim ih+r kjs si jshj Sint occaec
          at Ipa out srg amm id Ans sgwr Sint atti sOs
        </div>
        <div>
          <ButtonForModal
            color="orange"
            onClick={() => {
              setIsPrivacyChecked(true);
              // dispatch(setPopupComponent("CreateAccount"));
              setIsOpen(false);
            }}
          >
            I agree
          </ButtonForModal>
        </div>
      </div>
    </div>
  );
}
