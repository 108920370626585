import React from "react";
import ButtonForModal from "./ButtonForModal";
import { useAutoRefillMutation } from "../../utils/store/api/activationApi";
import "./Model.css";
import { useSelector } from "react-redux";
import {
  setIsAutoRefill,
  // useGetStripeCustomerDetails_Query,
} from "../../utils/store";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import { MixpanelTrackEvent } from "../../mixpanelEvents";
import RenderTotalData from "../../components/RenderTotalData.component";

export default function EnableAutoRefillAccept() {
  const { dispatch, setIsOpenPopup, setPopupComponent } =
    useSelectPopupPomponent();
  const [autoRefill, resp] = useAutoRefillMutation();
  const { productPlanForModal: plan } = useSelector(
    (state) => state.modalState
  );
  // const { data: stripeCardDetails } = useGetStripeCustomerDetails_Query();
  // const isDefaultCard = stripeCardDetails?.find((item) => item.default_source)
  //   ? true
  //   : false;

  const handleAcceptAutoRefill = async () => {
    const response = await autoRefill({
      productId: plan._id,
      flag: true,
    });
    if (response.data) {
      dispatch(setIsAutoRefill(true));
      dispatch(setIsOpenPopup(false));
      MixpanelTrackEvent.autoRefillEvent(plan, true);
    } else throw new Error(response.error.data.message);
  };
  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col sm:text-center">
      <div className="auto-refill w-[calc(134px+13.924vw)]  md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="normal-18 w-[calc(149px+15.521vw)] space-y-3 p-[calc(20px+1.989vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="teal medium-26">Enable Auto Refill</div>
        <div>Great Choice to enable Auto Refill!</div>
        <div>
          By clicking ACCEPT you will automatically get a new [
          {plan?.productCategory} ${plan?.priceBundle?.toFixed(2)} -{" "}
          {plan?.country} - <RenderTotalData data={plan.data}></RenderTotalData>{" "}
          - Valid for {plan?.validity} days] added to your account when you have
          used all of your data or your plan expired. We will let you know when
          we refill your plan.
        </div>
        <div>
          <ButtonForModal
            // disabled={!isDefaultCard}
            isSubmitting={resp?.status === "pending"}
            color="teal"
            onClick={handleAcceptAutoRefill}
          >
            ACCEPT
          </ButtonForModal>
        </div>
      </div>
    </div>
  );
}
