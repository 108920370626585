// import { Dialog, Transition } from "@headlessui/react";
import { useEffect, useRef } from "react";
import { Tab } from "@headlessui/react";
import { QRCodeSVG } from "qrcode.react";
import copyIcon from "../../resources/icons/copy/copy2x.png";
import {
  useGetIccidQuery,
  useLazyGetIpaCodeQuery,
} from "../../utils/store/api/activationApi";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "../../components/Skeleton.component";
import NestedModal from "../../components/NestedModal.component";
import { useState } from "react";
import SettingInstructionsInternational from "./SettingInstructionsInternational";
import { setToaster } from "../../utils/store";
import { MixpanelTrackEvent } from "../../mixpanelEvents";
import useToaster from "../../Hooks/useToaster";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function EsimActivationInternational() {
  const [isOpen, setIsOpen] = useState(false);

  let { data: iccidData, error } = useGetIccidQuery();
  const [getIpaCodeQuery, response] = useLazyGetIpaCodeQuery();
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  const profile = useSelector((state) => state.profile);

  const getIpaCode = async () => {
    let resp = await getIpaCodeQuery(iccidData.iccid);
    if (resp.data) {
      MixpanelTrackEvent.activationStringGeneratedEvent(
        resp.data.activationCode,
        true,
        200
      );
    } else if (resp.error) {
      MixpanelTrackEvent.activationStringGeneratedEvent(
        "",
        false,
        resp.error.status
      );
    }
  };

  useEffect(() => {
    if (iccidData?.iccid) {
      getIpaCode();
      MixpanelTrackEvent.iccidGeneratedEvent(iccidData.iccid, 200, true);
    } else if (error) {
      MixpanelTrackEvent.iccidGeneratedEvent("", error.status, false);
    }
    return () => {
      iccidData = null;
    };
  }, [iccidData?.iccid]);

  const code = response.data?.activationCode;

  let categories = [
    {
      title: "QR Code",
      component: QrPage,
    },
    {
      title: "Manual",
      component: Manual,
    },
  ];

  return (
    <div>
      <NestedModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <SettingInstructionsInternational setIsOpen={setIsOpen} />
      </NestedModal>
      <div
        className={`${
          isOpen ? "hidden" : "flex "
        } w-full sm:w-[90vw] sm:flex-col`}
      >
        <div className="qrcode-page w-[calc(151px+15.729vw)]  md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>

        <div className="w-[calc(170px+16.929vw)] bg-[#F4F2EE] p-[calc(11px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
          <div className="orange medium-26 mb-3 ">
            <span className="capitalize">{profile?.firstName}</span>, install
            your Ride Mobile eSIM following the instructions below
          </div>
          <Tab.Group>
            <div className="w-full sm:mb-6 sm:w-full">
              <Tab.List className="flex items-center justify-center space-x-1 rounded-xl bg-white p-1 shadow">
                {categories.map((category) => (
                  <Tab
                    key={category.title}
                    className={({ selected }) =>
                      classNames(
                        "medium-22 w-full rounded-lg py-1",
                        "focus:outline-none",
                        selected
                          ? "bg-[#D05929] text-white"
                          : "text-black hover:bg-white/[0.12] hover:text-[#D05929]"
                      )
                    }
                  >
                    {category.title}
                  </Tab>
                ))}
              </Tab.List>
            </div>
            <Tab.Panels className="mt-2">
              {categories.map((content, idx) => (
                <Tab.Panel
                  key={idx}
                  className={classNames(
                    "rounded-xl ",
                    "focus:outline-none",
                    "country-zone-section"
                  )}
                >
                  {content.component(code)}
                  <button
                    onClick={() => {
                      // dispatch(
                      //   setPopupComponent("SettingInstructionsInternational")
                      // );
                      setIsOpen(true);
                    }}
                    className={`form-button medium-22 flex w-full items-center justify-center rounded-md border border-transparent bg-[#D05929] py-3 text-white sm:h-12`}
                  >
                    Settings Instructions
                  </button>
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
}

function QrPage(code = "") {
  return (
    <div className="normal-18 ">
      <div className="mt-[calc(8px+0.868vw)] space-y-3 font-medium">
        <div className="flex justify-center py-4">
          {code ? (
            <QRCodeSVG
              value={"LPA:" + code}
              // className={`${!code && "invisible"}`}
            />
          ) : (
            <Skeleton className="h-[calc(43px+4.444vw)] w-[calc(43px+4.444vw)]" />
          )}
        </div>
        <div className=" ">
          DO NOT DELETE/REMOVE{" "}
          <span className="font-normal">
            your eSIM from your device after successful installation
          </span>
        </div>
        <div className="orange ">INSTALLATION INSTRUCTIONS</div>
        <div className="bold-20 ">
          To install your eSIM on your phone, print or display this QR code on
          another device. The QR code is also on your Order Confirmation email
        </div>
      </div>
      <ol className="mt-3 mb-6 list-inside list-decimal space-y-1 font-light">
        <li>
          Scan the QR code using the camera function on the phone you will use
          the Ride eSIM
        </li>
        <li>
          If you ARE NOT at your international destination:
          <div className="flex gap-1">
            <div>- </div> Follow the Installation Instructions on your phone
          </div>
          <div className="flex gap-1">
            <div>- </div> Select Primary for Default Line, Cellular Data, Calls
            & Messages, and iMessage & FaceTime
          </div>
          <div className="flex gap-1">
            <div>- </div> Label your eSIM ‘Ride’ (You can do this at a later
            time in your settings)
          </div>
        </li>
        <li>
          Immediately prior to arriving, or when you’re at your international
          destination:
          {/* <div className="flex gap-1">
            <div>- </div> Follow the Installation Instructions on your phone
          </div> */}
          <div className="flex gap-1">
            <div>- </div> Follow the Settings Instructions on the next screen to
            avoid unnecessary roaming charges
          </div>
        </li>
        <li>
          Return to the Ride Mobile App to activate your plan on the Manage
          Plans screen
        </li>
      </ol>
    </div>
  );
}

function Manual(code = "") {
  const dispatch = useDispatch();
  const textToCopy1 = useRef(null);
  const textToCopy2 = useRef(null);
  const [notRequiredForNow, address, activationCode] = code?.split("$");
  const { setToast } = useToaster();

  return (
    <div className="normal-18">
      <div className="mt-[calc(8px+0.868vw)] space-y-3 font-medium">
        <div className="normal-16 mb-5 rounded-lg border border-[#D05929]">
          <div className="flex items-center justify-between py-2 px-4 ">
            <div>
              <div className="normal-16 opacity-80">SM-DP-ADDRESS</div>
              <div className="medium-18" ref={textToCopy1}>
                {code ? address : <Skeleton className="h-4" />}
              </div>
            </div>
            <img
              src={copyIcon}
              alt=""
              className="h-6 cursor-pointer"
              onClick={() => {
                setToast({
                  msg: "Copied to clipboard",
                  type: "success",
                });
                navigator.clipboard.writeText(textToCopy2.current.innerText);
              }}
            ></img>
          </div>
          <hr className="w-full border-[#D05929]" />
          <div className="flex items-center justify-between py-2  px-4 ">
            <div>
              <div className="normal-16 opacity-80">ACTIVATION CODE</div>
              <div className="medium-18" ref={textToCopy2}>
                {code ? activationCode : <Skeleton className="h-4" />}
              </div>
            </div>
            <img
              src={copyIcon}
              alt=""
              className="h-6 cursor-pointer"
              onClick={() => {
                setToast({
                  msg: "Copied to clipboard",
                  type: "success",
                });
                navigator.clipboard.writeText(textToCopy2.current.innerText);
              }}
            ></img>
          </div>
        </div>
        <div className=" font-medium ">
          DO NOT DELETE/REMOVE{" "}
          <span className="font-normal">
            your eSIM from your device after successful installation
          </span>
        </div>
        <div className="orange ">INSTALLATION INSTRUCTIONS </div>
      </div>

      <ol className="my-3 list-inside list-decimal space-y-1">
        <li>
          Go to Settings {`>`} Cellular {`>`} Add eSIM
          <div className="flex gap-1">
            <div>- </div> On ‘Set Up Cellular’ screen, select ‘Use QR Code’
          </div>
          <div className="flex gap-1">
            <div>- </div> Tap on ‘Enter Details Manually’
          </div>
        </li>
        <li>Copy-paste the SM-DP+ADDRESS and ACTIVATION CODE</li>
        <li>
          If you ARE NOT at your international destination:
          <div className="flex gap-1">
            <div>- </div> Follow the Installation Instructions on your phone
          </div>
          <div className="flex gap-1">
            <div>- </div> Select Primary for Default Line, Cellular Data, Calls
            & Messages, and iMessage & FaceTime
          </div>
          <div className="flex gap-1">
            <div>- </div>Label your eSIM ‘Ride’ (You can do this at a later time
            in your settings)
          </div>
        </li>
        <li>
          Immediately prior to arriving, or when you’re at your international
          destination:
          {/* <div className="flex gap-1">
            <div>- </div> Follow the Installation Instructions on your phone
          </div> */}
          <div className="flex gap-1">
            <div>- </div> Follow the Settings Instructions on the next screen to
            avoid unnecessary roaming charges
          </div>
        </li>
        <li>
          Return to the Ride Mobile App to activate your plan on the Manage
          Plans screen
        </li>
      </ol>
    </div>
  );
}
