import { useState } from "react";
import { useEffect } from "react";

export default function useCountdownTimer() {
  const [countdown, setCountdown] = useState(
    +localStorage.getItem("countdown") - (Date.now() / 1000).toFixed()
  );
  const [showResendMsg, setShowResendMsg] = useState(false);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(
          +localStorage.getItem("countdown") - (Date.now() / 1000).toFixed()
        );
      }, 1000);
      // localStorage.setItem("countdown", countdown);
    } else {
      setShowResendMsg(true);
      localStorage.removeItem("countdown");
    }
    return () => {
      clearTimeout(timer);
    };
  }, [countdown]);

  const handleResetCountdown = (sec) => {
    // code to resend password
    setCountdown(
      +localStorage.getItem("countdown") - (Date.now() / 1000).toFixed()
    ); // set countdown to 60 seconds
    setShowResendMsg(false);
  };
  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;

  const time = (
    <span>
      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </span>
  );
  return { countdown, showResendMsg, handleResetCountdown, time };
}
