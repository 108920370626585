//////////// for signup page ///////////////////////////////////////////
import React from "react";
import {
  useLazyOtpVerifivationQuery,
  useLazyProfileQuery,
  useResendMutation,
} from "../../utils/store";
import OtpInput from "react18-input-otp";
import { useNavigate } from "react-router-dom";
import useAuth from "../../utils/Auth/authChecker";
import useCountdownTimer from "../../Hooks/use-countdown-timer";
import SubmitButton from "../../components/SubmitButton.component";
import CountdownTimer from "../../components/CountdownTimer.component";
import ApiFetchingErrorMsg from "../../components/ApiFetchingErrorMsg.component";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import { useSelector } from "react-redux";
import { MixpanelTrackEvent } from "../../mixpanelEvents";
import { Mixpanel } from "../../Mixpanel";
import { useState } from "react";

const OtpVerification = ({ otp, setOtp, email }) => {
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  const { countdown, showResendMsg, handleResetCountdown } =
    useCountdownTimer();
  const navigate = useNavigate();
  const [resendOtpCode] = useResendMutation();
  const { login } = useAuth();
  const signupData = useSelector((state) => state.signupData);
  const [getProfileData] = useLazyProfileQuery();
  //signup page otp verification
  const [otpVerification, response] = useLazyOtpVerifivationQuery();
  const [error, setError] = useState();

  const handleMixpanelRegister = async (token) => {
    const { data } = await getProfileData(token);
    if (data) {
      Mixpanel.register({
        "User Id": data._id,
        Email: data.email,
      });
      MixpanelTrackEvent.registrationEvent(signupData);
    }
  };

  const handleOtpVerification = async (e) => {
    setError();
    e.preventDefault();
    if (otp.length !== 6) {
      return setError({
        data: { message: "Invalid verification code, please try again" },
      });
    }

    const response = await otpVerification({ otp, email });
    setOtp("");
    if (response.data) {
      login(response.data.tokenResponse);
      navigate("/");
      setTimeout(() => {
        dispatch(setPopupComponent("SuccessfullyLoggedIn"));
      }, 100);
      handleMixpanelRegister(response.data.token);
    }
  };

  const handleOtpInputChange = (enteredOtp) => {
    setOtp(enteredOtp);
  };

  return (
    <div className="relative mt-6 flex h-4/5 flex-col justify-center pb-6 md:pb-12">
      <div className="signin-msgSection px-auto mx-8 space-y-4 pb-8 text-center text-white md:text-black sm:text-black">
        <div className="normal-26 ">Enter Verification Code</div>
        <div className="form-bottomText text-lg  font-light text-white md:text-black sm:text-black">
          A 6 digit code has been sent to your email address
        </div>
        <div className="form-bottomText text-lg  font-light text-white md:text-black sm:text-black">
          Time elapsed: <CountdownTimer />
        </div>
      </div>

      <form onSubmit={handleOtpVerification}>
        <div className="mb-4 flex justify-center">
          <OtpInput
            value={otp}
            inputStyle="otpinput"
            focusStyle="focus:ring-[#D05929]"
            onChange={handleOtpInputChange}
            numInputs={6}
            separator={<span></span>}
            isInputNum={true}
          />
        </div>

        <SubmitButton isSubmitting={response.isFetching}>Submit</SubmitButton>
        {showResendMsg ? (
          <div
            className="form-bottomText mt-6 cursor-pointer  text-center text-lg font-light text-white hover:text-orange-500 md:text-black sm:text-black"
            onClick={() => {
              localStorage.setItem(
                "countdown",
                +(Date.now() / 1000).toFixed() + 60
              );
              handleResetCountdown();
              resendOtpCode(email);
              dispatch(setPopupComponent("OtpVerificationSent"));
            }}
          >
            I did not receive a code
          </div>
        ) : (
          <div className="form-bottomText my-6  text-center text-lg font-light text-white md:text-black sm:text-black">
            OTP has been sent to your email address if not recived retry in
            <div className="orange inline-block w-8 font-medium">
              {countdown}
            </div>
            seconds
          </div>
        )}
        <div className="relative">
          <ApiFetchingErrorMsg submissionError={error || response.error} />
        </div>
      </form>
    </div>
  );
};

export default OtpVerification;
