import React from "react";
import { useSelector } from "react-redux";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import ButtonForModal from "./ButtonForModal";
import ButtonOutlineForModal from "./ButtonOutlineForModal";
import "./Model.css";
export default function ActivatePlanManually() {
  const { dispatch, setPopupComponent, setIsOpenPopup } =
    useSelectPopupPomponent();
  const { productPlanForModal } = useSelector((state) => state.modalState);

  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col">
      <div className="purchase-successful relative w-[calc(151px+15.729vw)]   md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="w-[calc(151px+15.729vw)] p-[calc(13px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="normal-22 px-4 pt-12 pb-8 text-center sm:pt-6">
          You can activate this plan whenever you would like in the next 90 days
          in your account under “Manage Plans”
        </div>
        <div className="flex gap-5">
          <ButtonForModal
            color="teal"
            onClick={() => {
              dispatch(setPopupComponent("PurchaseSuccess"));
            }}
          >
            Go Back
          </ButtonForModal>
          <ButtonOutlineForModal
            color="teal"
            onClick={() => {
              dispatch(setIsOpenPopup(false));
            }}
          >
            Continue
          </ButtonOutlineForModal>
        </div>
      </div>
    </div>
  );
}
