///for create account modal

import { useState } from "react";
import React from "react";
import OtpInput from "react18-input-otp";
import "./Model.css";
import {
  useLazyOtpVerifivationQuery,
  useLazyProfileQuery,
  useResendMutation,
} from "../../utils/store";
import useAuth from "../../utils/Auth/authChecker";
import SubmitButton from "../../components/SubmitButton.component";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import ApiFetchingErrorMsg from "../../components/ApiFetchingErrorMsg.component";
import NestedModal from "../../components/NestedModal.component";
import OtpVerifiationForCreateAccount from "./OtpVerifiationForCreateAccount";
import { useSelector } from "react-redux";
import { MixpanelTrackEvent } from "../../mixpanelEvents";
import { Mixpanel } from "../../Mixpanel";
import DidntReceiveEmail from "./DidntReceiveEmail";

export default function Otp({ setIsOtpPageOpen }) {
  const [otp, setOtp] = useState("");
  const email = sessionStorage.getItem("email");
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  const signupData = useSelector((state) => state.signupData);
  const [getProfileData] = useLazyProfileQuery();
  const [resendOtpCode] = useResendMutation();
  const { login } = useAuth();
  const [error, setError] = useState();

  //signup page otp verification
  const [otpVerification, response] = useLazyOtpVerifivationQuery();

  const handleMixpanelRegister = async (token) => {
    const { data } = await getProfileData(token);
    if (data) {
      Mixpanel.register({
        "User Id": data._id,
        Email: data.email,
      });
      MixpanelTrackEvent.registrationEvent(signupData);
    }
  };

  const handleOtpVerification = async (e) => {
    setError();
    e.preventDefault();
    if (otp.length !== 6) {
      return setError({
        data: { message: "Invalid verification code, please try again" },
      });
    }
    const response = await otpVerification({ otp, email });
    if (response.data) {
      login(response.data.tokenResponse);
      dispatch(setPopupComponent("SuccessfullyLoggedIn"));
      sessionStorage.removeItem("email");

      handleMixpanelRegister(response.data.token);
    }
    setOtp("");
  };

  const handleResendOtp = () => {
    resendOtpCode(email);
    setIsOpen(true);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [isDidntReceiveEmailOpen, setIsDidntReceiveEmailOpen] = useState(false);

  return (
    <div>
      <NestedModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isBackButton={false}
        arrowColor="pink"
      >
        <OtpVerifiationForCreateAccount setIsOpen={setIsOpen} />
      </NestedModal>
      <NestedModal
        isOpen={isDidntReceiveEmailOpen}
        setIsOpen={setIsDidntReceiveEmailOpen}
        arrowColor="pink"
      >
        <DidntReceiveEmail setIsOtpPageOpen={setIsOtpPageOpen} />
      </NestedModal>
      <div
        className={`${
          isDidntReceiveEmailOpen ? "hidden" : "flex "
        } w-full sm:w-[90vw] sm:flex-col sm:text-center`}
      >
        <div className="sim-installation  w-[calc(151px+15.729vw)]  md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
        <div className="mb-10 w-[calc(151px+15.729vw)] p-[calc(13px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
          <div className="pink medium-26">Enter Verification Code</div>
          {/* <div className="pink medium-26"> you received via email</div> */}
          {response.error ? (
            <div className="normal-18 my-4">
              Wrong code, please try again or{" "}
              <span className="pink cursor-pointer" onClick={handleResendOtp}>
                CLICK HERE
              </span>{" "}
              for us to resend you a new code
            </div>
          ) : (
            <div className="normal-18 my-4">
              A 6 digit code has been sent to your email address{" "}
              <br className="hidden md:block" />
              <span
                className="pink normal-14 ml-3 cursor-pointer md:ml-0 sm:ml-0 sm:block "
                onClick={handleResendOtp}
              >
                Didn't receive code? <span className="medium-16">Resend</span>
              </span>
            </div>
          )}

          <form
            onSubmit={handleOtpVerification}
            className="md:flex md:flex-col md:items-center sm:flex sm:flex-col sm:items-center sm:gap-4"
          >
            <OtpInput
              value={otp}
              inputStyle="otpinput otpinputTab"
              isInputNum={true}
              focusStyle="focus:ring-[#D05929]"
              onChange={(enteredOtp) => setOtp(enteredOtp)}
              numInputs={6}
              separator={<span></span>}
            />
            <SubmitButton isSubmitting={response.isFetching}>
              Submit
            </SubmitButton>
          </form>
          <div
            className="form-bottomText mt-6 cursor-pointer  text-center text-lg font-light hover:text-orange-500"
            onClick={() => setIsDidntReceiveEmailOpen(true)}
          >
            I did not receive an email
          </div>
          <div className="relative w-full">
            <ApiFetchingErrorMsg submissionError={error || response?.error} />
          </div>
        </div>
      </div>
    </div>
  );
}
