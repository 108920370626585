import React, { useEffect } from "react";
import DataPlanComponent from "../components/DataPlan.component";
import { useLazyPlansByCountryZoneQuery } from "../utils/store";
import CountryPlansSkelotons from "./Skeletons/CountryPlansSkelotons";

export default function DataPlanPage({ country }) {
  const [planList, planListResult] = useLazyPlansByCountryZoneQuery();

  useEffect(() => {
    planList(country.name);
  }, [country.name]);

  if (planListResult.isFetching) return <CountryPlansSkelotons />;
  if (planListResult.isError) return <div>error</div>;
  if (planListResult?.data?.length === 0)
    return (
      <div className="flex h-[calc(58px+6.076vw)] items-center justify-center">
        Coming Soon...
      </div>
    );

  return (
    <div className="grid grid-cols-4 gap-6 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
      {planListResult?.data?.map((plan) => {
        return (
          <DataPlanComponent
            plan={plan}
            country={country}
            key={plan.data + plan.validity}
          />
        );
      })}
    </div>
  );
}
