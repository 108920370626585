const BASE_URL = process.env.REACT_APP_BASE_URL;

const endpoints = {
  auth: {
    signin: "auth/signin",
    signup: "auth/signup",
    otpVerify: ({ otp, email }) => `auth/verify/${otp}/${email}`,
    refreshToken: "auth/token/refreshToken",
    resend: "auth/resend",
    forgot: "auth/forgot",
    validateOtp: "auth/validateOtp",
    reset: "auth/reset",
    changePassword: "auth/changePassword",
  },
  users: {
    profile: "users/me",
    updateUserInfo: "users",
    updateProfilePic: "users/picture",
    getCountryCode: "users/countryCode",
    getNotifications: "pushNotifications",
    updateNotification: (notificationId) => `pushNotifications/${notificationId}`,
  },
  productPlans: {
    products: "products",
  },
  activation: {
    products: "products",
    getIccid: "activation/iccid",
    getIpaCode: (iccid) => `activation/activation-code/${iccid}`,
    subscribePlan: (productId) => `products/${productId}/subscribe`,
    getUsageData: "activation/data-usage",
    getEsimStatus: "activation/esim-status",
    autoRefill: (productId) => `products/${productId}`,
    setEsimDataLimit: "activation/data-limit",
    webHookData: "activation/webhook-data",
    voucherVerify: "vouchers/verify",
    voucherRedeem: "vouchers/redeem",
    reSubscribePlan: (productId) => `products/${productId}/resubscribe`,
    usedFor: "activation/usedFor",
  },
  countryList: {
    zoneInfo: "podsgroup-products",
    zoneList: "zones",
    countryList: "countries",
    getAllCountry: "country-list",
    getALlZones: "zone-list",
  },
  chckoutSession: {
    createCheckoutSession: (planId) => `create-checkout-session-web/${planId}`,
    createUserSession: "create-user-session",
  },
  cards: {
    getStripeCustomerDetails: ({ customerId, object, limit }) =>
      `get-stripe-resources/${customerId}/${object}/${limit}`,
    addNewCard: "payment-methods",
    updateCard: "payment-methods",
    getStripeCustomerDetails_: "payment-methods",
    deleteCard: "payment-methods",
    updateDefaultCard: "payment-methods/update-default",
  },
  podProduct: {
    creatingNewPlane: "plans",
  },
  plans: {
    getProductsByCountry: "plans",
  },
  support: {
    createSupportRequest: "support-request",
  },
  vouchers: {
    redeemProduct: "vouchers/redeemProduct",
    history: "rideMiles/history",
    conversionDetails: "campaign/conversionDetails",
    manualAddition: "campaign/manualAddition",
  },
};

export { endpoints, BASE_URL };
