import useSelectPopupPomponent from "./use-select-popup-component";

export default function useSettingsInstructions() {
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();

  const SettingInstructions = () => {
    // dispatch(setPopupComponent("SettingsGeo"));
    dispatch(setPopupComponent("SettingInstructions"));
  };
  return { SettingInstructions };
}
