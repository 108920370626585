import React from "react";
import ButtonForModal from "./ButtonForModal";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { useConversionDetailsQuery } from "../../utils/store";

export default function ReceivedRideMiles() {
  const { dispatch, setIsOpenPopup, setPopupComponent } =
    useSelectPopupPomponent();
  const profile = useSelector((state) => state.profile);
  const { data: conversionData } = useConversionDetailsQuery();

  if (!conversionData) return;
  let points;
  if (profile?.refereeCode) {
    points = +conversionData.referrer + +conversionData.signUp;
  } else points = +conversionData.signUp;

  return (
    <div className="flex w-full sm:flex-col">
      <div className="ride-miles w-[calc(151px+15.729vw)]  md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="w-[calc(151px+15.729vw)] space-y-4 p-[calc(13px+1.389vw)] pt-12 md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)]  sm:w-full sm:pt-2">
        <div className="medium-24 orange mt-6 text-center sm:mt-3 sm:text-left">
          You have received {points} Ride Miles!
        </div>
        <div className="normal-18 text-center sm:text-left">
          Your Ride Miles will be reflected in your account within the next few
          minutes
        </div>
        <div className="">
          <ButtonForModal
            color="orange"
            onClick={() => dispatch(setPopupComponent("ReferAndRide"))}
          >
            Go to Refer & Ride
          </ButtonForModal>
        </div>
      </div>
      <button
        className="normal-12 absolute right-1 top-2 flex items-center gap-3 whitespace-nowrap rounded-lg bg-white px-4 py-2 focus-visible:outline-none sm:top-4 sm:right-4 sm:h-8"
        onClick={() => dispatch(setIsOpenPopup(false))}
      >
        <span className="hidden text-[12px] sm:inline-block">Close</span>
        <RxCross2 className="orange text-2xl sm:text-[14px]" />
      </button>
    </div>
  );
}
