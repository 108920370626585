import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import useSelectPopupPomponent from "../Hooks/use-select-popup-component";
import useAuth from "../utils/Auth/authChecker";
import { setProductPlanForModal, useProductsQuery } from "../utils/store";
import { MixpanelTrackEvent } from "../mixpanelEvents";
import RenderTotalData from "./RenderTotalData.component";

export default function DataPlanComponent({ plan, country }) {
  const { isLogin } = useAuth();
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  const { data } = useProductsQuery();

  const handleBuyNowClick = () => {
    dispatch(setProductPlanForModal(plan));
    MixpanelTrackEvent.clickedOnPlanBuy(plan);

    if (!isLogin()) {
      dispatch(setPopupComponent("CreateAccount"));
      return;
    } else {
      // if (data?.length && data[0].country !== country.name) {
      //   return dispatch(setPopupComponent("NewCountryPlanBuy"));
      // }
      dispatch(setPopupComponent("BuyNow"));
    }
  };

  const [showPlanInfo, setShowPlanInfo] = useState(false);

  return (
    <div className="flex-1">
      <div className="bg-teal medium-16 w-max rounded-t-lg py-1 px-4 text-white">
        {plan.productCategory}
      </div>
      <div className="normal-20 space-y-2 rounded-b-xl rounded-r-xl border border-[#52b1ae] bg-white py-4  px-6">
        <div className="teal flex justify-between">
          <div className="font-semibold">{country.name}</div>
          <img
            className="flag-icon"
            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country.code}.svg`}
            alt=""
          />
        </div>
        <div className="normal-18 flex items-center justify-between">
          <div>
            <RenderTotalData data={plan.data}></RenderTotalData> - Valid for{" "}
            {plan.validity} days
          </div>
          <div className="medium-16">{plan.priceBundle?.toFixed(2)} USD</div>
        </div>
        <div className="medium-16 flex justify-between">
          <button
            className={`flex w-[45%]  items-center justify-evenly rounded-md border border-[#52B1AE] py-1.5  ${
              showPlanInfo ? "bg-teal text-white " : "teal"
            } `}
            onClick={() => {
              setShowPlanInfo(!showPlanInfo);
              MixpanelTrackEvent.clickedOnPlanLearnMore(plan);
            }}
          >
            Learn More
            {showPlanInfo && <IoIosArrowDown className="text-white" />}
          </button>

          <button
            // disabled={response.status === "pending"}
            className={`bg-teal w-[45%] rounded-md border border-transparent py-1.5 text-white `}
            onClick={handleBuyNowClick}
          >
            Buy Now
          </button>
        </div>

        {showPlanInfo && (
          <div className="space-y-3">
            <div className="medium-18 mt-5 flex justify-between">
              <div>{plan.productCategory}</div>
              <div>
                <RenderTotalData data={plan.data}></RenderTotalData> -{" "}
                {plan.validity} days
              </div>
            </div>
            <div className="teal">
              <div className="semibold-18 mb-3">
                <RenderTotalData data={plan.data}></RenderTotalData> data plan
                will give you one of the following
              </div>
              {plan.info.map((e) => {
                return (
                  <div className="medium-18 flex gap-1" key={e}>
                    <div>-</div>
                    <div>{e}</div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
