import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  setBackButtonEventAuthPage,
  useLazyProfileQuery,
  useResendMutation,
  useSigninMutation,
} from "../utils/store";
import FormField from "../components/FormField.component";
import emailIcon from "../resources/icons/FormFieldIcons/email.svg";
import passwordIcon from "../resources/icons/FormFieldIcons/Layer_1.svg";
import { Formik, Form } from "formik";
import useAuth from "../utils/Auth/authChecker";
import ErrorMessageComponent from "../components/ErrorMessage.component";
import SubmitButton from "../components/SubmitButton.component";
import { MixpanelTrackEvent } from "../mixpanelEvents";
import { Mixpanel } from "../Mixpanel";
import { useDispatch } from "react-redux";
import useToaster from "../Hooks/useToaster";

const SignIn = ({
  setRenderedComponent,
  setEmail,
  setRenderedComponentSignup,
  setSelectedIndex,
}) => {
  const [signinError, setSigninError] = useState(null);
  const [mail, setMail] = useState();
  const [resendOtpCode] = useResendMutation();
  const { login } = useAuth();
  const navigate = useNavigate();
  const [setUser, resp] = useSigninMutation();
  const [getProfileData] = useLazyProfileQuery();
  const dispatch = useDispatch();
  const resendVerificationCode = () => {
    setEmail(mail);
    resendOtpCode(mail);
    setSelectedIndex(1);
    setRenderedComponentSignup();
  };

  useEffect(() => {
    dispatch(setBackButtonEventAuthPage(""));
  }, []);

  const { setToast } = useToaster();
  const validate = (values) => {
    let error;
    if (!values.email) {
      error = "Email Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      error = "Invalid email address";
    } else if (!values.password) {
      error = "Password Required";
    } else if (values.password?.length < 8) {
      error = "Password should contain minimum 8 characters.";
    }
    setToast({
      msg: error,
      type: "error",
    });
    if (error) return true;
  };

  const handleLogin = async (values) => {
    const resp = await setUser(values);
    if (resp.data) {
      const { data } = await getProfileData(resp.data.token);
      if (data) {
        Mixpanel.register({
          "User Id": data._id,
          Email: data.email,
        });
      }
      MixpanelTrackEvent.loginEvent();

      login(resp.data);
      navigate("/");
    } else if (resp.error) {
      setSigninError(resp.error.data.message);
      if (resp.error.data.verficationPending) {
        setMail(values.email);
      }
    }
  };
  const formFieldValues = [
    {
      name: "email",
      type: "text",
      placeholder: "Enter your email address",
      icon: emailIcon,
    },
    {
      name: "password",
      type: "password",
      placeholder: "Enter your password",
      icon: passwordIcon,
    },
  ];
  const renderFormFields = formFieldValues.map((field) => {
    return <FormField field={field} key={field.name} />;
  });
  return (
    <div className="mt-6 flex h-4/5 flex-col justify-center md:mb-6">
      {signinError && (
        <div className="signin-error px-16  pb-5 text-center text-white md:text-black sm:text-black">
          <div>{signinError}</div>
          <div>
            {resp?.error?.data.verficationPending && (
              <button
                className="mt-2 rounded-lg bg-white py-2 px-4 text-black sm:border sm:border-slate-800 "
                onClick={() => {
                  resendVerificationCode();
                  dispatch(setBackButtonEventAuthPage("signin"));
                }}
              >
                Verify Now
              </button>
            )}
          </div>
        </div>
      )}
      <Formik
        initialValues={{ email: "", password: "" }}
        validate={(values) => {
          // const errors = {};
          // if (!values.email) {
          //   errors.email = "Email Required";
          // } else if (
          //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          // ) {
          //   errors.email = "Invalid email address";
          // } else if (!values.password) {
          //   errors.password = "Password Required";
          // } else if (values.password?.length < 8) {
          //   errors.password = "Password should contain minimum 8 characters.";
          // }
          // return errors;
        }}
        onSubmit={(values) => {
          const isError = validate(values);
          if (isError) return;
          handleLogin(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="formField-spacing space-y-6">
            {renderFormFields}
            <div>
              <SubmitButton isSubmitting={resp.status === "pending"}>
                Log in
              </SubmitButton>
              <div
                className="rm-font-overpass form-bottomText my-[calc(8px+0.833vw)] mt-6 cursor-pointer text-center text-lg font-light text-white hover:text-orange-500 md:text-black sm:text-black"
                onClick={() => {
                  setRenderedComponent("forgot-password");
                }}
              >
                Have you forgotten your password?
              </div>
              {/* <div className="relative">
                <ErrorMessageComponent formFieldValues={formFieldValues} />
              </div> */}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default SignIn;
