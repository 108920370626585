import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  setIsAutoRefill,
  setIsFreeProduct,
  setProductPlanForModal,
  useCreateCheckoutSessionMutation,
  useGetEsimStatusQuery,
  useLazyProductsQuery,
  useProductsQuery,
  useVoucherRedeemMutation,
} from "../../utils/store";
import ButtonForModal from "./ButtonForModal";
import sideImg from "../../resources/images/GettyImages-158687146/GettyImages-158687146.png";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import checkmarkIcon from "./images/Group 777.svg";
import NestedModal from "../../components/NestedModal.component";
import VoucherAutoRefill from "./VoucherAutoRefill";
import InfoAutoRefill from "./InfoAutoRefill";
import { BsInfoCircle, BsQuestionCircle } from "react-icons/bs";
import { MixpanelTrackEvent } from "../../mixpanelEvents";
import RenderTotalData from "../../components/RenderTotalData.component";
import DisableFreeProductForAutoRefill from "./DisableFreeProductForAutoRefill";

export default function BuyNowWithVoucher() {
  const { data: esimStatus } = useGetEsimStatusQuery();
  const { data } = useProductsQuery();

  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDisableFreeProductOpen, setIsDisableFreeProductOpen] =
    useState(false);

  const [getUpdatedProducts] = useLazyProductsQuery();
  const [value, setValue] = useState(
    sessionStorage.getItem("isAutoRefillOn") === "true" ? true : false
  );
  const { voucher } = useSelector((state) => state.modalState);
  sessionStorage.setItem("isAutoRefillOn", value);
  const { productPlanForModal } = useSelector((state) => state.modalState);

  const { dispatch, setIsOpenPopup, setPopupComponent } =
    useSelectPopupPomponent();
  const [redeemVoucher, response] = useVoucherRedeemMutation();
  const [createCheckoutSession, resp] = useCreateCheckoutSessionMutation();

  const profile = useSelector((state) => state.profile);
  useEffect(() => {
    if (value === true && !voucher.freeProduct) setIsOpen(true);
    if (voucher?.freeProduct) {
      if (value) setIsDisableFreeProductOpen(true);
      setValue(false);
      dispatch(setIsAutoRefill(false));
    }
  }, [value]);

  const handleBuyNowClick = async () => {
    sessionStorage.setItem("plan", JSON.stringify(productPlanForModal));

    if (voucher.freeProduct) {
      dispatch(setIsFreeProduct(true));
      const resp = await redeemVoucher({
        code: voucher.code,
        planId: productPlanForModal.stripeProductId,
        productId: productPlanForModal.productId,
        country: productPlanForModal.country,
      });
      if (resp.data) {
        getUpdatedProducts();
        if (data?.length === 0) {
          sessionStorage.setItem("plan", JSON.stringify(productPlanForModal));
          dispatch(setPopupComponent("PaymentSuccessful"));
        }
        if (data?.length) {
          dispatch(setProductPlanForModal(resp.data));
          dispatch(setPopupComponent("PurchaseSuccess"));
        }
        MixpanelTrackEvent.couponRedeemEvent(productPlanForModal, voucher);
        sessionStorage.setItem("plan", JSON.stringify(resp.data));
        dispatch(setProductPlanForModal(resp.data));
      } else if (
        resp.error.data.message.message ===
        "This plan is not available anymore."
      ) {
        dispatch(setPopupComponent("PlanNotAvailableToReOrderInfo"));
      } else if (resp.error) throw new Error(resp.error.data.message);
    } else {
      const resp = await createCheckoutSession({
        productId: productPlanForModal.stripeProductId,
        coupon: voucher.code,
      });
      if (resp.data) {
        sessionStorage.setItem("voucher", JSON.stringify(voucher));
        window.location.href = resp.data.url;
      }
      if (
        resp.error.data.message.message ===
        "This plan is not available anymore."
      ) {
        dispatch(setPopupComponent("PlanNotAvailableToReOrderInfo"));
      } else if (resp.error) throw new Error(resp.error.data.message);
    }
  };

  const renderCalculatedDiscount = () => {
    if (voucher?.discount.type === "PERCENT") {
      return (
        <div className="normal-12 sm:text-start">
          $
          {(
            (productPlanForModal?.priceBundle *
              voucher?.discount?.percent_off) /
            100
          )?.toFixed(2)}{" "}
          <span>({voucher?.discount?.percent_off}% OFF)</span>
        </div>
      );
    }
    if (voucher?.discount.type === "AMOUNT") {
      return (
        <div className="normal-12 sm:text-start">
          ${(voucher?.discount.amount_off / 100).toFixed(2)} OFF
        </div>
      );
    }
  };
  const renderVoucherDiscountApplied = (
    <div className="flex items-center gap-5 sm:gap-2">
      <div
        className={`relative  flex-1 text-gray-400  focus-within:text-gray-600 `}
      >
        <div className=" teal normal-18 flex h-[calc(22px+2.326vw)] w-full flex-col justify-center rounded-md border border-[#52B1AE] bg-inherit pl-12  autofill:bg-transparent  focus:border-[#52B1AE]  focus:ring-[#52B1AE] sm:h-12">
          <div className="normal-18 sm:text-start">
            {sessionStorage.getItem("usedVoucher")}{" "}
            {voucher?.active && "Applied"}
          </div>
          {/* <div className="normal-12">
            ${renderCalculatedDiscount()} OFF{" "}
            {voucher?.discount?.percent_off && (
              <span>({voucher?.discount?.percent_off}%)</span>
            )}
          </div> */}
          {renderCalculatedDiscount()}
        </div>
        <img
          src={checkmarkIcon}
          className="absolute top-1/2  left-4 block -translate-y-1/2 text-sm font-medium text-gray-700 transition-all sm:left-3"
          alt={""}
        ></img>
      </div>
      <button
        onClick={() => {
          dispatch(setPopupComponent("BuyNow"));
        }}
        className={` medium-20 bg-teal flex h-[calc(22px+2.326vw)] w-[35%] items-center justify-center rounded-lg border border-transparent  py-4 px-4  text-xl font-medium text-white sm:h-12`}
      >
        Remove
      </button>
    </div>
  );

  return (
    <div>
      <NestedModal isOpen={isOpen} setIsOpen={setIsOpen} arrowColor="teal">
        <VoucherAutoRefill
          setIsOpen={setIsOpen}
          discountType={"a promo code"}
        />
      </NestedModal>
      <NestedModal isOpen={isInfoOpen} setIsOpen={setIsInfoOpen}>
        <InfoAutoRefill setIsOpen={setIsInfoOpen} />
      </NestedModal>
      <NestedModal
        isOpen={isDisableFreeProductOpen}
        setIsOpen={setIsDisableFreeProductOpen}
      >
        <DisableFreeProductForAutoRefill
          setIsOpen={setIsDisableFreeProductOpen}
          type="Promo Code"
        />
      </NestedModal>
      <div
        className={`${
          isOpen || isInfoOpen || isDisableFreeProductOpen
            ? "hidden"
            : "flex sm:flex-col"
        }  w-full  transform overflow-hidden rounded-[20px]  bg-white text-left align-middle shadow-xl transition-all sm:w-[90vw]`}
      >
        <div className="save-payment-method relative w-[calc(141px+15.729vw)]   md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>

        <div className="w-[calc(151px+18.729vw)] py-[calc(3px+1.389vw)] px-[calc(9px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
          <div className="mt-5">
            <div className="teal medium-26 ">
              <span className="capitalize">{profile?.firstName}, </span>
              <br />
              you selected:
            </div>
            <div className="teal medium-26  mt-5">
              {productPlanForModal?.productCategory} $
              {productPlanForModal?.priceBundle?.toFixed(2)}
            </div>
            <div className="normal-22 mb-5 font-normal text-[#383838]">
              {productPlanForModal?.country} -{" "}
              <RenderTotalData
                data={productPlanForModal.data}
              ></RenderTotalData>{" "}
              - Valid for {productPlanForModal?.validity} days
            </div>
          </div>
          <div className="mb-[calc(13px+1.389vw)] mt-[calc(8px+0.868vw)] space-y-[calc(8px+0.868vw)] ">
            <div className="mb-5 flex">
              <label
                className={`${
                  voucher?.freeProduct ? "text-gray-400" : "teal"
                } normal-18 flex gap-2`}
              >
                <input
                  disabled={voucher?.freeProduct}
                  checked={value}
                  value={value}
                  onChange={(e) => setValue(!value)}
                  type={"checkbox"}
                  className={`${
                    voucher?.freeProduct
                      ? "border-gray-400"
                      : "border-[#52B1AE]"
                  }  mr-[0.83vw] mt-1 border-[#52B1AE] bg-inherit checked:bg-[#52B1AE] hover:checked:bg-[#52B1AE]  focus:ring-transparent focus:checked:bg-[#52B1AE] md:mt-0 sm:mt-0`}
                />
                Enable Auto Refill
              </label>
              <BsQuestionCircle
                className="teal ml-3 mt-1 inline  md:mt-0 sm:mt-0"
                onClick={() => setIsInfoOpen(true)}
              />
            </div>

            {renderVoucherDiscountApplied}
          </div>

          <div className="mb-3">
            <ButtonForModal
              color="teal"
              isSubmitting={
                resp.status === "pending" || response.status === "pending"
              }
              onClick={handleBuyNowClick}
            >
              {voucher?.freeProduct ? "Continue" : "Buy Now"}
            </ButtonForModal>
          </div>
        </div>
      </div>
    </div>
  );
}
