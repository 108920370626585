import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import "./Model.css";
import phoneIcon from "../../resources/icons/FormFieldIcons/Group582.svg";
import userIcon from "../../resources/icons/FormFieldIcons/Group578.svg";
import emailIcon from "../../resources/icons/FormFieldIcons/email.svg";
import passwordIcon from "../../resources/icons/FormFieldIcons/Layer_1.svg";
import cardIcon from "./images/credit-card/credit-card.svg";
import { useSelector } from "react-redux";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import SubmitButton from "../../components/SubmitButton.component";
import {
  useGetStripeCustomerDetails_Query,
  useUpdateUserInfoMutation,
} from "../../utils/store";
import PhoneInputField from "../../components/PhoneInputField.component";
import NestedModal from "../../components/NestedModal.component";
import ChangePassword from "./ChangePassword";
import SavedPaymentMethodes from "./SavedPaymentMethodes";
import ErrorMessageComponent from "../../components/ErrorMessage.component";
import { RxCross2 } from "react-icons/rx";
import { MixpanelTrackEvent } from "../../mixpanelEvents";
import useSetProfileData from "../../Hooks/useSetProfileData";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MyAccountEdit() {
  const [isManageMyPlansOpen, setIsManageMyPlansOpen] = useState(false);
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
  const [cardToShow, setCardToShow] = useState("");
  const { data: stripeCardDetails } = useGetStripeCustomerDetails_Query();
  const [editResponseMsg, setEditResponseMsg] = useState();

  useEffect(() => {
    if (stripeCardDetails) {
      const defaultCard = stripeCardDetails?.filter(
        (card) => card.default_source
      );
      if (defaultCard?.length) {
        setCardToShow(defaultCard[0]);
      } else setCardToShow(stripeCardDetails[0]);
    }
  }, [stripeCardDetails]);

  useEffect(() => {
    let showMessage = setTimeout(() => {
      setEditResponseMsg("");
    }, 5000);

    return () => {
      clearTimeout(showMessage);
    };
  }, [editResponseMsg]);

  const profile = useSelector((state) => state.profile);
  const { setProfile } = useSetProfileData();
  const { dispatch, setPopupComponent, setIsOpenPopup } =
    useSelectPopupPomponent();
  const [updateUser, resp] = useUpdateUserInfoMutation();

  const handleChangePassword = () => {
    // dispatch(setPopupComponent("ChangePassword"));
    setIsChangePasswordOpen(true);
  };

  const handleEditMyAccount = async (values) => {
    const editObj = { ...values };
    delete editObj.mobile;
    const response = await updateUser({
      firstName: values.firstName,
      lastName: values.lastName,
      countryCode: values.mobile.split(".")[0],
      mobile: values.mobile.split(".")[1],
    });
    if (response) {
      setProfile();
      MixpanelTrackEvent.updatedProfileEvent();
      setEditResponseMsg(response?.data.message);
    }
  };

  const handleManagePaymentMethode = () => {
    // dispatch(setPopupComponent("SavedPaymentMethodes"));
    setIsManageMyPlansOpen(true);
  };

  const formFieldValues = [
    {
      name: "firstName",
      type: "test",
      placeholder: "First Name",
      icon: userIcon,
    },
    {
      name: "lastName",
      type: "text",
      placeholder: "Last Name",
      icon: userIcon,
    },
    {
      name: "email",
      type: "email",
      placeholder: "Email Address",
      icon: emailIcon,
      isReadOnly: true,
    },
    {
      name: "mobile",
      type: "number",
      placeholder: "Phone Number",
      icon: phoneIcon,
    },
    {
      name: "password",
      type: "password",
      placeholder: "Password",
      icon: passwordIcon,
      buttonName: "Change",
      event: handleChangePassword,
      textSize: "normal-14",
      isReadOnly: true,
    },
    // {
    //   name: "card",
    //   type: "text",
    //   placeholder: "Card",
    //   icon: cardIcon,
    //   buttonName: "Manage Payment Method",
    //   event: handleManagePaymentMethode,
    //   textSize: "normal-12",
    //   isReadOnly: true,
    // },
  ];

  const renderFormFields = formFieldValues.map((field) => {
    return (
      <div
        key={field.name}
        className={`relative w-full text-gray-400 focus-within:text-gray-600  sm:w-full ${
          field.name === "mobile"
            ? "phone-form-control form-input flex items-center rounded-md border border-[#c42f52] bg-inherit text-sm text-white"
            : ""
        }`}
      >
        {field.name === "mobile" ? (
          <div className="modal-tel-input-container edit-phone-input">
            <Field
              name={field.name}
              component={PhoneInputField}
              profile={profile}
              isEdit={true}
              isPopUp={true}
            />
          </div>
        ) : (
          <Field
            disabled={field.isReadOnly}
            name={field.name}
            placeholder={field.placeholder}
            id={field.name}
            className={`${
              field.name === "email" ? "bg-gray-300" : "bg-inherit"
            } rm-font-overpass ${
              window.innerWidth > 576 ? "normal-18" : "text-[0.875rem]"
            } form-input relative block h-[calc(22px+2.326vw)] w-full  rounded-md border border-[#c42f52] pl-16 text-black  placeholder:text-black/[0.65] autofill:bg-transparent focus:border-[#c42f52]  focus:ring-[#c42f52] md:h-16 sm:h-12`}
          />
        )}

        <img
          src={field.icon}
          className={classNames(
            "formIcon-left   absolute top-1/2  left-7 block  -translate-y-1/2 text-sm font-medium text-gray-700 transition-all",
            field.icon === passwordIcon
              ? "max-h-[calc(7px+0.736vw)] max-w-[calc(6px+0.638vw)]"
              : field.icon === emailIcon
              ? "max-h-[calc(5px+0.508vw)] max-w-[calc(7px+0.692vw)]"
              : field.icon === phoneIcon
              ? "max-h-[calc(7px+0.694vw)] max-w-[calc(7px+0.694vw)]"
              : "max-h-[calc(6px+0.625vw)] max-w-[calc(6px+0.625vw)]"
          )}
          alt={field.name}
        ></img>

        {field.buttonName && (
          <span
            className={`${field.textSize} pink formIcon-right absolute right-3 top-1/2 block  -translate-y-1/2 cursor-pointer  transition-all md:right-2  sm:right-2`}
            onClick={field.event}
          >
            {field.buttonName}
          </span>
        )}
      </div>
    );
  });

  return (
    <div className="sm:w-[90vw]  sm:pb-6">
      <NestedModal
        isOpen={isChangePasswordOpen}
        setIsOpen={setIsChangePasswordOpen}
        arrowColor="pink"
      >
        <ChangePassword setIsOpen={setIsChangePasswordOpen} />
      </NestedModal>
      <NestedModal
        isOpen={isManageMyPlansOpen}
        setIsOpen={setIsManageMyPlansOpen}
      >
        <SavedPaymentMethodes setIsOpen={setIsManageMyPlansOpen} />
      </NestedModal>
      <div
        className={`${
          isChangePasswordOpen || isManageMyPlansOpen
            ? "hidden"
            : "flex  sm:flex-col"
        } w-full`}
      >
        <div className="my-account w-[calc(151px+15.729vw)]  md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
        <div className="mb-5  w-[calc(151px+15.729vw)] p-[calc(15px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:mb-0 sm:w-full">
          <Formik
            initialValues={{
              // card: "AMEX - ****7890",
              email: profile.email,
              mobile: profile.mobile,
              password: "*******",
              lastName: profile.lastName,
              firstName: profile.firstName,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.firstName) {
                errors.firstName = "First Name Required";
              } else if (values.firstName.length > 15) {
                errors.firstName =
                  "First Name should not contain more than 15 characters";
              } else if (!values.lastName) {
                errors.lastName = "Last Name Required";
              } else if (values.lastName.length > 20) {
                errors.lastName =
                  "Last Name should not contain more than 20 characters";
              } else if (!values.mobile?.split(".")[1].length) {
                errors.mobile = "Mobile Number Required";
              } else if (values.mobile?.split(".")[1].length > 18) {
                errors.mobile =
                  "Mobile Number should not be more than 18 digits";
              }
              return errors;
            }}
            onSubmit={(values) => {
              handleEditMyAccount(values);
            }}
          >
            {({ isSubmitting }) => (
              <Form
                className="md:formField-spacing space-y-6"
                // onSubmit={handleSubmit}
              >
                <div className="pink medium-26 text-center">My Account</div>
                <div className="space-y-6 text-center">{renderFormFields}</div>
                <div className="rm-font-overpass normal-18  form-input relative flex h-[calc(22px+2.326vw)]  w-full items-center  rounded-md border border-[#c42f52] bg-inherit pl-16 text-black  md:h-16 sm:h-12">
                  <img
                    src={cardIcon}
                    className="formIcon-left   absolute top-1/2  left-7 block  max-h-[calc(6px+0.625vw)] max-w-[calc(6px+0.625vw)] -translate-y-1/2 text-sm font-medium  transition-all"
                    alt={"Card"}
                  ></img>
                  <div
                    className={`medium-18 capitalize opacity-70 ${
                      !cardToShow && "invisible"
                    }`}
                  >
                    {cardToShow?.card?.brand} - ****{cardToShow?.card?.last4}
                  </div>
                  <span
                    className={`normal-14 pink formIcon-right absolute right-3 top-1/2 block  -translate-y-1/2 cursor-pointer  transition-all md:right-2  sm:right-2`}
                    onClick={handleManagePaymentMethode}
                  >
                    Manage Cards
                  </span>
                </div>
                <SubmitButton isSubmitting={resp?.status === "pending"}>
                  Save Changes
                </SubmitButton>
                <div className="relative w-full">
                  <ErrorMessageComponent
                    formFieldValues={formFieldValues}
                    submissionError={resp.error}
                  />
                  {editResponseMsg && (
                    <div className="relative">
                      <div
                        className={`normal-14 absolute  mb-3 flex w-full items-center justify-between rounded-md bg-green-500 py-2 font-light text-white`}
                      >
                        <span className="px-[calc(7px+0.694vw)] text-center">
                          {editResponseMsg}
                        </span>
                        <div className="mr-[calc(7px+0.694vw)] flex h-[calc(8px+0.833vw)] w-[calc(8px+0.833vw)] cursor-pointer items-center">
                          <RxCross2 onClick={() => setEditResponseMsg("")} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
