import React from "react";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import { setDashboardTabIndex } from "../../utils/store";
import ButtonForModal from "./ButtonForModal";
import "./Model.css";
export default function PurchaseSuccess() {
  const { dispatch, setIsOpenPopup } = useSelectPopupPomponent();

  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col">
      <div className="auto-refill w-[calc(134px+13.924vw)]  md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="modal-content w-[calc(149px+15.521vw)] space-y-3 p-[calc(23px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="medium-26 teal text-center">Zone Selection</div>
        <div className="medium-22 text-center">Coming Soon</div>
        <div className="hidden">
          nulla pariatur. Excepteur sint occaec at sgtl idatat non proident,
          sunt in culpa out srg qui , Lorem.
        </div>
        <div>
          <ButtonForModal
            color="teal"
            onClick={() => {
              dispatch(setDashboardTabIndex(0));
              dispatch(setIsOpenPopup(false));
            }}
          >
            Return to Country Selection
          </ButtonForModal>
        </div>
      </div>
    </div>
  );
}
