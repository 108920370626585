import React, { useState } from "react";
import FormField from "../components/FormField.component";
import {
  setBackButtonEventAuthPage,
  setProductPlanForModal,
  setSignupData,
  useResendMutation,
  useSignupMutation,
} from "../utils/store";
import { Formik, Form, Field, useFormikContext } from "formik";
import userIcon from "../resources/icons/FormFieldIcons/Group578.svg";
import emailIcon from "../resources/icons/FormFieldIcons/email.svg";
import passwordIcon from "../resources/icons/FormFieldIcons/Layer_1.svg";
import phoneIcon from "../resources/icons/FormFieldIcons/Group582.svg";
import ErrorMessageComponent from "../components/ErrorMessage.component";
import SubmitButton from "../components/SubmitButton.component";
import "intl-tel-input/build/css/intlTelInput.css";
import "intl-tel-input/build/js/intlTelInput.js";
import useSelectPopupPomponent from "../Hooks/use-select-popup-component";
import { MixpanelTrackEvent } from "../mixpanelEvents";
import { useEffect } from "react";
import giftIcon from "../resources/icons/gift-card-pink.svg";
import useToaster from "../Hooks/useToaster";
import useValidateSignup from "../Hooks/useValidateSignup";

const SignUp = ({
  setRenderedComponentSignup,
  setEmail,
  setSignupDetails,
  signupDetails,
  setOtp,
}) => {
  const [signup, resp] = useSignupMutation();
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  const [resendOtpCode] = useResendMutation();
  //just to prevent getting buy product modal like create-account modal signup flow
  dispatch(setProductPlanForModal(null));
  const { validate, setToast } = useValidateSignup();

  useEffect(() => {
    setOtp("");
  }, []);

  const handleSignup = async (values) => {
    setEmail(values.email);
    const singnupObj = { ...values };
    delete singnupObj.mobile;
    const resp = await signup({
      ...singnupObj,
      countryCode: values.mobile.split(".")[0],
      mobile: values.mobile.split(".")[1],
    });

    if (resp.data) {
      setRenderedComponentSignup("");
      dispatch(
        setSignupData({
          email: values.email,
          mobile: values.mobile,
          firstName: values.firstName,
          lastName: values.lastName,
        })
      );
      setSignupDetails({
        ...values,
        countryCode: values.mobile.split(".")[0],
        mobile: values.mobile.split(".")[1],
      });
    } else if (resp.error) {
      if (resp.error.data.verificationPending) {
        resendOtpCode(values.email);
        setRenderedComponentSignup();
      } else {
        setToast({ msg: resp.error.data.message, type: "error" });
        MixpanelTrackEvent.errorOnClickedCreateAccountButton(
          "Signup Page",
          values
        );
        throw new Error(resp.error.data.message);
      }
    }
  };

  const formFieldValues = [
    {
      name: "firstName",
      type: "text",
      placeholder: "First Name",
      icon: userIcon,
    },
    {
      name: "lastName",
      type: "text",
      placeholder: "Last Name",
      icon: userIcon,
    },
    {
      name: "email",
      type: "text",
      placeholder: "Email Address",
      icon: emailIcon,
    },
    {
      name: "mobile",
      type: "number",
      placeholder: "Mobile Number",
      icon: phoneIcon,
      profile: signupDetails,
      isStoreData: true,
    },
    {
      name: "password",
      type: "password",
      placeholder: "Create Password",
      icon: passwordIcon,
    },
    {
      name: "confirm-password",
      type: "password",
      placeholder: "Re-enter Password",
      icon: passwordIcon,
    },
  ];

  const renderFormFields = formFieldValues.map((field) => {
    return <FormField field={field} key={field.name} />;
  });
  return (
    <div className="mt-6 mb-12 flex h-4/5 flex-col justify-center md:mb-12">
      <div className="">
        <Formik
          initialValues={
            signupDetails || {
              email: "",
              password: "",
              firstName: "",
              lastName: "",
              mobile: "",
              "confirm-password": "",
              toggle1: false,
              toggle2: false,
              refereeCode: "",
            }
          }
          validate={(values) => {
            // const errors = {};
            // if (!values.firstName) {
            //   errors.firstName = "First Name Required";
            // } else if (values.firstName.length > 15) {
            //   errors.firstName =
            //     "First Name should not contain more than 15 characters";
            // } else if (!values.lastName) {
            //   errors.lastName = "Last Name Required";
            // } else if (values.lastName.length > 20) {
            //   errors.lastName =
            //     "Last Name should not contain more than 20 characters";
            // } else if (!values.email) {
            //   errors.email = "Email Required";
            // } else if (
            //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            // ) {
            //   errors.email = "Invalid email address";
            // } else if (!values.mobile?.split(".")[1].length) {
            //   errors.mobile = "Mobile no. Required";
            // } else if (values.mobile?.split(".")[1].length > 18) {
            //   errors.mobile = "Mobile no. should not be more than 18 digits";
            // } else if (!values.password) {
            //   errors.password = "Password Required";
            // } else if (!/^\S*$/.test(values.password)) {
            //   errors.password = "Password should not contain space.";
            // } else if (values.password?.length < 8) {
            //   errors.password = "Password should contain minimum 8 characters.";
            // } else if (values["confirm-password"] !== values["password"]) {
            //   errors["confirm-password"] = "Password does not match";
            // } else if (!values.toggle1) {
            //   errors.toggle1 =
            //     "Please accept that you have an eSIM enabled and Carrier Unlocked phone";
            // } else if (!values.toggle2) {
            //   errors.toggle2 =
            //     "Please accept the Privacy Policy and Terms & Conditions";
            // }
            // return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            const isError = validate(values);
            if (isError) return;
            handleSignup(values);
            dispatch(setBackButtonEventAuthPage("signup"));
            MixpanelTrackEvent.clickedCreateAccountButton(
              "Signup Page",
              values
            );
          }}
        >
          {({ isSubmitting }) => (
            <Form
              className="formField-spacing space-y-6 text-white md:text-black sm:text-black"
              // onSubmit={handleSubmit}
            >
              {renderFormFields}
              <CalculateForMixpanal />

              <div className="normal-14 ml-[0.83vw] font-light ">
                {/* <div className="mb-4  sm:mb-2">
                  I understand I need a network unlocked, eSIM enabled device to
                  use this service{" "}
                  <BsInfoCircle
                    className="pink ml-2 inline"
                    onClick={() =>
                      dispatch(setPopupComponent("InfoOnSignupPage"))
                    }
                  />
                </div> */}
                <div className="mb-4 flex sm:mb-2">
                  <label className="rm-font-overpass">
                    <Field
                      type="checkbox"
                      name="toggle1"
                      className="rm-font-overpass mr-[0.83vw] border-[#c42f52] bg-inherit checked:bg-[#c42f52] hover:text-[#c42f52] focus:ring-transparent focus:ring-offset-0 focus:checked:bg-[#c42f52] sm:mx-3"
                    />
                    I confirm my phone is an{" "}
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href="https://ridemobile.zohodesk.com/portal/en/kb/articles/what-esim-enabled-devices-does-ride-mobile-support"
                      className="text-[#c42f52] underline "
                      onClick={() =>
                        MixpanelTrackEvent.eSIMEnabledClick("Signup Page")
                      }
                    >
                      eSIM enabled
                    </a>{" "}
                    and{" "}
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href="https://ridemobile.zohodesk.com/portal/en/kb/articles/how-do-i-know-if-my-iphone-is-unlocked"
                      className="text-[#c42f52] underline "
                      onClick={() =>
                        MixpanelTrackEvent.carrierUnlockedClick("Signup Page")
                      }
                    >
                      Carrier Unlocked
                    </a>{" "}
                    iPhone
                  </label>
                </div>
                <div className="mb-4 flex sm:mb-2">
                  <label className="rm-font-overpass">
                    <Field
                      type="checkbox"
                      name="toggle2"
                      className="rm-font-overpass mr-[0.83vw] border-[#c42f52] bg-inherit checked:bg-[#c42f52] hover:text-[#c42f52] focus:ring-transparent focus:ring-offset-0 focus:checked:bg-[#c42f52] sm:mx-3"
                    />
                    I agree to the Ride Mobile{" "}
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href="https://www.ridemobile.com/privacy-policy"
                      className="text-[#c42f52] underline "
                    >
                      Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href="https://www.ridemobile.com/terms-and-conditions"
                      className="text-[#c42f52] underline "
                    >
                      Terms & Conditions
                    </a>
                  </label>
                </div>
              </div>

              <div className="relative  w-full text-gray-400 focus-within:text-gray-600">
                <Field
                  type={"text"}
                  name="refereeCode"
                  placeholder="Referral Code"
                  id="refereeCode"
                  className="rm-font-overpass form-input relative block h-[calc(32px+2.4vh)] w-full rounded-md border  border-[#c42f52] bg-inherit pl-16 text-lg font-light text-white  placeholder:text-white/70 autofill:bg-transparent focus:border-[#c42f52] focus:ring-[#c42f52] md:h-16  md:text-black  md:placeholder:text-black/70 sm:h-12 sm:text-black sm:placeholder:text-black/70"
                />
                <img
                  src={giftIcon}
                  className="formIcon-left  absolute top-1/2  left-7 block  max-h-[calc(10px+0.736vw)] max-w-[calc(9px+0.638vw)] -translate-y-1/2 text-sm font-medium text-gray-700 transition-all sm:max-h-5 sm:max-w-[18px]"
                  alt={""}
                ></img>
              </div>

              <SubmitButton isSubmitting={resp.status === "pending"}>
                Create Account
              </SubmitButton>
              {/* <div className="relative">
                <ErrorMessageComponent
                  formFieldValues={[
                    ...formFieldValues,
                    { name: "toggle1" },
                    { name: "toggle2" },
                  ]}
                  submissionError={resp.error}
                  mb={0}
                />
              </div> */}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default SignUp;

const CalculateForMixpanal = () => {
  const { values } = useFormikContext();
  useEffect(() => {
    if (values.firstName.length === 1)
      MixpanelTrackEvent.startedAccountCreation("Signup Page");
  }, [values.firstName]);
};
