import React from "react";
import { RxCross2 } from "react-icons/rx";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import ButtonForModal from "./ButtonForModal";
export default function IphoneOnly() {
  const { dispatch, setIsOpenPopup } = useSelectPopupPomponent();
  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col">
      <div className="iphone-only  w-[calc(151px+15.729vw)]    md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="w-[calc(151px+15.729vw)] p-[calc(13px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="medium-18 p-4 pt-4 text-center ">
          Currently, our plans are for iPhone devices.
        </div>
        <div className="normal-18 text-center">
          <div>By clicking 'Continue' you confirm</div>
          <div>you have an iphone that is </div>
          <div>
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://ridemobile.zohodesk.com/portal/en/kb/articles/what-esim-enabled-devices-does-ride-mobile-support"
              className="text-[#c42f52] underline "
            >
              eSIM enabled
            </a>{" "}
            and{" "}
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://ridemobile.zohodesk.com/portal/en/kb/articles/how-do-i-know-if-my-iphone-is-unlocked"
              className="text-[#c42f52] underline "
            >
              Carrier Unlocked
            </a>
          </div>
        </div>
        <div className="normal-18 my-4 text-center">
          Android plans are coming soon
        </div>

        <ButtonForModal onClick={() => dispatch(setIsOpenPopup(false))}>
          Continue
        </ButtonForModal>
        <button
          className="normal-12 absolute right-4 top-4 flex items-center gap-3  whitespace-nowrap rounded-lg bg-white px-4 py-2 focus-visible:outline-none sm:h-8"
          onClick={() => dispatch(setIsOpenPopup(false))}
        >
          <RxCross2 className="pink text-2xl sm:text-base" />
        </button>
      </div>
    </div>
  );
}
