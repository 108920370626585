import React from "react";
import Skeleton from "../../components/Skeleton.component";

export default function CountryPlansSkelotons() {
  return (
    <div className="grid grid-cols-4 gap-6 md:grid-cols-2 sm:grid-cols-1">
      <Skeleton times={4} className=" h-[calc(58px+6.076vw)] rounded-xl" />
    </div>
  );
}
