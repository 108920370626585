import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../utils/Auth/authChecker";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLogin } = useAuth();

  if (isLogin()) return <Outlet />; // or loading indicator, etc...
  return <Navigate to={"/auth"} replace />;
};

export default PrivateRoute;
