import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import ButtonForModal from "./ButtonForModal";
import "./Model.css";
import slashIcon from "./images/-/-@2x.png";
import { useUpdateCardMutation } from "../../utils/store";
import cardIcon from "./images/Layer 46/Layer 46@2x.png";
import ErrorMessageComponent from "../../components/ErrorMessage.component";
import { RxCross2 } from "react-icons/rx";

export default function EditCard({ card, setIsOpen }) {
  const [updateCard, res] = useUpdateCardMutation();
  const [editResponseMsg, setEditResponseMsg] = useState();
  
  const formFieldValues = [
    {
      name: "exp_month",
    },
    {
      name: "exp_year",
    },
    {
      name: "name",
    },
  ];
  
  useEffect(() => {
    let showMessage = setTimeout(() => {
      setEditResponseMsg("");
    }, 5000);

    return () => {
      clearTimeout(showMessage);
    }
  }, [editResponseMsg]);

  return (
    <div className=" flex w-full sm:w-[90vw] sm:flex-col">
      <div className="card-details w-[calc(151px+15.729vw)]  md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="normal-18 mb-6 w-[calc(151px+15.729vw)] space-y-6 p-[calc(13px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="medium-26 teal ">Edit Card</div>
        <div className="medium-26">Enter card details</div>

        <Formik
          initialValues={{
            number: `**** **** **** ${card.card.last4}`,
            exp_month: card.card.exp_month,
            exp_year: card.card.exp_year.toString().slice(2),
            cvc: "***",
            name: card["billing_details"]?.name,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.exp_month) {
              errors.exp_month = "Invalid month";
            } else if (values.exp_month > 12 || values.exp_month <= 0) {
              errors.exp_month = "Invalid month";
            } else if (!values.exp_year) {
              errors.exp_year = "Invalid year";
            } else if (!values.name) {
              errors.name = "Name cannot be empty";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const res = await updateCard({
              exp_month: values.exp_month,
              exp_year: values.exp_year,
              cardId: card.id,
              name: values.name,
            });
            if (res.data) {
              setEditResponseMsg("Card updated successfully");
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form className="formField-spacing space-y-6">
              <div>
                <div>Card number</div>
                <div className="relative ">
                  <Field
                    as="input"
                    disabled
                    name="number"
                    type={"text"}
                    // maxLength={16}
                    size={16}
                    id="card"
                    className="rm-font-overpassrelative normal-22 block  h-[calc(22px+2.326vw)]  w-full  rounded-md  border border-[#52B1AE] bg-gray-200 bg-inherit tracking-wide  text-black placeholder:text-black/70 autofill:bg-transparent  focus:ring-teal-500  focus-visible:outline-none  sm:h-12 "
                  />
                  <div className="absolute top-1/2 right-4 -translate-y-1/2">
                    {card.card.brand}
                  </div>
                </div>
              </div>
              <div>
                <div className="flex gap-4">
                  <span>Expiry date</span>
                  <span className="normal-14 font-light">e.g. 10/20</span>
                </div>
                <div className="flex gap-3">
                  <div className="flex flex-col">
                    <span>Month</span>
                    <Field
                      name="exp_month"
                      maxLength={2}
                      type={"number"}
                      id="exp_month"
                      className="rm-font-overpass normal-22 relative block h-[calc(22px+2.326vw)] w-[calc(23px+2.361vw)] rounded-md border  border-[#52B1AE] bg-inherit text-center text-black placeholder:text-black/70 autofill:bg-transparent   focus:ring-teal-500    focus-visible:outline-none sm:h-12 sm:w-12"
                    />
                  </div>
                  <div className="flex h-[calc(18px+2.326vw)] items-center self-end">
                    <img
                      src={slashIcon}
                      alt=""
                      className="h-[calc(13px+1.389vw)]"
                    ></img>
                  </div>
                  <div className="flex flex-col">
                    <span>Year</span>
                    <Field
                      name="exp_year"
                      maxLength={2}
                      type="number"
                      id="exp_year"
                      className="rm-font-overpass normal-22 relative block h-[calc(22px+2.326vw)] w-[calc(23px+2.361vw)] rounded-md border  border-[#52B1AE] bg-inherit text-center text-black placeholder:text-black/70 autofill:bg-transparent   focus:ring-teal-500    focus-visible:outline-none sm:h-12 sm:w-12"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div>Name on card</div>
                <Field
                  name="name"
                  maxLength={50}
                  id="card"
                  className="rm-font-overpassrelative normal-22 block h-[calc(22px+2.326vw)]  w-full  rounded-md  border  border-[#52B1AE] bg-inherit px-3 tracking-wide  text-black placeholder:text-black/70 autofill:bg-transparent  focus:ring-teal-500  focus-visible:outline-none  sm:h-12 "
                />
              </div>
              <div className="space-y-1">
                <div>Card security code</div>
                <div className="normal-14 font-light">
                  The last 3 digits on the back of the card
                </div>
                <div className="flex items-center gap-5">
                  <Field
                    disabled
                    name="cvc"
                    maxLength={3}
                    id="cvc"
                    className="rm-font-overpass normal-22 relative block h-[calc(22px+2.326vw)] w-[calc(55px+5.694vw)] rounded-md border  border-[#52B1AE] bg-gray-200 bg-inherit text-center text-black placeholder:text-black/70  autofill:bg-transparent   focus:ring-teal-500 focus-visible:outline-none sm:h-12"
                  />
                  <img
                    src={cardIcon}
                    alt=""
                    className="h-[calc(13px+1.354vw)]"
                  ></img>
                </div>
              </div>

              <div>
                <ButtonForModal
                  type="submit"
                  color={"teal"}
                  isSubmitting={res.status === "pending"}
                >
                  Continue
                </ButtonForModal>
              </div>
              <div className="relative">
                <ErrorMessageComponent
                  formFieldValues={formFieldValues}
                  submissionError={res.error}
                />
                  {
                    editResponseMsg && 
                    <div className="relative">
                      <div className={`mb-3 flex  w-full items-center justify-between rounded-md bg-green-500 py-2 font-light text-white absolute normal-14`}>
                        <span className="px-[calc(7px+0.694vw)] text-center">{editResponseMsg}</span>
                        <div className="mr-[calc(7px+0.694vw)] flex h-[calc(8px+0.833vw)] w-[calc(8px+0.833vw)] cursor-pointer items-center">
                          <RxCross2 onClick={() => setEditResponseMsg("")} />
                        </div>
                      </div>
                    </div>
                  }
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
