import React, { useEffect } from "react";
import { BiLoaderAlt } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import DateFormator from "../../components/DateFormator.component";
import ProgressionBar from "../../components/ProgressionBar.component";
import SwitchButton from "../../components/Switch.component";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import {
  setProductPlanForModal,
  useGetEsimStatusQuery,
  useLazyGetUsageDataQuery,
  useProductsQuery,
  useSubscribePlanMutation,
} from "../../utils/store";
import RenderTotalData from "../../components/RenderTotalData.component";
import useSettingsInstructions from "../../Hooks/use-settings-instructions";

export default function Active() {
  let location = useLocation();
  const navigate = useNavigate();
  const { data, refetch: refetchProductData } = useProductsQuery();
  const { data: esimStatus } = useGetEsimStatusQuery();

  const [subscribePlan, resp] = useSubscribePlanMutation();
  const [getDataUsage, usageData] = useLazyGetUsageDataQuery();
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  const { SettingInstructions } = useSettingsInstructions();

  const handleActivatePlan = async (plan) => {
    dispatch(setProductPlanForModal(plan));
    // const esimStatus = await refetchEsim();
    dispatch(setProductPlanForModal(plan));
    if (esimStatus.esimStatus !== "installed") {
      dispatch(setPopupComponent("SimInstallation"));
    }
    if (esimStatus.esimStatus === "installed") {
      if (!activetPlan?.length) {
        const resp = await subscribePlan({
          productId: plan._id,
          body: {
            productId: plan._id,
            transactionId: plan.transactionId,
            planId: plan.productId,
            upcoming: plan.status === "upcoming",
            country: plan.country,
          },
        });
        if (resp.data) {
          refetchProductData();
        } else throw new Error(resp.error.data.message);
      } else dispatch(setPopupComponent("ActivatePlanNow"));
    }
  };

  let renderProductPlan;
  const activetPlan = data?.filter((plan) => {
    return plan.status === "active";
  });

  useEffect(() => {
    if (activetPlan?.length) getDataUsage();
  }, [data]);
  renderProductPlan = activetPlan?.length && activetPlan[0];
  if (!renderProductPlan)
    return (
      <div className="medium-20 h-64 pt-10 text-center">No active plans</div>
    );

  return (
    <div
      className={`mb-[3.5vw] flex min-h-[calc(90px+9.375vw)] items-stretch justify-center gap-12 md:gap-6 sm:my-8 sm:flex-col sm:items-center sm:gap-6 `}
    >
      <div
        className={`subscription-sec-1 space-y-2 px-[calc(15px+2vw)] py-[calc(8px+1.389vw)] text-white md:space-y-3 md:px-[calc(8px+2vw)] ${
          renderProductPlan.status === "active" ? " md:pt-8" : " md:pt-4"
        } sm:h-full sm:w-[85vw] sm:py-6`}
      >
        <div className="medium-32 mb-[calc(0.533vw)] ">
          Your Plan:{" "}
          <span className="uppercase">
            {renderProductPlan.status === "upcoming"
              ? "NOT ACTIVE"
              : renderProductPlan.status}
          </span>
        </div>
        <div
          className={`medium-28 space-y-1 ${
            renderProductPlan.status === "active"
              ? "md:space-y-3"
              : "md:space-y-1"
          } `}
        >
          <div
            className={`${renderProductPlan.status === "upcoming" && "flex"} ${
              renderProductPlan.status === "active"
                ? "md:space-y-3"
                : "md:space-y-1"
            } space-y-0.5  md:flex-col sm:flex-col`}
          >
            <div>{renderProductPlan.productCategory}</div>

            <div>
              - {renderProductPlan.country}{" "}
              <RenderTotalData data={renderProductPlan.data}></RenderTotalData>{" "}
            </div>
          </div>

          <div>- Valid for {renderProductPlan.validity} days</div>
        </div>
        <button
          className="normal-20 mt-4 w-full rounded-lg border border-white py-4 text-center sm:py-2"
          onClick={() => SettingInstructions()}
        >
          {resp.status === "pending" ? (
            <BiLoaderAlt
              className={`mx-auto animate-spin text-4xl`}
            ></BiLoaderAlt>
          ) : (
            "Settings Instructions"
          )}
        </button>
      </div>
      <div className="subscription-sec-2 flex  h-auto py-5 px-[calc(23px+2.431vw)] md:px-[calc(10px+2.279vw)] sm:w-[85vw] sm:flex-col sm:space-y-3 sm:px-[calc(13px+2.431vw)]">
        <div className="sm:flex sm:flex-col sm:items-center">
          <div className="orange medium-32 text-center">Data Usage:</div>
          <ProgressionBar dataUsage={usageData} />
        </div>
        <div className="flex flex-col pl-[calc(10px+2.472vw)] md:pl-[calc(10px+1.472vw)]">
          <SwitchButton
            plan={renderProductPlan}
            isAutoRefillOn={renderProductPlan.isAutoRefillOn}
            productId={renderProductPlan._id}
          />
          <div className="flex flex-1 items-center gap-[calc(20px+1.083vw)] md:justify-between md:gap-[calc(15px+1.083vw)] sm:mt-4 sm:justify-between">
            <div>
              <div className="medium-28 mb-1 text-[#383838]">Total Data</div>
              <RenderTotalData
                className=" orange semibold-24"
                data={renderProductPlan.data}
              ></RenderTotalData>
            </div>
            <div>
              <div className="medium-28 mb-1 text-[#383838]">Valid Until</div>
              <div className=" orange semibold-24 ">
                <DateFormator date={renderProductPlan?.expiryDate} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
