///// after forgot password //////////////////////////////////////
import React from "react";
import OtpInput from "react18-input-otp";
import ApiFetchingErrorMsg from "../../components/ApiFetchingErrorMsg.component";
import CountdownTimer from "../../components/CountdownTimer.component";
import SubmitButton from "../../components/SubmitButton.component";
import useCountdownTimer from "../../Hooks/use-countdown-timer";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import {
  useResendMutation,
  useValidateOtpMutation,
} from "../../utils/store/api/authApi";
import { useState } from "react";

const OtpPage = ({ setRenderedComponent, otp, setOtp, email }) => {
  const { showResendMsg, handleResetCountdown, time } = useCountdownTimer();
  const [resendOtpCode] = useResendMutation();
  const [validateOtp, response] = useValidateOtpMutation(); // forgot password
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  const [error, setError] = useState();

  const handleOtpVerification = async (e) => {
    setError();
    e.preventDefault();
    if (otp.length !== 6) {
      return setError({
        data: { message: "Invalid verification code, please try again" },
      });
    }

    const response = await validateOtp({ otp, email });

    if (response.data) {
      //render reset passward page
      setRenderedComponent("");
    }
  };

  return (
    <div className="mt-6 flex h-4/5 flex-col justify-center md:mb-12">
      <div className="signin-msgSection px-auto mx-8 space-y-4 pb-8 text-center text-white md:text-black sm:text-black">
        <div className="normal-26 ">Enter Verification Code</div>
        <div className="form-bottomText text-lg  font-light text-white md:text-black sm:text-black">
          A 6 digit code has been sent to your email address
        </div>
        <div className="form-bottomText text-lg  font-light text-white md:text-black sm:text-black">
          Time elapsed: <CountdownTimer />
        </div>
      </div>
      <form onSubmit={handleOtpVerification}>
        <div className="mb-4 flex justify-center">
          <OtpInput
            value={otp}
            inputStyle="otpinput"
            focusStyle="focus:ring-[#D05929]"
            onChange={(enteredOtp) => setOtp(enteredOtp)}
            numInputs={6}
            separator={<span></span>}
            isInputNum={true}
          />
        </div>
        <SubmitButton isSubmitting={response.status === "pending"}>
          Submit
        </SubmitButton>
        {showResendMsg ? (
          <div
            className="form-bottomText mt-6 cursor-pointer  text-center text-lg font-light text-white hover:text-orange-500 md:text-black sm:text-black"
            onClick={() => {
              localStorage.setItem(
                "countdown",
                +(Date.now() / 1000).toFixed() + 60
              );
              handleResetCountdown();
              resendOtpCode(email);
              dispatch(setPopupComponent("OtpVerificationSent"));
            }}
          >
            I did not receive a code
          </div>
        ) : (
          <div className="form-bottomText mt-6  text-center text-lg font-light text-white md:text-black sm:text-black">
            OTP has been sent to your email address if not recived retry in
            <span className="orange px-1 font-medium">{time}</span>seconds
          </div>
        )}
        <div className="relative">
          <ApiFetchingErrorMsg submissionError={error || response?.error} />
        </div>
      </form>
    </div>
  );
};

export default OtpPage;
