import React from "react";
import ButtonForModal from "./ButtonForModal";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { setGeoScope } from "../../utils/store/Slices/modalStateSlice";
import NestedModal from "../../components/NestedModal.component";
import { useState } from "react";
import InfoInCountry from "./InfoInCountry";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import { useSelector } from "react-redux";
import SettingInstructionsInternational from "./SettingInstructionsInternational";
import SettingInstructions from "./SettingInstructions";

export default function SettingsGeo() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDomesticOpen, setIsDomesticOpen] = useState(false);
  const [isInternationalOpen, setIsInternationalOpen] = useState(false);
  const { dispatch } = useSelectPopupPomponent();
  const profile = useSelector((state) => state.profile);

  return (
    <div>
      <NestedModal isOpen={isOpen} setIsOpen={setIsOpen} isBackButton={false}>
        <InfoInCountry setIsOpen={setIsOpen} />
      </NestedModal>
      <NestedModal isOpen={isDomesticOpen} setIsOpen={setIsDomesticOpen}>
        <SettingInstructions setIsOpen={setIsDomesticOpen} />
      </NestedModal>
      <NestedModal
        isOpen={isInternationalOpen}
        setIsOpen={setIsInternationalOpen}
      >
        <SettingInstructionsInternational setIsOpen={setIsInternationalOpen} />
      </NestedModal>
      <div
        className={`${
          isInternationalOpen || isDomesticOpen ? "hidden" : "flex sm:flex-col"
        }  w-full sm:w-[90vw] `}
      >
        <div className="settings-instructions  w-[calc(151px+15.729vw)]    md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
        <div className="normal-18 w-[calc(151px+15.729vw)] space-y-4 p-[calc(20px+1.259vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
          <div className="medium-26 teal">IMPORTANT INFORMATION</div>
          <div className="medium-24 teal">{profile?.firstName}:</div>
          <div>Follow the Settings Instruction on next screen</div>
          <div>
            The settings are different depending if you want to use Ride Mobile
            for In-Country or International Travel
          </div>
          <div>
            <span className="teal">In-Country: </span>Use Ride as a Range
            Extender
            <sup className="normal-12 ">TM</sup> within the country in which you
            have your primary service to get better coverage
          </div>
          <div>
            <span className="teal">International Travel: </span> Use Ride when
            traveling outside the country in which you have your primary service
            to avoid roaming fees
          </div>
          <div className="mx-auto w-[70%]">
            <ButtonForModal
              color="teal"
              onClick={() => {
                dispatch(setGeoScope("domestic"));
                setIsDomesticOpen(true);
                // dispatch(setPopupComponent("InstallationInCountry"));
              }}
            >
              In-Country
            </ButtonForModal>
            {/* <AiOutlineQuestionCircle
              className="teal absolute -right-5 top-1/2 -translate-y-1/2 transition-all "
              onClick={() => {
                setIsOpen(true);
              }}
            /> */}
          </div>
          <div className="mx-auto w-[70%]">
            <ButtonForModal
              onClick={() => {
                dispatch(setGeoScope("international"));
                // dispatch(
                //   setPopupComponent("InstallationInstructionsInternationalTravel")
                // );
                setIsInternationalOpen(true);
              }}
              color="teal"
            >
              International Travel
            </ButtonForModal>
          </div>
        </div>
      </div>
    </div>
  );
}
