import { Switch } from "@headlessui/react";
import { useAutoRefillMutation } from "../utils/store/api/activationApi";
import useSelectPopupPomponent from "../Hooks/use-select-popup-component";
import {
  setIsAutoRefill,
  useLazyGetStripeCustomerDetails_Query,
} from "../utils/store";
import { setProductPlanForModal } from "../utils/store";
import { useSelector } from "react-redux";
import { MixpanelTrackEvent } from "../mixpanelEvents";

export default function SwitchButton({ productId, plan }) {
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  const { isAutoRefill = plan.isAutoRefillOn } = useSelector(
    (state) => state.modalState
  );
  const [getStripeCardDetails, stripeCards] =
    useLazyGetStripeCustomerDetails_Query();
  const [autoRefill] = useAutoRefillMutation();

  return (
    <div className="flex items-center gap-3 pt-[calc(-5px+1.007vw)] text-[#383838] md:pt-[calc(1px+0.507vw)] sm:justify-center sm:pt-2">
      <span className="normal-18 w-[calc(50px+5.861vw)] font-light leading-none md:w-24 sm:w-24">
        {isAutoRefill ? "Auto Refill ON" : "Auto Refill OFF"}
      </span>
      <Switch
        checked={isAutoRefill}
        onChange={async (e) => {
          dispatch(setProductPlanForModal(plan));

          if (!isAutoRefill) {
            const cardsList = await getStripeCardDetails();

            ////get Default Card for enabling auto refill
            const defaultCard = cardsList?.data?.filter((card) => {
              return card.default_source;
            });
            if (!cardsList.data?.length) {
              dispatch(setPopupComponent("EnableAutoRefillSavePaymentMethode"));
            } else if (!defaultCard?.length) {
              dispatch(
                setPopupComponent("SavedPaymentMethodesAfterEnableAutoRefill")
              );
            } else {
              dispatch(setPopupComponent("EnableAutoRefillAccept"));
            }
          }
          if (isAutoRefill) {
            dispatch(setIsAutoRefill(e));
            dispatch(setPopupComponent("AutoRefillOff"));
            let resp = await autoRefill({ productId, flag: false });
            if (resp) MixpanelTrackEvent.autoRefillEvent(plan, false);
          }
        }}
        className={`${isAutoRefill ? "bg-teal" : "bg-gray-200"}
          relative inline-flex h-[calc(17px+0.451vw)] w-[calc(33px+0.868vw)] shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span className="sr-only text-black">Use setting</span>
        <span
          aria-hidden="true"
          className={`${
            isAutoRefill ? "translate-x-[calc(17px+0.486vw)]" : "translate-x-0"
          }
            pointer-events-none inline-block h-[calc(14px+0.339vw)] w-[calc(14px+0.339vw)] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
    </div>
  );
}
