import { Field, Form, Formik } from "formik";
import React from "react";
import ButtonForModal from "./ButtonForModal";
import "./Model.css";
import slashIcon from "./images/-/-@2x.png";
import {
  useAddNewCardMutation,
  useLazyGetStripeCustomerDetails_Query,
  useUpdateDefaultCardMutation,
} from "../../utils/store";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import cardLogos from "../../resources/icons/Group 460/Group460.png";
import { useSelector } from "react-redux";

export default function SavePaymentMethodAutorefill() {
  const { productPlanForModal: plan } = useSelector(
    (state) => state.modalState
  );
  const [addNewCard, res] = useAddNewCardMutation();
  const [getStripeCardDetails] = useLazyGetStripeCustomerDetails_Query();
  const { dispatch, setIsOpenPopup, setPopupComponent } =
    useSelectPopupPomponent();
  const [defaultCard] = useUpdateDefaultCardMutation();

  if (res.data) {
    // if(isFromAutorefill) dispatch(setPopupComponent(""))
    dispatch(setIsOpenPopup(false));
  }

  const handleKeyPress = (event, maxLength, isMonth) => {
    const input = event.target;
    if (event.charCode >= 48 && event.charCode <= 57) {
      if (input.value.length >= maxLength) {
        // if (input.value.length >= 5) {
        event.preventDefault();
      }
    } else {
      event.preventDefault();
    }
  };

  return (
    <div className="flex w-full sm:flex-col">
      <div className="save-payment-method w-[calc(151px+15.729vw)]   md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="normal-18 w-[calc(151px+15.729vw)] space-y-6 p-[calc(13px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="medium-26 teal ">Save your payment method</div>
        <div className="medium-26">Enter card details</div>
        <div>
          <div className="medium-26">
            {plan?.productCategory} ${plan.bundlePrice}
          </div>
          <div className="normal-22 text-[#383838]">
            {plan.country} - {plan.data} - Valid for {plan.validity} days
          </div>
        </div>
        <Formik
          initialValues={{
            number: "",
            exp_month: "",
            exp_year: "",
            cvc: "",
            name: "",
          }}
          validate={(values) => {}}
          onSubmit={async (values, { setSubmitting }) => {
            const cardDetails = await addNewCard(values);
            const cardsList = await getStripeCardDetails();

            if (cardsList.data?.length === 1) {
              defaultCard(cardDetails.data.id);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form className="formField-spacing space-y-6">
              <div>
                <div>Email</div>
                <Field
                  disabled
                  as="email"
                  id="email"
                  // onKeyPress={(event) => handleKeyPress(event, 16, false)}
                  className="rm-font-overpass normal-22 relative  block   h-[calc(22px+2.326vw)]  w-full rounded-md border border-black  bg-inherit   text-black placeholder:text-black/60  autofill:bg-transparent  focus:border-black focus:ring-transparent  focus-visible:outline-none "
                />
              </div>

              <div>
                <label htmlFor="number" className="mb-0 mt-4">
                  Card Information
                </label>
                <div className="relative">
                  <Field
                    placeholder="1234 1234 1234 1234"
                    as="input"
                    name="number"
                    type={"number"}
                    maxLength={16}
                    size={16}
                    id="card"
                    onKeyPress={(event) => handleKeyPress(event, 16, false)}
                    className="rm-font-overpass normal-22 relative block h-[calc(22px+2.326vw)]   w-full  rounded-t-md border border-black bg-inherit  pl-4   text-black placeholder:text-black/60  autofill:bg-transparent  focus:border-black focus:ring-transparent  focus-visible:outline-none "
                  />
                  <img
                    src={cardLogos}
                    alt=""
                    className="formIcon-right  absolute  right-7 top-1/2  block max-h-[calc(10px+0.825vw)] -translate-y-1/2 text-sm  font-medium transition-all"
                  ></img>
                </div>
                <div className="flex">
                  <div className="flex w-1/2 items-center rounded-bl-md border  border-black pl-4">
                    <Field
                      placeholder="MM"
                      name="exp_month"
                      maxLength={2}
                      type={"number"}
                      id="exp_month"
                      onKeyPress={(event) => handleKeyPress(event, 2, true)}
                      className="rm-font-overpass normal-22 relative block h-full w-1/4 border-transparent bg-inherit p-1 text-center text-black  placeholder:text-black/60 autofill:bg-transparent focus:border-transparent  focus:ring-transparent  focus-visible:outline-none"
                    />
                    <div className="flex h-[calc(18px+2.326vw)] items-center opacity-70">
                      <img
                        src={slashIcon}
                        alt=""
                        className="h-[calc(13px+1.389vw)]"
                      ></img>
                    </div>
                    <Field
                      placeholder="YY"
                      name="exp_year"
                      onKeyPress={(event) => handleKeyPress(event, 2, false)}
                      type="number"
                      id="exp_year"
                      className="rm-font-overpass normal-22 relative block h-full w-1/4 border-transparent bg-inherit p-0 text-center text-black placeholder:text-black/60 autofill:bg-transparent focus:border-transparent    focus:ring-transparent    focus-visible:outline-none"
                    />
                  </div>
                  <Field
                    placeholder="CVC"
                    name="cvc"
                    maxLength={3}
                    type={"number"}
                    id="exp_month"
                    onKeyPress={(event) => handleKeyPress(event, 3, false)}
                    className="rm-font-overpass normal-22 relative block h-[calc(22px+2.326vw)] w-1/2 rounded-br-md border border-black bg-inherit pl-4   text-black placeholder:text-black/60  autofill:bg-transparent  focus:border-black focus:ring-transparent  focus-visible:outline-none "
                  />
                </div>
              </div>

              <div>
                <div>Name on card</div>
                <Field
                  name="name"
                  maxLength={50}
                  id="card"
                  className="rm-font-overpass bg-inherittext-[calc(7px+0.694vw)] relative block h-[calc(22px+2.326vw)] w-full rounded-md  border border-black pl-4     text-black placeholder:text-black/60 autofill:bg-transparent  focus:border-black focus:ring-transparent  focus-visible:outline-none"
                />
              </div>
              <div className="border-collapse">
                <div>Country or Region</div>
                <Field
                  disabled
                  placeholder="United States"
                  name="country"
                  id="country"
                  // onKeyPress={(event) => handleKeyPress(event, 3, false)}
                  className="rm-font-overpass normal-22 relative block h-[calc(22px+2.326vw)]  w-full  rounded-t-md border border-black bg-inherit  pl-4   text-black placeholder:text-black/60  autofill:bg-transparent  focus:border-black focus:ring-transparent  focus-visible:outline-none "
                />

                <Field
                  disabled
                  placeholder="ZIP"
                  name="zip"
                  // maxLength={3}
                  type={"number"}
                  id="zip"
                  // onKeyPress={(event) => handleKeyPress(event, 3, false)}
                  className="rm-font-overpass normal-22 relative block h-[calc(22px+2.326vw)]  w-full  rounded-b-md border border-black bg-inherit  pl-4   text-black placeholder:text-black/60  autofill:bg-transparent  focus:border-black focus:ring-transparent  focus-visible:outline-none "
                />
              </div>
              <div>
                <ButtonForModal
                  type="submit"
                  color={"teal"}
                  isSubmitting={res.status === "pending"}
                >
                  Save
                </ButtonForModal>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
