import { Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import ButtonForModal from "./ButtonForModal";
import "./Model.css";
import discountIcon from "./images/Group 775.svg";
import {
  useConversionDetailsQuery,
  useGetEsimStatusQuery,
  useProductsQuery,
  useSubscribePlanMutation,
} from "../../utils/store";
import DateFormator from "../../components/DateFormator.component";
import { MixpanelTrackEvent } from "../../mixpanelEvents";
import RenderTotalData from "../../components/RenderTotalData.component";

export default function PurchaseSuccess() {
  const { data: conversionData } = useConversionDetailsQuery();
  const { isFreeProduct } = useSelector((state) => state.modalState);
  const { data: esimStatus } = useGetEsimStatusQuery();
  const isAutoRefillOn =
    sessionStorage.getItem("isAutoRefillOn") === "true" ? true : false;
  const { data, refetch: refetchProductData } = useProductsQuery();
  const [subscribePlan, response] = useSubscribePlanMutation();
  const activetPlan = data?.filter((plan) => {
    return plan.status === "active";
  });

  const profile = useSelector((state) => state.profile);
  const { dispatch, setPopupComponent, setIsOpenPopup } =
    useSelectPopupPomponent();
  const plan = JSON.parse(sessionStorage.getItem("plan"));

  const voucher = JSON.parse(sessionStorage.getItem("voucher"));

  const rideMiles = sessionStorage.getItem("ride-miles");

  const finalAmmount = () => {
    if (voucher?.discount.type === "PERCENT") {
      return (
        plan.priceBundle -
        (plan.priceBundle * voucher?.discount?.percent_off) / 100
      ).toFixed(2);
    }
    if (voucher?.discount.type === "AMOUNT") {
      return (plan.priceBundle - voucher?.discount.amount_off / 100).toFixed(2);
    }
  };

  useEffect(() => {
    const id = setTimeout(() => {
      sessionStorage.removeItem("voucher");
    }, 5000);
    return () => clearTimeout(id);
  }, []);

  const renderCouponDetails = (
    <div className="teal border-collapse">
      <div className="bg-teal w-full rounded-t-md border border-[#52B1AE] py-1 text-center  text-white">
        Promo Code : {voucher?.code}
      </div>
      <div className="flex w-full items-center rounded-b-md border border-[#52B1AE] ">
        <img src={discountIcon} className="ml-4" alt={""}></img>
        <div className="normal-20 mx-3">|</div>
        <div className="normal-14 mr-3 flex-1 py-1">
          <div className="flex justify-between">
            <div>Discount :</div>
            <div>
              {voucher?.discount.percent_off
                ? voucher?.discount.percent_off + "%"
                : "$" + (voucher?.discount.amount_off / 100)?.toFixed(2)}{" "}
              OFF
            </div>
          </div>
          <div className="flex justify-between">
            <div>Final Amount:</div>
            <div>${voucher?.freeProduct ? "0.00" : finalAmmount()}</div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderRideMilesDetails = (
    <div className="teal border-collapse">
      <div className="bg-teal w-full rounded-t-md border border-[#52B1AE] py-1 text-center  text-white">
        Ride Miles : {rideMiles}
      </div>
      <div className="flex w-full items-center rounded-b-md border border-[#52B1AE] ">
        <img src={discountIcon} className="ml-4" alt={""}></img>
        <div className="normal-20 mx-3">|</div>
        <div className="normal-14 mr-3 flex-1 py-1">
          <div className="flex justify-between">
            <div>Discount :</div>
            <div>
              {"US $" +
                (rideMiles / (conversionData?.moneyValue * 10)).toFixed(2)}{" "}
              OFF
            </div>
          </div>
          <div className="flex justify-between">
            <div>Final Amount:</div>
            <div>
              $
              {(
                plan.priceBundle -
                rideMiles / (conversionData?.moneyValue * 10)
              ).toFixed(2)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col">
      <div className="purchase-successful w-[calc(151px+15.729vw)]  md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="medium-20 w-[calc(151px+15.729vw)] space-y-3 p-[calc(13px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className={` ${isFreeProduct ? "hidden" : ""} medium-26 teal`}>
          <span className="capitalize">{profile?.firstName}</span>, your
          purchase was successful and your plan is ready to be activated
        </div>

        <div className="teal">
          {plan?.productCategory} $
          {plan?.priceBundle?.toFixed(2) || plan?.retailPrice?.toFixed(2)}
        </div>

        {(plan?.couponCode || voucher) && renderCouponDetails}
        {rideMiles && renderRideMilesDetails}

        <div>
          {plan?.country} - <RenderTotalData data={plan.data}></RenderTotalData>{" "}
          - Valid for {plan?.validity} days
        </div>
        <Formik
          initialValues={{
            picked: "Now",
          }}
          onSubmit={async (values) => {
            if (values.picked === "Now") {
              if (esimStatus.esimStatus !== "installed") {
                return dispatch(setPopupComponent("SimInstallation"));
              }
              if (!activetPlan?.length) {
                const resp = await subscribePlan({
                  productId: plan._id,
                  body: {
                    productId: plan._id,
                    transactionId: plan.transactionId,
                    planId: plan.productId,
                    upcoming: plan.status === "upcoming",
                    country: plan.country,
                  },
                });
                if (resp.data) {
                  refetchProductData();
                  dispatch(setIsOpenPopup(false));
                  profile?.isFirstTimeActivator &&
                    dispatch(
                      setPopupComponent("FirstPlanActivatedSuccessfully")
                    );
                  MixpanelTrackEvent.planActivation(plan, "Activated");
                } else {
                  MixpanelTrackEvent.planActivation(plan, "Failed");
                  throw new Error(resp.error.data.message);
                }
              } else
                dispatch(setPopupComponent("ActivatePlanNowAfterPurchase"));
            } else if (values.picked === "Manually") {
              dispatch(setPopupComponent("ActivatePlanManually"));
            }
          }}
        >
          {({ values }) => (
            <Form>
              <div role="group" aria-labelledby="my-radio-group">
                <div>
                  <label className="flex gap-2">
                    <Field
                      type="radio"
                      name="picked"
                      value="Now"
                      className="mr-2 mt-1.5 appearance-none items-center checked:bg-teal-500 focus:text-teal-500 focus:ring-transparent md:mt-0 sm:mt-0"
                    />
                    Activate my plan now
                  </label>
                </div>
                <div className="mt-2">
                  <label className="flex  gap-2">
                    <Field
                      type="radio"
                      name="picked"
                      value="Manually"
                      className="mr-2 mt-1.5 appearance-none checked:bg-teal-500 focus:text-teal-500 focus:ring-transparent  md:mt-0 sm:mt-0"
                    />
                    <div>
                      I will activate my plan manually in my account when I want
                      to use it{" "}
                    </div>
                  </label>
                </div>
              </div>
              <div className="mt-5 text-[#383838]">
                Auto Refill is{" "}
                {isAutoRefillOn === true ? "Enabled" : "Disabled"}
              </div>

              <ButtonForModal
                type="submit"
                color="teal"
                isSubmitting={response.status === "pending"}
              >
                Continue
              </ButtonForModal>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
