import { createSlice } from "@reduxjs/toolkit";

let initialState = [];

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    notifications: (state, action) => {
      return (state = action.payload);
    },
    destroyNotification: (state, action) => {
      return (state = null);
    }
  },
});

export const { notifications, destroyNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
