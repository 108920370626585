import React from "react";
import ButtonForModal from "./ButtonForModal";
import "./Model.css";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";

export default function FirstPlanActivatedSuccessfully() {
  const { dispatch, setIsOpenPopup } = useSelectPopupPomponent();

  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col">
      <div className="purchase-successful  w-[calc(151px+15.729vw)]    md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="w-[calc(151px+15.729vw)] p-[calc(13px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="semibold-24 teal px-10 pt-8 text-center md:px-[4vw] md:pt-4">
          Well Done!
        </div>
        <div className="normal-22 px-10 pt-3 text-center md:pb-4">
          Your Ride Mobile plan is now active. Do not forget to turn ON Data
          Roaming on your eSIM in your Settings menu
        </div>
        <div className="flex gap-5 md:flex-col md:gap-3 sm:mt-4">
          <ButtonForModal
            color="teal"
            onClick={() => dispatch(setIsOpenPopup(false))}
          >
            OK
          </ButtonForModal>
        </div>
      </div>
    </div>
  );
}
