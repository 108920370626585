import { createSlice } from "@reduxjs/toolkit";

let initialState = null;

// if (localStorage.getItem("profile"))
//   initialState = JSON.parse(localStorage.getItem("profile"));
export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    createProfile: (state, action) => {
      return (state = action.payload);
    },
    destroyProfile: (state, action) => {
      return (state = null);
    },
  },
});

export const { createProfile, destroyProfile } = profileSlice.actions;
export default profileSlice.reducer;
