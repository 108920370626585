import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authApi } from "./api/authApi";
import { vouchersApi } from "./api/vouchersApi";
import { usersApi } from "./api/usersApi";
import { plansApi } from "./api/plansApi";
import { countryListApi } from "./api/countryListApi";
import { chckoutSessionApi } from "./api/chckoutSessionApi";
import { cardsApi } from "./api/cardsApi";
import { supportApi } from "./api/supportApi";
import { podProductApi } from "./api/podProductApi";
import { productPlansApi } from "./api/productPlansApi";
import { activationApi } from "./api/activationApi";

import authSliceReducer from "./Slices/authSlice";
import selectPopupComponentSlice from "./Slices/selectPopupComponentSlice";
import profileSlice from "./Slices/profileSlice";
import modalStateSlice from "./Slices/modalStateSlice";
import signupDataSlice from "./Slices/signupDataSlice";
import backButtonSlice from "./Slices/backButtonEventAuthPages";
import toasterSlice from "./Slices/toasterSlice";
import notificationSlice from "./Slices/notificationSlice";

export const store = configureStore({
  reducer: {
    [vouchersApi.reducerPath]: vouchersApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [countryListApi.reducerPath]: countryListApi.reducer,
    [plansApi.reducerPath]: plansApi.reducer,
    [chckoutSessionApi.reducerPath]: chckoutSessionApi.reducer,
    [cardsApi.reducerPath]: cardsApi.reducer,
    [supportApi.reducerPath]: supportApi.reducer,
    [podProductApi.reducerPath]: podProductApi.reducer,
    [activationApi.reducerPath]: activationApi.reducer,
    [productPlansApi.reducerPath]: productPlansApi.reducer,

    auth: authSliceReducer,
    profile: profileSlice,
    popupComponent: selectPopupComponentSlice,
    modalState: modalStateSlice,
    toaster: toasterSlice,
    signupData: signupDataSlice,
    backButton: backButtonSlice,
    notification: notificationSlice
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(usersApi.middleware)
      .concat(countryListApi.middleware)
      .concat(plansApi.middleware)
      .concat(cardsApi.middleware)
      .concat(supportApi.middleware)
      .concat(productPlansApi.middleware)
      .concat(podProductApi.middleware)
      .concat(activationApi.middleware)
      .concat(chckoutSessionApi.middleware)
      .concat(vouchersApi.middleware);
  },
});
setupListeners(store.dispatch);
export * from "./Slices/backButtonEventAuthPages";
export * from "./Slices/signupDataSlice";
export * from "./Slices/authSlice";
export * from "./Slices/toasterSlice";
export * from "./Slices/profileSlice";
export * from "./Slices/modalStateSlice";
export * from "./Slices/selectPopupComponentSlice";
export * from "./Slices/notificationSlice";
export * from "./api/productPlansApi";
export * from "./api/authApi";
export * from "./api/usersApi";
export * from "./api/countryListApi";
export * from "./api/plansApi";
export * from "./api/cardsApi";
export * from "./api/podProductApi";
export * from "./api/activationApi";
export * from "./api/supportApi";
export * from "./api/vouchersApi";
export * from "./api/chckoutSessionApi";
