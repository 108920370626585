import React from "react";
import { useSelector } from "react-redux";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import {
  useLazyGetEsimStatusQuery,
  useLazyProductsQuery,
  useSubscribePlanMutation,
} from "../../utils/store";
import ButtonForModal from "./ButtonForModal";
import "./Model.css";
import { MixpanelTrackEvent } from "../../mixpanelEvents";

export default function ActivatePendingPlan() {
  const { dispatch, setPopupComponent, setIsOpenPopup } =
    useSelectPopupPomponent();
  const [refetchEsim, refetchEsimSatus] = useLazyGetEsimStatusQuery();
  const profile = useSelector((state) => state.profile);
  const { productPlanForModal = JSON.parse(sessionStorage.getItem("plan")) } =
    useSelector((state) => state.modalState);
  const [subscribePlan, resp] = useSubscribePlanMutation();
  const [refetchProductData] = useLazyProductsQuery();
  const activatePlan = async () => {
    const esimStatus = await refetchEsim();
    if (esimStatus?.data.esimStatus !== "installed") {
      return dispatch(setPopupComponent("SimInstallation"));
    }
    const resp = await subscribePlan({
      productId: productPlanForModal._id,
      body: {
        productId: productPlanForModal._id,
        transactionId: productPlanForModal.transactionId,
        planId: productPlanForModal.productId,
        upcoming: productPlanForModal.status === "upcoming",
        country: productPlanForModal.country,
      },
    });
    if (resp.data) {
      refetchProductData();
      dispatch(setIsOpenPopup(false));
      profile?.isFirstTimeActivator &&
        dispatch(setPopupComponent("FirstPlanActivatedSuccessfully"));
      MixpanelTrackEvent.planActivation(productPlanForModal, "Activated");
    } else {
      MixpanelTrackEvent.planActivation(productPlanForModal, "Failed");
      throw new Error(resp.error.data.message);
    }
  };
  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col">
      <div className="purchase-successful  w-[calc(151px+15.729vw)]    md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="w-[calc(151px+15.729vw)] p-[calc(13px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="semibold-22 px-10 pt-10 text-center md:px-[4vw] md:pt-4">
          You currently have an Active plan
        </div>
        <div className="normal-22 px-10 pt-3 text-center md:pb-4">
          If you activate a new plan, your current plan will be cancelled
        </div>
        <div className="flex gap-5 md:flex-col md:gap-3 sm:mt-4">
          <ButtonForModal
            color="teal"
            onClick={activatePlan}
            isSubmitting={
              refetchEsimSatus.isFetching || resp.status === "pending"
            }
          >
            Continue
          </ButtonForModal>
        </div>
      </div>
    </div>
  );
}
