import { createSlice } from "@reduxjs/toolkit";
let initialState = {};

export const modalStateSlice = createSlice({
  name: "modalState",
  initialState,
  reducers: {
    setTransactionId: (state, action) => {
      state.transactionId = action.payload;
    },
    setIsPrivacyChecked: (state, action) => {
      state.isPrivacyChecked = action.payload;
    },
    setIsTermsChecked: (state, action) => {
      state.isTermsChecked = action.payload;
    },
    setDashboardTabIndex: (state, action) => {
      state.dashboardTabIndex = action.payload;
    },
    setProductPlanForModal: (state, action) => {
      state.productPlanForModal = action.payload;
    },
    setPlanSelectedBeforeSignup: (state, action) => {
      state.planSelectedBeforeSignup = action.payload;
    },
    setIsAutoRefill: (state, action) => {
      state.isAutoRefill = action.payload;
    },
    setBackButtonEvent: (state, action) => {
      state.backButtonEvent = action.payload;
    },
    setVoucher: (state, action) => {
      state.voucher = action.payload;
    },
    setGeoScope: (state, action) => {
      state.geoScope = action.payload;
    },
    setIsFreeProduct: (state, action) => {
      state.isFreeProduct = action.payload;
    },
    setIsShowReceivedRideMiles: (state, action) => {
      state.isShowReceivedRideMiles = action.payload;
    },
  },
});
export const {
  setTransactionId,
  setIsPrivacyChecked,
  setIsTermsChecked,
  setDashboardTabIndex,
  setProductPlanForModal,
  setPlanSelectedBeforeSignup,
  setIsAutoRefill,
  setBackButtonEvent,
  setVoucher,
  setGeoScope,
  setIsFreeProduct,
  setIsShowReceivedRideMiles,
} = modalStateSlice.actions;
export default modalStateSlice.reducer;
