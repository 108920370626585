import React from "react";
import useToggleShowPassword from "../../Hooks/use-toggleShowPassword";
import { Field } from "formik";
import phoneIcon from "../../resources/icons/FormFieldIcons/Group582.svg";
import emailIcon from "../../resources/icons/FormFieldIcons/email.svg";
import passwordIcon from "../../resources/icons/FormFieldIcons/Layer_1.svg";
import hideIcon from "../../resources/icons/FormFieldIcons/Group586.svg";
import showIcon from "../../resources/icons/view.svg";
import PhoneInputField from "../../components/PhoneInputField.component";
import PhoneInput from "react-phone-input-2";

export default function FormFieldPopup({ field }) {
  const { showPassword, setShowPassword } = useToggleShowPassword();
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div
      className={`relative  text-gray-400 focus-within:text-gray-600 md:w-full sm:w-full ${
        field.name === "mobile"
          ? "phone-form-control form-input flex items-center rounded-md border border-[#c42f52] bg-inherit text-sm text-white"
          : ""
      }`}
    >
      {field.name === "mobile" ? (
        <div className="modal-tel-input-container">
          <Field name={field.name} component={PhoneInputField} isEdit={false} isPopUp={true}/>
          {/* <input ref={inputRef} type="tel" className="telephone-input" onChange={handleInputChange}  /> */}
        </div>
      ) : (
        <Field
          type={
            field.type !== "password"
              ? field.type
              : showPassword
              ? "text"
              : "password"
          }
          required={field.isRequired}
          tabIndex="0"
          name={field.name}
          placeholder={field.placeholder}
          id={field.name}
          className="rm-font-overpass form-input relative block h-[calc(22px+2.326vw)] w-full rounded-md border  border-[#c42f52] bg-inherit pl-16 text-[calc(6px+0.625vw)] text-black placeholder:text-black/[0.65]  autofill:bg-transparent focus:border-[#c42f52] focus:ring-[#c42f52]  md:h-16 sm:h-12"
        />
      )}
      <img
        src={field.icon}
        className={classNames(
          "formIcon-left   absolute top-1/2  left-7 block  -translate-y-1/2 text-sm font-medium text-gray-700 transition-all",
          field.icon === passwordIcon
            ? "max-h-[calc(7px+0.736vw)] max-w-[calc(6px+0.638vw)] sm:max-h-5 sm:max-w-[18px]"
            : field.icon === emailIcon
            ? "max-h-[calc(5px+0.508vw)] max-w-[calc(7px+0.692vw)] sm:max-h-[15px] sm:max-w-[18px]"
            : field.icon === phoneIcon
            ? "max-h-[calc(7px+0.694vw)] max-w-[calc(7px+0.694vw)] sm:max-h-[21px] sm:max-w-[18px]"
            : "max-h-[calc(6px+0.625vw)] max-w-[calc(6px+0.625vw)] sm:max-h-[18px] sm:max-w-[16px]"
        )}
        alt={field.name}
      ></img>

      {field.type === "password" && (
        <img
          src={showPassword ? showIcon : hideIcon}
          className="formIcon-right max-w-[calc(7px + 0.694vw)] absolute  right-7 top-1/2  block max-h-[calc(6px+0.625vw)] -translate-y-1/2 text-sm  font-medium transition-all"
          alt={field.name}
          onClick={() => setShowPassword(!showPassword)}
        ></img>
      )}
    </div>
  );
}
