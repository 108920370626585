import { BsFillInfoCircleFill } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { ErrorMessage, useFormikContext } from "formik";
import { useState } from "react";
import { useEffect } from "react";
import { nanoid } from "@reduxjs/toolkit";
export default function ErrorMessageComponent({
  formFieldValues,
  submissionError,
  mb = 3,
}) {
  const { errors, setErrors } = useFormikContext();
  const closeError = () => {
    setErrors({});
  };
  const [showError, setShowError] = useState();

  useEffect(() => {
    setShowError(submissionError);
  }, [submissionError]);

  useEffect(() => {
    let clearErrorTimeout = setTimeout(() => {
      if (showError || Object.keys(errors).length > 0) {
        setShowError("");
        setErrors({});
      }
    }, 5000);
    return () => {
      clearTimeout(clearErrorTimeout);
    };
  }, [errors, showError]);

  return formFieldValues?.map((field) => {
    return (
      <div className="normal-14 absolute w-full font-light" key={nanoid()}>
        {showError && Object.keys(errors).length === 0 ? (
          <div
            className={`mb-${mb} flex  w-full items-center justify-between rounded-md bg-[#c42f52] py-2 font-light text-white`}
          >
            <span className="px-[calc(7px+0.694vw)] text-center">
              {submissionError?.data?.message}
            </span>
            <div className="mr-[calc(7px+0.694vw)] flex h-[calc(8px+0.833vw)] w-[calc(8px+0.833vw)] cursor-pointer items-center">
              <RxCross2 onClick={() => setShowError("")} />
            </div>
          </div>
        ) : (
          <ErrorMessage
            key={field.name}
            name={field.name}
            render={(message) => (
              <div
                className={`mb-${mb} flex w-full items-center justify-between rounded-md bg-[#c42f52] py-2  text-white`}
              >
                <span className="px-[calc(7px+0.694vw)] text-center">
                  {message}
                </span>
                <div className="mr-[calc(7px+0.694vw)] flex h-[calc(8px+0.833vw)] w-[calc(8px+0.833vw)] items-center">
                  <RxCross2 onClick={closeError} />
                </div>
              </div>
            )}
          />
        )}
      </div>
    );
  });
}
