import { createSlice } from "@reduxjs/toolkit";

let initialState = null;

export const signupDataSlice = createSlice({
  name: "signupData",
  initialState,
  reducers: {
    setSignupData: (state, action) => {
      return (state = action.payload);
    },
  },
});

export const { setSignupData } = signupDataSlice.actions;
export default signupDataSlice.reducer;
