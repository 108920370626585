import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import rideMobileLogo from "../resources/icons/ride-mobile-logo.png";
import useAuth from "../utils/Auth/authChecker";
import DropdownMenu from "./DropdownMenu.component";
import { BsDot } from "react-icons/bs";
import personIcon from "../pages/Model Popups/images/Subtraction 2.svg";
import profileIcon from "../resources/MicrosoftTeams-image (28).png";
import { MixpanelTrackEvent } from "../mixpanelEvents";
import coinIcon from "../resources/icons/coin-black.svg";
import { useState } from "react";
import coinIconWhite from "../resources/icons/coin-white.svg";
import useSelectPopupPomponent from "../Hooks/use-select-popup-component";
import { RxCross2 } from "react-icons/rx";
import { AiFillBell, AiOutlineBell } from "react-icons/ai";
import { destroyNotification, notifications, useLazyGetUserNotificationsQuery } from "../utils/store";

const Navbar = () => {
  const navigate = useNavigate();
  const { isLogin } = useAuth();
  const profile = useSelector((state) => state.profile);
  const notification = useSelector((state) => state.notification);
  const location = useLocation();
  const [isShow, setShow] = useState(false);
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  const [ getAllNotifications ] = useLazyGetUserNotificationsQuery();

  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

  useEffect(() => {
    console.log('notification from use effect');
    handleGetNotifications();
    let timer = setTimeout(() => {
    }, 400);
    return () => {
      clearTimeout(timer);
    }
  }, [notification]);
  
  const handleGetNotifications = async () => {
    let { data, error } = await getAllNotifications();
    if(data) {
      let unreadCount = data.filter((notify) => notify.isRead != true).length;
      setUnreadNotificationCount(unreadCount);
      dispatch(notifications(data));
    }
  }

  return (
    <div className="rm-header flex flex-col">
      <div className="rm-header-container  top-0 z-10 flex h-[74px] flex-shrink-0 bg-white shadow  md:h-[97px] sm:h-[78px]">
        <div
          className={`relative flex flex-1  items-center ${
            !isLogin() && window.innerWidth < 576
              ? "justify-between"
              : "justify-between  "
          } pl-[2px] sm:pl-0`}
        >
          {/* <div className="hidden md:inline-block sm:inline-block">
            <DropdownMenu left="0" />
          </div> */}
          <img
            src={rideMobileLogo}
            className={`rm-logo  w-[103px] cursor-pointer md:ml-[calc(10px+3.662vw)]  md:max-h-[calc(27px+1.302vw)] md:w-[calc(74px+3.613vw)] ${
              isLogin()
                ? "sm:w-[calc(22px+10.436vw)]"
                : "sm:absolute sm:left-1/2 sm:w-[calc(35px+16.199vw)] sm:-translate-x-1/2"
            } `}
            alt=""
            onClick={() => {
              // navigate("/");
              window.open("https://www.ridemobile.com/", "_self");
              MixpanelTrackEvent.logoClick(location.pathname + " (Navbar)");
            }}
          />
          <div className="semibold-20 flex items-center">
            <div className="md:hidden sm:hidden">
              <a
                href="https://ridemobile.com/about-us/"
                className="rm-font-overpass hover-underline-animation "
                target={"_blank"}
                rel="noreferrer"
                onClick={() => {
                  MixpanelTrackEvent.aboutUsClick(
                    location.pathname + " (Navbar)"
                  );
                }}
              >
                <span>About Us</span>
              </a>
              <BsDot className="mx-[10px] inline" />

              <span className="rm-font-overpass hover-underline-animation  cursor-pointer">
                <a
                  href="https://www.ridemobile.com/contact"
                  target={"_blank"}
                  rel="noreferrer"
                  onClick={() => {
                    MixpanelTrackEvent.contactUsClick(
                      location.pathname + " (Navbar)"
                    );
                  }}
                >
                  Contact Us
                </a>
              </span>
              <BsDot className="mx-[10px] inline " />
              <span className="rm-font-overpass hover-underline-animation mr-[62px] underline-offset-8">
                <span>
                  <a
                    href="https://ridemobile.zohodesk.com/portal/en/kb/ridemobile/faq"
                    target={"_blank"}
                    rel="noreferrer"
                    onClick={() => {
                      MixpanelTrackEvent.faqClick(
                        location.pathname + " (Navbar)"
                      );
                    }}
                  >
                    FAQ
                  </a>
                </span>
              </span>
            </div>

            <div className="flex items-center pr-[27px] md:pr-[calc(10px+3.662vw)] sm:pr-1 ">
              {!isLogin() ? (
                <button
                  className="rm-header-login-btn rm-font-overpass flex items-center justify-center text-[15px] md:text-[18px] sm:hidden"
                  onClick={() => {
                    navigate("/auth");
                    MixpanelTrackEvent.loginButtonClick(
                      location.pathname + " (Navbar)"
                    );
                  }}
                >
                  <img
                    src={personIcon}
                    alt=""
                    className="mr-3 hidden h-[20px] sm:hidden"
                  ></img>
                  Log In
                </button>
              ) : (
                <>
                  <div className=" flex items-center gap-2 sm:gap-1">
                    <div className="relative" onClick={() => dispatch(setPopupComponent("Notifications"))}>
                      {
                        unreadNotificationCount ? 
                          <AiFillBell fill='#d05929' className="sm:h-4 sm:w-3 h-5 w-5 cursor-pointer" />   
                          :
                          <AiOutlineBell className="sm:h-4 sm:w-3 h-5 w-5 cursor-pointer" />
                      }
                    </div>
                    <img
                      src={profileIcon}
                      alt=""
                      className="h-[30px] md:mr-0 md:h-[calc(7px+2.018vw)] sm:mr-0 sm:-mt-1 sm:h-[20px]"
                    />
                    <span className="mr-[20px] whitespace-nowrap font-bold md:hidden md:text-[20px] sm:hidden">
                      {profile?.displayName}
                    </span>
                    <span className="mr-[20px] hidden whitespace-nowrap font-bold  md:mr-[calc(9px+2.563vw)] md:inline-block md:text-[20px] sm:mr-2 sm:inline-block">
                      {profile?.firstName}
                    </span>
                  </div>
                  <div className="flex items-center gap-2 sm:gap-1">
                    <img
                      src={coinIcon}
                      alt=""
                      className="-mt-1 h-[25px] md:mr-0 md:h-[calc(5px+2.018vw)] sm:mr-0 sm:h-[17px]"
                    />
                    <div className="whitespace-nowrap p-0 font-bold md:text-[20px]">
                      Ride Miles
                    </div>
                    <div
                      className=" relative mr-[20px] w-[calc(30px+3.160vw)] rounded-md border border-[#E6E6E6] bg-[#F9F9F9] pt-1 md:mr-[calc(9px+2.563vw)] sm:mr-2"
                      // onMouseEnter={() => setShow(true)}
                      // onMouseLeave={() => setShow(false)}
                      onClick={() => {
                        dispatch(setPopupComponent("ReferAndRide"));
                      }}
                    >
                      <span className="ml-2">
                        {profile?.redeemableRideMiles}
                      </span>
                      {/* <div
                        className={`absolute right-0 ${
                          isShow ? "" : "hidden"
                        } sm:-right-2`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          // width="360"
                          className="h-[calc(73px+1.910vw)] w-[360px] md:w-[calc(260px+6.20vw)] sm:w-[285px]"
                          // height="100"
                        >
                          <rect
                            x="10"
                            y="10"
                            rx="10"
                            ry="10"
                            // width="350"
                            className="h-[calc(55px+1.215vw)] w-[350px] md:w-[calc(250px+6.20vw)] sm:w-[267px]"
                            height="60"
                            fill="#d05929"
                            stroke="#d05929"
                          />
                          <polygon
                            className="ridemiles-polygon"
                            points="240,10 260,10 250,2"
                            fill="#d05929"
                          />
                          <image
                            x="18"
                            y="25"
                            // width="25"
                            // height="25"
                            xlinkHref={coinIconWhite}
                            className=" h-[calc(23px+0.590vw)] w-[calc(23px+0.590vw)]"
                          />
                          <text
                            x="40"
                            y="20"
                            fontFamily="Arial"
                            fontSize="15"
                            fill="#FFFFFF"
                            className="x text-[18px] md:text-[15px] sm:text-[12px]"
                          >
                            <tspan x="55" dy="1.2em">
                              Enjoy 5 Ride Miles using a referral
                            </tspan>
                            <tspan x="55" dy="1.2em">
                              code when you buy your first plan.
                            </tspan>
                          </text>
                        </svg>
                      </div> */}
                    </div>
                  </div>
                  <div className="">
                    <DropdownMenu />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
