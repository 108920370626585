import { Tab } from "@headlessui/react";
import DashboardCountryTab from "./DashboardCountryTab";
import Navbar from "../components/Navbar.component";
import Footer from "../components/Footer.component";
import DashboardZoneTab from "./DashboardZoneTab";
import SubscriptionPlan from "../components/SubscriptionPlan.component";
import { useSelector } from "react-redux";
import useSelectPopupPomponent from "../Hooks/use-select-popup-component";
import { useEffect, useState } from "react";
import {
  createProfile,
  setIsShowReceivedRideMiles,
  useLazyProfileQuery,
} from "../utils/store";
import { Mixpanel } from "../Mixpanel";
import { MixpanelTrackEvent } from "../mixpanelEvents";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Dashboard() {
  const auth = useSelector((state) => state.auth);
  const { dashboardTabIndex, isShowReceivedRideMiles } = useSelector(
    (state) => state.modalState
  );
  const [getProfileData, result] = useLazyProfileQuery();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const { dispatch, setPopupComponent } = useSelectPopupPomponent();

  const { isOpenPopup } = useSelector((state) => state.popupComponent);

  useEffect(() => {
    if (!isOpenPopup && isShowReceivedRideMiles) {
      dispatch(setIsShowReceivedRideMiles(false));
      dispatch(setPopupComponent("ReceivedRideMiles"));
    }
  }, [isOpenPopup]);

  let categories = [
    {
      title: "Country",
      component: <DashboardCountryTab />,
      click: () => {
        MixpanelTrackEvent.countryTabClick();
      },
    },
    {
      title: "Zone",
      component: <DashboardZoneTab />,
      isDisabled: true,
      click: () => {
        MixpanelTrackEvent.clickedOnZoneEvent();
        dispatch(setPopupComponent("ZoneComingSoon"));
      },
    },
  ];

  const fetchProfile = async () => {
    const { data } = await getProfileData(auth.token);
    if (data) dispatch(createProfile(data));
  };

  useEffect(() => {
    if (auth) fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    Mixpanel.register({
      "User Id":
        result.data && localStorage.getItem("jwt") ? result.data._id : "null",
      Email:
        result.data && localStorage.getItem("jwt") ? result.data.email : "null",
    });
  }, [result]);

  return (
    <div className="flex h-full flex-col">
      <Navbar />
      <div className="mt-[74px] flex-1 md:mt-[97px] sm:mt-[78px]">
        <div
          className={`${
            auth && window.innerWidth < 576
              ? "rm-dashboard-img-container-loggedin"
              : "rm-dashboard-img-container"
          } mb-[calc(33px+3.472vw)] `}
        >
          <div className="rm-dashboard-text hidden md:inline-block sm:inline-block">
            <p
              className={` text-main bold-55 w-[90vw] text-center md:inline-block md:text-[calc(13px+2.474vw)] sm:mx-auto sm:w-[70vw] sm:text-[calc(9px+4.361vw)]  sm:leading-tight`}
            >
              {/* Stay connected in more places */}
              Stay connected in more places
            </p>

            {/* <p className="text-main normal-40 font-light md:hidden sm:hidden">
              A new wireless data service built for people who
              <br className="d-none-tab" />
              take the road less traveled.
            </p> */}
          </div>
          <div className="rm-dashboard-text  md:hidden sm:hidden">
            <p className="text-main bold-55 w-[90vw] text-center md:inline-block md:text-[calc(13px+2.474vw)] sm:mx-auto sm:hidden sm:w-[70vw] sm:text-[calc(9px+4.361vw)]  sm:leading-tight">
              Stay connected in more places
            </p>
            <p className="text-main medium-45  md:hidden sm:hidden">
              Wherever you lose coverage, Ride will connect you to other available networks.
              {/* <br className="d-none-tab" />
              the road less traveled */}
            </p>
          </div>
        </div>
        {auth && <SubscriptionPlan />}
        <div className="shop-for-plan-parent" id="explore-plans">
          <p className={` shop-plan-text semibold-45 rm-font-overpass `}>
            Shop for a Ride Mobile Plan for your iPhone
          </p>
          <div>
            <p className="country-zone-select-text normal-22 sm:px-12">
              Make sure it is{" "}
              <span className="teal cursor-pointer underline">
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://ridemobile.zohodesk.com/portal/en/kb/articles/what-esim-enabled-devices-does-ride-mobile-support"
                  className="underline"
                  onClick={() =>
                    MixpanelTrackEvent.eSIMEnabledClick("DashboardScreen")
                  }
                >
                  eSIM enabled
                </a>
              </span>{" "}
              and{" "}
              <span className="teal cursor-pointer underline">
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://ridemobile.zohodesk.com/portal/en/kb/articles/how-do-i-know-if-my-iphone-is-unlocked"
                  className="underline"
                  onClick={() =>
                    MixpanelTrackEvent.carrierUnlockedClick("DashboardScreen")
                  }
                >
                  Carrier Unlocked
                </a>
              </span>
            </p>
            <div className="normal-22 mb-4 text-center">
              Android plans are coming soon
            </div>
          </div>
          {/* <p className="destination-text rm-font-overpass medium-38">
            Choose Your Destination
          </p>
          <p className="country-zone-select-text normal-22 sm:px-12 ">
            Select the country or zone where you want to use your Ride Mobile
            plan
          </p> */}
        </div>
        <div className="dashboard-tab-section flex-1">
          {/* <div className="pt-8 sm:pt-4">
            <Tab.Group
              selectedIndex={dashboardTabIndex ?? selectedIndex}
              onChange={setSelectedIndex}
            >
              <div className="country-zone-parent">
                <Tab.List className="flex">
                  {categories.map((category) => (
                    <Tab
                      onClick={category.click}
                      key={category.title}
                      className={({ selected }) =>
                        classNames(
                          "medium-32 w-full rounded-lg py-[calc(3px+0.417vw)]",
                          "focus:outline-none",
                          selected
                            ? "bg-teal text-white shadow"
                            : "text-black hover:bg-white/[0.12] hover:text-[#52b1ae]"
                        )
                      }
                    >
                      {category.title}
                    </Tab>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels className="mt-2">
                {categories.map((content, idx) => (
                  <Tab.Panel
                    key={idx}
                    className={classNames(
                      "rounded-xl ",
                      "focus:outline-none",
                      "country-zone-section"
                    )}
                  >
                    {content.component}
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          </div> */}
          <div className="country-zone-section">
            <DashboardCountryTab />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
