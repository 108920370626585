import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { destroyNotification, notifications, useLazyGetUserNotificationsQuery, useUpdateNotificationMutation } from "../../utils/store";
import { useEffect, useState } from "react";

export default function Notifications() {

    const allNotifications = useSelector((state) => state.notification);
    const [ getAllNotifications ] = useLazyGetUserNotificationsQuery();
    const dispatch = useDispatch();
    const [ updateNotification ] = useUpdateNotificationMutation();

    const handleMarkAsRead = async (notification) => {
        if(notification) {
          let { data } = await updateNotification({notificationId: notification._id, body: {
                isRead: !notification?.isRead
          }})
          if(data)
            handleGetNotifications(data);
      }
    }
    
    const handleGetNotifications = async (updatedData) => {
      let tempAllNotifications = [...allNotifications];
        tempAllNotifications.forEach((item, index) => {
          if(item._id == updatedData._id) {
            tempAllNotifications.splice(index, 1, updatedData);
          }
        });
        dispatch(notifications(tempAllNotifications));
    }

    const TimeAgo = (timestamp) => {
      const now = moment();
      const time = moment(timestamp);
      const diffMinutes = now.diff(time, 'minutes');
      const diffHours = now.diff(time, 'hours');
      const diffDays = now.diff(time, 'days');
      let timeAgo = '';
      if(diffMinutes <= 0) {
        timeAgo = `just now`;
      } else if (diffMinutes < 60 && diffMinutes > 1) {
          timeAgo = `${diffMinutes}m ago`;
      } else if (diffHours < 24) {
          timeAgo = `${diffHours}h ago`;
      } else {
          timeAgo = `${diffDays}d ago`;
      }
      return <span>{timeAgo}</span>;
    };

    const renderHistory = allNotifications.slice(0).reverse().map((notify, index) => {
      return (
        <div
          className="medium-18  items-center justify-between gap-1 py-2"
          key={index}
        >
          <div className="flex">
            <div className="orange normal-18 mr-2">{notify?.title}</div>
            <span>&bull; {TimeAgo(notify?.createdAt)}</span>
          </div>
          <div className="normal-18">{notify.description}</div>
          {
            !notify.isRead &&
            <div className="px-1 py-1 bg-orange rounded text-white w-4/12 text-center ml-auto mt-2 cursor-pointer" onClick={() => handleMarkAsRead(notify)}>Mark as read</div>
          }
        </div>
      );
    });

    return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col">
      <div className="save-payment-method  w-[calc(151px+14.729vw)]    md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="w-[calc(151px+16.729vw)] space-y-4 p-[calc(10px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="medium-30 orange text-center">Notifications</div>
        <div
          className={`h-96 divide-y-2 overflow-y-scroll  md:h-72 sm:h-72 ${
            allNotifications?.length && "pr-3"
          }`}
        >
          {allNotifications?.length ? (
            renderHistory
          ) : (
            <div className="normal-18 mt-20 text-center text-gray-500">
              No notifications found
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
