import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  backButtonEvent: "",
  renderAuthPage: "",
};

export const backButtonSlice = createSlice({
  name: "backButton",
  initialState,
  reducers: {
    setBackButtonEventAuthPage: (state, action) => {
      state.backButtonEvent = action.payload;
    },
    setRenderAuthPage: (state, action) => {
      state.renderAuthPage = action.payload;
    },
  },
});

export const { setBackButtonEventAuthPage, setRenderAuthPage } =
  backButtonSlice.actions;
export default backButtonSlice.reducer;
