import { Tab } from "@headlessui/react";
import Navbar from "../../components/Navbar.component";
import Footer from "../../components/Footer.component";
import { createProfile, useLazyProfileQuery, useProductsQuery } from "../../utils/store";
import Active from "./Active";
import Pending from "./Pending";
import Expired from "./Expired";
import ButtonForModal from "../Model Popups/ButtonForModal";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function ManageMyPlans() {
  const { data } = useProductsQuery();
  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile);
  const auth = useSelector((state) => state.auth);
  const [getProfileData, result] = useLazyProfileQuery();
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();

  useEffect(() => {
    if(!profile) {
      getProfileData(auth.token);
    }
  }, [])

  useEffect(() => {
    if (result.data) {
      dispatch(createProfile(result.data));
    }
  }, [result]);
  
  const pendingPlans = data?.filter((plan) => {
    return plan.status === "upcoming";
  });
  const expiredtPlan = data?.filter((plan) => {
    return plan.status === "expired";
  });
  let categories = [
    {
      title: "ACTIVE",
      component: <Active />,
    },
    {
      title: "PENDING",
      component: <Pending plans={pendingPlans} />,
    },
    {
      title: "EXPIRED",
      component: <Expired plans={expiredtPlan} />,
    },
  ];

  const shopForPlanButtonClick = () => {
    navigate("/");
    setTimeout(() => {
      var element = document.getElementById("explore-plans");
      var headerOffset = 108;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }, 200);
  };

  const renderShopForPlanButton = (
    <div className="mb-8 flex justify-center md:mt-7 sm:mt-6">
      <div className="w-80 md:w-60 sm:w-52">
        <ButtonForModal color="orange" onClick={shopForPlanButtonClick}>
          Shop for a Plan
        </ButtonForModal>
      </div>
    </div>
  );

  return (
    <div className="flex h-full w-full flex-col">
      <Navbar />
      <div className="mt-[74px] flex-1 md:mt-[97px] sm:mt-[78px]">
        <div
          className={`${
            window.innerWidth < 576
              ? "manage-plans"
              : "rm-dashboard-img-container"
          } mb-[calc(33px+3.472vw)]`}
        >
          <div className="rm-dashboard-text hidden md:inline-block sm:inline-block">
            <p className="text-main bold-55 w-[90vw] text-center md:inline-block md:text-[calc(13px+2.474vw)] sm:mx-auto sm:w-[70vw] sm:text-[calc(9px+4.361vw)]  sm:leading-tight">
              Stay connected in more places
            </p>

            <p className="text-main normal-40 font-light md:hidden sm:hidden">
              A new wireless data service built for people who
              <br className="d-none-tab" />
              take the road less traveled.
            </p>
          </div>
          <div className="rm-dashboard-text  md:hidden sm:hidden">
            <p className="text-main bold-55 w-[90vw] text-center md:inline-block md:text-[calc(13px+2.474vw)] sm:mx-auto sm:hidden sm:w-[70vw] sm:text-[calc(9px+4.361vw)]  sm:leading-tight">
              Get out there. Stay connected.
            </p>
            <p className="text-main medium-45  md:hidden sm:hidden">
              A new wireless data service built for people who want to take
              <br className="d-none-tab" />
              the road less traveled
            </p>
          </div>
        </div>
        <div
          className="orange mt-10 text-center text-[calc(15px+1.563vw)] font-bold md:mb-4"
          id="manage-plans-scroll"
        >
          Manage Plans
        </div>
        <div className="dashboard-tab-section">
          <div className="pt-8 md:px-4 sm:pt-4">
            <Tab.Group>
              <div className="mb-[calc(19px+1.979vw)] flex justify-center">
                <Tab.List className="flex w-[calc(236px+21.188vw)] items-center justify-center space-x-1 rounded-xl bg-white p-1 shadow md:w-[calc(213px+41.602vw)]">
                  {categories.map((category) => (
                    <Tab
                      key={category.title}
                      className={({ selected }) =>
                        classNames(
                          "normal-22 w-full rounded-lg py-2 font-medium",
                          "focus:outline-none",
                          selected
                            ? "bg-[#D05929] text-white"
                            : "text-black hover:bg-white/[0.12] hover:text-[#D05929]"
                        )
                      }
                    >
                      {category.title}
                    </Tab>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels className="mt-2 sm:w-full">
                {categories.map((content, idx) => (
                  <Tab.Panel
                    key={idx}
                    className={classNames(
                      "rounded-xl ",
                      "focus:outline-none",
                      "country-zone-section"
                    )}
                  >
                    {content.component}
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
      {renderShopForPlanButton}
      <Footer />
    </div>
  );
}
