import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { endpoints, BASE_URL } from "../../config/endpoint.config";

const plansApi = createApi({
  reducerPath: "plansApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints(builder) {
    return {
      plansByCountryZone: builder.query({
        query: (country) => {
          return {
            url: endpoints.plans.getProductsByCountry,
            params: { isCountry: true, countrySelected: country },
            method: "GET",
          };
        },
      }),
    };
  },
});

export const { usePlansByCountryZoneQuery, useLazyPlansByCountryZoneQuery } =
  plansApi;
export { plansApi };
