import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import useToaster from "../Hooks/useToaster";
import { BiError } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";

function Toaster() {
  const { type, toastMsg } = useSelector((state) => state.toaster);
  const { setToast } = useToaster();
  useEffect(() => {
    const Id = setTimeout(() => {
      if (type === "success") {
        toast.dismiss();
        toast.success(toastMsg, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          style: {
            background: "#22c55e",
            paddingRight: "30px",
          },
          bodyClassName: "bg-green-500",
          closeButton: (
            <RxCross2 className="absolute top-1/2 right-3 h-5 w-5 -translate-y-1/2 transform" />
          ),
        });
      }
      if (type === "error") {
        toast.dismiss();
        toast.error(toastMsg, {
          icon: <BiError />,
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          style: {
            background: "#22c55e",
            paddingRight: "30px",
          },
          bodyClassName: "bg-[#22c55e]",
          closeButton: (
            <RxCross2 className="absolute top-1/2 right-3 h-5 w-5 -translate-y-1/2 transform" />
          ),
        });
      }
      setToast({ msg: "", type: "" });
    }, 100);

    return () => {
      clearTimeout(Id);
    };
  }, [type, toastMsg]);

  return (
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      pauseOnHover
      theme="colored"
      className="custom-toast-container"
      // limit={1}
    />
  );
}

export default React.memo(Toaster);
