import { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import SignIn from "../pages/Signin.page";
import SignUp from "../pages/Signup.page";
import companyLogo from "../resources/images/Image 2/Image 2@2x.png";
import ForgotPassword from "../pages/Password/ForgotPassword";
import ResetPassword from "../pages/Password/ResetPassword";
import OtpPage from "../pages/Password/OtpPage";
import { useNavigate } from "react-router-dom";
import OtpVerification from "../pages/Password/OtpVerification";
import useAuth from "../utils/Auth/authChecker";
import Footer from "./Footer.component";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setBackButtonEventAuthPage, setRenderAuthPage } from "../utils/store";
import { MixpanelTrackEvent } from "../mixpanelEvents";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function TabComponent() {
  const navigate = useNavigate();
  const { isLogin } = useAuth();
  // if logged in and accidentally try to access login/signup page redirect to dashboard page
  useEffect(() => {
    isLogin() && navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // index-0 => signin tab,  index-1 => signup tab
  const [selectedIndex, setSelectedIndex] = useState(0);
  // select component to render in signin tab
  const [renderdComponent, setRenderedComponent] = useState("signin");
  //select component to render in signup tab
  const [renderdComponentSignup, setRenderedComponentSignup] =
    useState("signup");
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");

  const [signupDetails, setSignupDetails] = useState("");

  const dispatch = useDispatch();
  const { backButtonEvent, renderAuthPage } = useSelector(
    (state) => state.backButton
  );

  useEffect(() => {
    if (renderAuthPage === "signin") {
      setSelectedIndex(0);
      setRenderedComponent("signin");
      dispatch(setBackButtonEventAuthPage(""));
      dispatch(setRenderAuthPage(""));
    }
    if (renderAuthPage === "signup") {
      setSelectedIndex(1);
      setRenderedComponentSignup("signup");
      dispatch(setBackButtonEventAuthPage(""));
      dispatch(setRenderAuthPage(""));
    }
  }, [renderAuthPage]);

  const handleBackButtonClick = () => {
    if (backButtonEvent) {
      dispatch(setRenderAuthPage(backButtonEvent));
    } else navigate("/");
  };

  useEffect(() => {
    if (selectedIndex == 0) setRenderedComponentSignup("signup");
    if (selectedIndex == 1) setRenderedComponent("signin");
  }, [selectedIndex]);

  let categories = [
    {
      title: "Log In",
      component:
        renderdComponent === "signin" ? (
          <SignIn
            setRenderedComponent={setRenderedComponent}
            setEmail={setEmail}
            setSelectedIndex={setSelectedIndex}
            setRenderedComponentSignup={setRenderedComponentSignup}
          />
        ) : renderdComponent === "forgot-password" ? (
          <ForgotPassword
            setRenderedComponent={setRenderedComponent}
            setEmail={setEmail}
          />
        ) : renderdComponent === "otp-verification" ? (
          <OtpPage
            setRenderedComponent={setRenderedComponent}
            otp={otp}
            setOtp={setOtp}
            email={email}
          />
        ) : (
          <ResetPassword
            setRenderedComponent={setRenderedComponent}
            otp={otp}
          />
        ),
    },
    {
      title: "Signup",
      component:
        renderdComponentSignup === "signup" ? (
          <SignUp
            setRenderedComponentSignup={setRenderedComponentSignup}
            setEmail={setEmail}
            signupDetails={signupDetails}
            setSignupDetails={setSignupDetails}
            setOtp={setOtp}
          />
        ) : (
          <OtpVerification
            setRenderedComponent={setRenderedComponent}
            setOtp={setOtp}
            otp={otp}
            email={email}
            signupDetails={signupDetails}
            setSignupDetails={setSignupDetails}
          />
        ),
    },
  ];
  return (
    <div
      className={`h-screen w-full ${
        window.innerWidth > 1024
          ? selectedIndex === 0
            ? "homepage-login"
            : "hompage-signup"
          : ""
      }`}
    >
      <div className=" md:flex md:w-screen md:justify-center">
        <div className="float-left flex flex-col">
          <img
            src={companyLogo}
            alt="Ride Mobile Logo"
            onClick={() => {
              handleBackButtonClick();
              MixpanelTrackEvent.logoClick("Auth Page");
            }}
            className=" mt-[calc(30px+0.781vw)] ml-[127px] w-44 cursor-pointer md:ml-0 md:w-36 sm:ml-[40%] sm:mt-12 sm:w-[calc(35px+16.199vw)] "
          ></img>
          <div className={`${selectedIndex == 0 && "hidden"}`}>
            <div
              className="ml-[96px] mt-[calc(27px+0.694vw)] w-[calc(366px+9.531vw)] rounded-xl bg-[#C42F52] px-7  py-[calc(27px+2.4vw)] text-white md:hidden sm:hidden"
              style={{ lineHeight: `calc(31px + 0.816vw)` }}
            >
              <div className="text-[calc(10px+1.4vw)]">
                You’re just a few steps away from adding Ride Mobile and staying
                connected in more places.
              </div>
              <div className="mt-[calc(13px+1.2vw)] text-[calc(10px+1.4vw)]">
                1- Choose a Plan <br />
                2- Install your eSIM <br />
                3- Adjust your Settings <br />
                4- Activate your Plan
              </div>
              <div
                className="mt-[calc(13px+1.2vw)] text-[calc(10px+1.5vw)] font-bold"
                style={{ lineHeight: `calc(33px + 0.868vw)` }}
              >
                Don’t forget to use the WELCOME20 code to get 20% off on your
                first plan
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={`${window.innerWidth > 1025 ? "tab-bg" : ""} `}></div> */}
      {window.innerWidth < 1025 && window.innerWidth > 576 ? (
        <div className="mt-12 flex h-[calc(100vh-100px)] flex-col">
          <div className="flex w-screen justify-center">
            <div className=" flex  rounded-2xl  shadow-xl">
              <div className="create-account relative  w-[calc(171px+16.729vw)] rounded-l-2xl">
                {
                  <button
                    className="normal-18 absolute top-10 left-10 flex items-center gap-3 whitespace-nowrap rounded-lg  border border-gray-800 bg-white px-4 py-2 focus-visible:outline-none sm:h-8"
                    onClick={handleBackButtonClick}
                  >
                    <MdOutlineArrowBackIosNew className="pink" />
                    Go Back
                  </button>
                }
              </div>
              <div className="tab-size w-[calc(189px+12.864vw)]  p-[calc(23px+1.389vw)] md:w-[calc(326px+12.864vw)]">
                <Tab.Group
                  selectedIndex={selectedIndex}
                  onChange={setSelectedIndex}
                >
                  <Tab.List className="tab-mb mb-10  flex  items-center  justify-center space-x-1 rounded-xl bg-white p-1 shadow sm:mx-10">
                    {categories.map((category) => (
                      <Tab
                        key={category.title}
                        className={({ selected }) =>
                          classNames(
                            "tab-titles w-full rounded-lg py-[9px]  text-xl  font-medium leading-5",
                            "focus:outline-none",
                            selected
                              ? "bg-pink text-white shadow"
                              : "text-black hover:bg-white/[0.12] hover:text-[#C42F52]"
                          )
                        }
                      >
                        {category.title}
                      </Tab>
                    ))}
                  </Tab.List>
                  <Tab.Panels className="mt-2">
                    {categories.map((content, idx) => (
                      <Tab.Panel
                        key={idx}
                        className={classNames(
                          "rounded-xl sm:flex sm:flex-col",
                          "focus:outline-none"
                        )}
                      >
                        <div className="sm:flex-1 sm:px-10">
                          {content.component}
                        </div>
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
          </div>
          <div className="flex-1"></div>
          <div className="mt-12">{window.innerWidth < 1025 && <Footer />}</div>
        </div>
      ) : (
        <div className="float-right flex h-screen flex-col items-center justify-center sm:mt-10 sm:h-full sm:justify-start">
          <div
            className={`${
              window.innerWidth > 576 ? "tab-bg" : ""
            } relative h-screen sm:w-screen`}
          >
            {/* {
              <button
                className="normal-12 absolute top-6 left-6 z-50 flex items-center gap-3 whitespace-nowrap  rounded-lg bg-white px-4 py-2 focus-visible:outline-none sm:-top-20 sm:h-8 sm:border sm:border-gray-800"
                onClick={() => navigate("/")}
              >
                <MdOutlineArrowBackIosNew className="pink" />
                Go Back
              </button>
            } */}
          </div>
          <div
            className={`tab-size absolute mt-24  ${
              selectedIndex === 1
                ? "h-[calc(100vh-6rem)] md:h-[calc(100vh-4rem)]"
                : ""
            }  w-[calc(120px+12.864vw)] md:mt-16 sm:mt-16 sm:w-screen`}
          >
            {
              <button
                className="normal-12 absolute -top-16 left-0 flex  items-center gap-3 whitespace-nowrap rounded-lg bg-white px-4 py-2 focus-visible:outline-none sm:-top-14 sm:left-10 sm:h-8 sm:shadow-[0px_1px_10px_#2121216B]"
                onClick={handleBackButtonClick}
              >
                <MdOutlineArrowBackIosNew className="pink" />
                Go Back
              </button>
            }
            <Tab.Group
              selectedIndex={selectedIndex}
              onChange={setSelectedIndex}
            >
              <Tab.List className="tab-mb mb-10  flex  items-center  justify-center space-x-1 rounded-xl bg-white p-1 shadow sm:mx-10">
                {categories.map((category) => (
                  <Tab
                    key={category.title}
                    className={({ selected }) =>
                      classNames(
                        "tab-titles w-full rounded-lg py-[9px]  text-xl  font-medium leading-5",
                        "focus:outline-none",
                        selected
                          ? "bg-pink text-white shadow"
                          : "text-black hover:bg-white/[0.12] hover:text-[#C42F52]"
                      )
                    }
                  >
                    {category.title}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels className="mt-2">
                {categories.map((content, idx) => (
                  <Tab.Panel
                    key={idx}
                    className={classNames(
                      "rounded-xl sm:flex sm:flex-col",
                      "focus:outline-none"
                    )}
                  >
                    <div className="sm:flex-1 sm:px-10">
                      {content.component}
                    </div>
                    <div className="sm:mt-24">
                      {window.innerWidth < 576 && <Footer />}
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      )}
    </div>
  );
}
