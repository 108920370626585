import React from "react";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import ButtonForModal from "./ButtonForModal";
import checkmark from "./images/Path 97/Path 97.png";
export default function SettingInstructions() {
  const { dispatch, setIsOpenPopup } = useSelectPopupPomponent();

  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col">
      <div className="save-payment-method  w-[calc(151px+15.729vw)]    md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="w-[calc(151px+15.729vw)] p-[calc(13px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="medium-26 teal mb-4">SETTINGS INSTRUCTIONS</div>

        {/* <div className="medium-26 teal">In-Country</div>
        <div className="normal-18 teal mb-4">
          Extended data coverage within your country
        </div> */}
        <ol className="normal-18 list-inside list-decimal space-y-3">
          <li className="space-y-1">
            Go to Settings {`>`} Cellular {`>`} Cellular Data
            <div className="flex gap-1">
              <div>- </div>{" "}
              <div>
                Confirm your Primary Service Provider is selected (check mark{" "}
                {
                  <img
                    src={checkmark}
                    alt="√"
                    className="inline-block h-3 md:h-2 sm:h-2"
                  ></img>
                }
                )
              </div>
            </div>
            <div className="flex gap-1">
              <div>- </div> On the same screen, ensure "Allow Cellular Data
              Switching" is ON
            </div>
            <div className="flex gap-1">
              <div>- </div> Select "Cellular" on the top left corner to return
              to the previous menu
            </div>
          </li>
          <li>
            Select your Ride Mobile eSIM and turn ON "Data Roaming" (If you
            haven't labeled your eSIM to 'Ride' it might appear with a different
            name, e.g. Travel or Business)
          </li>
          <li>
            Return to the Ride Mobile App to activate your plan on the Manage
            Plans screen
          </li>
        </ol>
        <div className="flex gap-5 md:flex-col md:gap-3 sm:mt-4">
          <ButtonForModal
            color="teal"
            onClick={() => {
              dispatch(setIsOpenPopup(false));
            }}
          >
            Continue
          </ButtonForModal>
        </div>
      </div>
    </div>
  );
}
