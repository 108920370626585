import React from "react";
import { BiLoaderAlt } from "react-icons/bi";

export default function ButtonOutlineForModal({
  color = "pink",
  children,
  isSubmitting = false,
  ...rest
}) {
  return (
    <button
      {...rest}
      className={`form-button mt-10 flex  w-full  items-center justify-center rounded-lg border  border-teal-500 py-5 px-4 text-xl font-medium  text-teal-500 sm:h-10 `}
    >
      {isSubmitting ? (
        <BiLoaderAlt className="animate-spin text-xl"></BiLoaderAlt>
      ) : (
        children
      )}
    </button>
  );
}
