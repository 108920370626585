import { Route, Routes } from "react-router-dom";
import "./App.css";
import "./FontSize.css";
import TabComponent from "./components/Tabs.component";
import Dashboard from "./pages/Dashboard.page";
import NotFound from "./pages/NotFound.page";
import ManageMyPlans from "./pages/ManageMyPlans/ManageMyPlans.page";
import PaymentSusccessfullPage from "./pages/PaymentSusccessfullPage";
import Profile from "./pages/Profile.page";
import PrivateRoute from "./routes/PrivateRoute";
import useSelectPopupPomponent from "./Hooks/use-select-popup-component";
import UnivarsalModal from "./components/UnivarsalModal.component";
import "react-toastify/dist/ReactToastify.css";
import "intl-tel-input/build/js/utils";
import Toaster from "./components/Toaster";

function App() {
  const { renderPopupComponent } = useSelectPopupPomponent();

  return (
    <div className="flex h-screen flex-col">
      <main className="flex-1">
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/auth" element={<TabComponent />} />
          <Route path="/" element={<Dashboard />} />
          <Route element={<PrivateRoute />}>
            <Route path="/profile" element={<Profile />} />
            <Route path="/success/:id" element={<PaymentSusccessfullPage />} />
            <Route path="/manage-my-plans" element={<ManageMyPlans />} />
          </Route>
        </Routes>
        {renderPopupComponent && (
          <UnivarsalModal>{renderPopupComponent}</UnivarsalModal>
        )}
        {!renderPopupComponent && <Toaster />}
      </main>
    </div>
  );
}
export default App;
