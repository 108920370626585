import React from "react";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import ButtonForModal from "./ButtonForModal";
import "./Model.css";
export default function DidntReceiveEmail({ setIsOtpPageOpen }) {
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col">
      <div className="didnt-recive-email w-[calc(151px+15.729vw)]  md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="normal-18 w-[calc(151px+15.729vw)] space-y-3 p-[calc(13px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full sm:p-7">
        <div className="medium-26 pink sm:text-center">
          Didn't receive an email?
        </div>
        <div>
          <div>- Please check the email address you entered</div>
          <div>- Check your Spam mail folder</div>
        </div>
        <div>
          If you still haven't received it,
          <br />
          please email us at
        </div>

        <a href="mailto:support@ridemobile.com" className=" pink">
          support@ridemobile.com
        </a>
        <div>
          <ButtonForModal
            onClick={() => {
              setIsOtpPageOpen(false);
              // dispatch(setPopupComponent("CreateAccount"));
            }}
          >
            Return to Account Creation
          </ButtonForModal>
        </div>
      </div>
    </div>
  );
}
