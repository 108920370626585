import React, { useEffect, useState } from "react";
import { useCountryListQuery } from "../utils/store";
import searchIcon from "../resources/icons/Group 609.svg";
import DataPlanPage from "./DataPlan.page";
import { IoIosArrowDown } from "react-icons/io";
import rightArrow from "../resources/icons/arrow-down-sign-to-navigate.svg";
import { MixpanelTrackEvent } from "../mixpanelEvents";
import { useMemo } from "react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function DashboardCountryTab() {
  // const { data, isFetching, error } = useCountryListQuery();
  const data = useMemo(
    () => [
      {
        name: "United States",
        dial_code: "+1",
        code: "US",
        flag: "🇺🇸",
        isPopular: true,
      },
    ],
    []
  );
  const [list, setList] = useState(data?.slice(0, 20));
  const [selectedCountry, setSelectedCountry] = useState();
  const [listBeforeSearch, setListBeforeSearch] = useState({
    searchStarted: false,
    list: "",
  });

  useEffect(() => {
    if (list) setSelectedCountry(list[0]);
  }, [list]);

  //to set no of countries to render corresponding to screen size
  useEffect(() => {
    if (window.innerWidth < 576) {
      setList(data?.slice(0, 4));
    }

    if (window.innerWidth >= 576 && window.innerWidth < 1280) {
      setList(data?.slice(0, 10));
    }

    if (window.innerWidth >= 1280) {
      setList(data?.slice(0, 20));
    }
  }, [data]);

  // if (isFetching) return <div>Loading...</div>;
  // if (error) return <div>error</div>;

  //to display plans exactly below country
  const subArrays = [];
  if (window.innerWidth < 576) {
    for (let i = 0; i < list?.length; i++) {
      subArrays.push(list?.slice(i, i + 1));
      // subArrays.push(i);    }
    }
  }
  if (window.innerWidth >= 576 && window.innerWidth < 1280) {
    for (let i = 0; i < list?.length; i += 2) {
      subArrays.push(list?.slice(i, i + 2));
    }
  }
  if (window.innerWidth >= 1280) {
    for (let i = 0; i < list?.length; i += 4) {
      subArrays.push(list?.slice(i, i + 4));
    }
  }

  const renderCountryList = subArrays?.map((array, index) => {
    return (
      <React.Fragment key={index}>
        {array?.map((country) => {
          return (
            <div
              className="flex cursor-pointer flex-col rounded-[13px] bg-white hidden"
              key={country.code}
            >
              <div
                className={classNames(
                  selectedCountry?.name === country.name
                    ? "bg-teal text-white"
                    : "text-[#52b1ae]",
                  "grid-card flex justify-between"
                )}
                // onClick={(el) => {
                //   if (selectedCountry?.name !== country.name) {
                //     setSelectedCountry(country);
                //     MixpanelTrackEvent.clickedOnCountryEvent(country);
                //   } else {
                //     setSelectedCountry();
                //   }
                // }}
              >
                <div className="flex w-full items-center">
                  <img
                    className="flag-icon mr-[calc(10px+0.260vw)] md:mr-[calc(16px+0.4vw)]"
                    src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country.code}.svg`}
                    alt=""
                  />
                  <p className="country-name-text w-6 flex-1 overflow-hidden truncate font-semibold">
                    {country.name}
                  </p>
                </div>
                {selectedCountry?.name === country.name ? (
                  <IoIosArrowDown className="h-full max-h-7 w-6 pl-[8px] text-white" />
                ) : (
                  <img src={rightArrow} alt="" className="pl-[8px]" />
                )}
              </div>
            </div>
          );
        })}
        {array.includes(selectedCountry) && (
          <div className="col-span-4 xl:col-span-2 md:col-span-2 sm:col-span-1">
            <DataPlanPage country={selectedCountry} />
          </div>
        )}
      </React.Fragment>
    );
  });

  const searchCountry = (e) => {
    if (!listBeforeSearch.searchStarted) {
      if (e.target.value.length === 1)
        setListBeforeSearch({
          searchStarted: true,
          list: list,
        });
    }

    if (e.target.value.length === 0) {
      setList(listBeforeSearch.list);
      setListBeforeSearch({
        searchStarted: false,
      });
      return;
    }
    const serchedCountry = data.filter((country) => {
      const countryName = country.name + "";
      return countryName.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setList(serchedCountry);
  };

  return (
    // <>
    //   <div className="relative mx-auto mt-14 w-1/2 md:w-[72vw] sm:w-full">
    //     <input
    //       className="country-zone-search normal-24 w-full  border-none focus:border-none"
    //       type="text"
    //       placeholder="Search your destination"
    //       onChange={searchCountry}
    //     />
    //     <img
    //       src={searchIcon}
    //       alt=""
    //       className="absolute left-[calc(10px+1.042vw)] top-1/2 h-[calc(9px+0.903vw)] -translate-y-1/2 md:left-7 "
    //     />
    //   </div>
    //   {list && list.length === 0 ? (
    //     <>
    //       <p className="popular-destinations-text mt-9 text-center">
    //         No results found
    //       </p>
    //     </>
    //   ) : (
    //     <>
    //       <p className="popular-destinations-text mt-9 text-center">
    //         Popular destinations
    //       </p>

    //       <div className="mt-10 grid grid-cols-4 gap-6 xl:grid-cols-2 md:grid-cols-2 md:px-[5vw] sm:grid-cols-1 sm:gap-3">
    //         {renderCountryList}
    //       </div>

    //       <div className="show-countries-btn medium-20 mt-12 mb-[calc(36px+3.715vw)] text-center">
    //         {list?.length <= 20 && !listBeforeSearch.searchStarted && (
    //           <button
    //             className="medium-20 rounded-none md:h-12  sm:h-10 sm:w-full"
    //             onClick={() => {
    //               setList(data);
    //               MixpanelTrackEvent.clickedOn40Country();
    //             }}
    //           >
    //             Show 40+ countries
    //           </button>
    //         )}
    //       </div>
    //     </>
    //   )}
    // </>
    <div className="my-5 grid grid-cols-4 gap-6 xl:grid-cols-2 md:my-10 md:grid-cols-2 md:px-[5vw] sm:grid-cols-1 sm:gap-3">
      {renderCountryList}
    </div>
  );
}

export default DashboardCountryTab;
