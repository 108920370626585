import { createProfile, useLazyProfileQuery } from "../utils/store";
import { useDispatch, useSelector } from "react-redux";

export default function useSetProfileData() {
  const [getProfileData] = useLazyProfileQuery();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let profileData;

  const setProfile = async () => {
    const { data } = await getProfileData(auth.token);
    if (data) dispatch(createProfile(data));
    profileData = data;
  };
  return { setProfile, profileData };
}
