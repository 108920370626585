import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { endpoints, BASE_URL } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const podProductApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      creatingNewPlane: builder.mutation({
        query: (body) => {
          return {
            url: endpoints.podProduct.creatingNewPlane,
            body: body,
            method: "POST",
          };
        },
      }),
    };
  },
});

export const { useCreatingNewPlaneMutation } = podProductApi;
export { podProductApi };
