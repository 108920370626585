import React from "react";
import "./Model.css";
import ButtonForModal from "./ButtonForModal";
export default function VoucherAutoRefill({ setIsOpen, discountType }) {
  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col sm:text-center">
      <div className="my-account  w-[calc(151px+15.729vw)]  md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="medium-22 w-[calc(151px+15.729vw)] space-y-3 p-[calc(20px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="sm:px-6">
          You have used {discountType} and turned on auto-refill for this plan.
        </div>
        <div className="sm:px-6">
          The discounted price is applicable only for this purchase.
        </div>
        <div className="sm:px-6">
          Any future auto-refills will be charged at the standard price.
        </div>
        <div>
          <ButtonForModal
            color="teal"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            OK
          </ButtonForModal>
        </div>
      </div>
    </div>
  );
}
