import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { endpoints, BASE_URL } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const chckoutSessionApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      createCheckoutSession: builder.mutation({
        query: ({ productId, coupon, body }) => {
          return {
            url: endpoints.chckoutSession.createCheckoutSession(productId),
            params: {
              coupon: coupon,
            },
            body: body,

            method: "POST",
          };
        },
      }),
      createUserSession: builder.mutation({
        query: ({ transactionId, coupon }) => {
          return {
            url: endpoints.chckoutSession.createUserSession,
            body: { transactionId: transactionId },
            params: {
              coupon: coupon,
            },
            method: "POST",
          };
        },
      }),
    };
  },
});

export const {
  useCreateCheckoutSessionMutation,
  useCreateUserSessionMutation,
} = chckoutSessionApi;
export { chckoutSessionApi };
