import { useDispatch, useSelector } from "react-redux";
import {
  activationApi,
  authApi,
  cardsApi,
  chckoutSessionApi,
  createUser,
  destroyProfile,
  destroyUser,
  productPlansApi,
  store,
  supportApi,
  usersApi,
} from "../store";
import { MixpanelTrackEvent } from "../../mixpanelEvents";
export default function useAuth() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const TOKEN_KEY = "jwt";

  const login = (data) => {
    dispatch(createUser(data));
    localStorage.setItem(TOKEN_KEY, JSON.stringify(data));
  };

  const logout = (screen) => {
    MixpanelTrackEvent.logoutEvent(screen);

    dispatch(destroyUser());
    dispatch(destroyProfile());

    ///to invalidate cache
    store.dispatch(productPlansApi.util.resetApiState());
    store.dispatch(activationApi.util.resetApiState());
    store.dispatch(authApi.util.resetApiState());
    store.dispatch(cardsApi.util.resetApiState());
    store.dispatch(chckoutSessionApi.util.resetApiState());
    store.dispatch(usersApi.util.resetApiState());
  };

  const isLogin = () => {
    if (auth) return true;
    return false;
  };

  return { login, logout, isLogin, TOKEN_KEY };
}
