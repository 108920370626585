import React from "react";
import { useEffect } from "react";
import { BiLoaderAlt } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router";
import useSelectPopupPomponent from "../Hooks/use-select-popup-component";
import {
  setProductPlanForModal,
  useGetEsimStatusQuery,
  useLazyGetEsimStatusQuery,
  useLazyGetUsageDataQuery,
  useSubscribePlanMutation,
} from "../utils/store";
import { useProductsQuery } from "../utils/store/api/productPlansApi";
import DateFormator from "./DateFormator.component";
import ProgressionBar from "./ProgressionBar.component";
import SwitchButton from "./Switch.component";
import { nanoid } from "@reduxjs/toolkit";
import { useState } from "react";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import installationDetailsIcon from "../resources/icons/installation_details_icon/install@2x.png";
import supportICon from "../resources/icons/support_icon/technical-support2x.png";
import { useSelector } from "react-redux";
import { MixpanelTrackEvent } from "../mixpanelEvents";
import RenderTotalData from "./RenderTotalData.component";
import useInstallESim from "../Hooks/useInstallEsim";
import useSettingsInstructions from "../Hooks/use-settings-instructions";
export default function SubscriptionPlan() {
  let location = useLocation();
  const navigate = useNavigate();
  const { data, refetch: refetchProductData } = useProductsQuery();
  const { data: esimStatus, refetchEsim } = useGetEsimStatusQuery();
  const [getDataUsage, usageData] = useLazyGetUsageDataQuery();
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  const { SettingInstructions } = useSettingsInstructions();

  const { installESim } = useInstallESim();
  let renderProductPlan;
  const activetPlan = data?.filter((plan) => {
    return plan.status === "active";
  });

  useEffect(() => {
    if (activetPlan?.length) getDataUsage();
  }, [data]);
  renderProductPlan =
    (activetPlan?.length && activetPlan[0]) || (data?.length && data[0]);
  if (!renderProductPlan) return;
  if (activetPlan?.length)
    return (
      <div
        className={`mb-[3.5vw] flex min-h-[calc(90px+9.375vw)] items-stretch justify-center gap-12 md:gap-8 sm:my-8 sm:flex-col sm:items-center sm:gap-6 ${
          location.pathname !== "/manage-my-plans"
            ? "md:flex-col md:items-center"
            : ""
        }`}
      >
        <div
          className={`subscription-sec-1   space-y-2 px-[calc(15px+2vw)] py-[calc(8px+1.389vw)] text-white md:space-y-3 md:text-center sm:h-full  sm:w-[85vw] sm:py-6`}
        >
          <div className="medium-32 mb-[calc(0.533vw)] ">
            Your Plan:{" "}
            <span className="uppercase">
              {renderProductPlan.status === "upcoming"
                ? "NOT ACTIVE"
                : renderProductPlan.status}
            </span>
          </div>
          <div
            className={`medium-28 space-y-1 ${
              location.pathname !== "/manage-my-plans"
                ? "md:flex md:justify-center md:space-y-0"
                : ""
            }`}
          >
            <div
              className={`${
                renderProductPlan.status === "upcoming" && "flex"
              } space-y-0.5 md:flex md:space-y-0 sm:flex-col`}
            >
              <div>{renderProductPlan.productCategory}</div>
              <div>
                - {renderProductPlan.country}{" "}
                <RenderTotalData
                  data={renderProductPlan.data}
                ></RenderTotalData>
              </div>
            </div>
            <div> - Valid for {renderProductPlan.validity} days</div>
          </div>
          <button
            className="normal-20 mt-4 w-full rounded-lg border border-white py-4 text-center md:w-2/5 md:py-3 sm:py-2"
            onClick={() => SettingInstructions()}
          >
            Settings Instructions
          </button>
        </div>
        <div className="subscription-sec-2 flex  h-auto py-5 px-[calc(23px+2.431vw)] md:px-[calc(20px+3.906vw)]  sm:w-[85vw] sm:flex-col sm:space-y-3 sm:px-[calc(13px+2.431vw)]">
          <div className="sm:flex sm:flex-col sm:items-center">
            <div className="orange medium-32 text-center">Data Usage:</div>
            <ProgressionBar dataUsage={usageData} />
          </div>
          <div className="flex flex-col px-[calc(15px+2.472vw)] md:px-[calc(22px+4.232vw)]">
            <SwitchButton
              plan={renderProductPlan}
              isAutoRefillOn={renderProductPlan.isAutoRefillOn}
              productId={renderProductPlan._id}
            />
            <div className="flex flex-1 items-center gap-[calc(20px+1.083vw)] md:justify-between sm:mt-4 sm:justify-between">
              <div>
                <div className="medium-28 mb-1 text-[#383838]">Total Data</div>
                <RenderTotalData
                  className="orange semibold-24"
                  data={renderProductPlan.data}
                ></RenderTotalData>
              </div>
              <div>
                <div className="medium-28 mb-1 text-[#383838]">Valid Until</div>
                <div className=" orange semibold-24 ">
                  <DateFormator date={renderProductPlan?.expiryDate} />
                </div>
              </div>
            </div>
          </div>
          {location.pathname !== "/manage-my-plans" && (
            <div className="flex flex-col justify-center gap-[calc(10px+1.042vw)] sm:pl-0">
              {/* <button
                onClick={installESim}
                className={`${
                  esimStatus?.esimStatus === "installed" ? "" : "visible"
                } invisible medium-20 w-full rounded-md border border-[#D05929]  bg-[#D05929] py-2.5 px-2 text-white md:px-6`}
              >
                Installation Instructions
              </button> */}
              <button
                className=" medium-20  w-full rounded-md border  border-[#D05929] py-2.5 px-3  md:px-6"
                onClick={() => navigate("/manage-my-plans")}
              >
                Manage My Plans
              </button>
            </div>
          )}
        </div>
      </div>
    );
  const pendingPlans = data?.filter((plan) => {
    return plan.status === "upcoming";
  });
  if (!pendingPlans?.length) return;
  return (
    <div className="mb-[3.5vw] flex justify-center sm:my-8">
      <div className="bg-orange flex w-[calc(445px+46.319vw)] justify-center gap-10 rounded-lg md:w-[calc(273px+53.385vw)] md:gap-4 sm:w-[85vw] sm:flex-col sm:gap-4">
        <Pending plans={pendingPlans.slice(0, 1)} />
        <div className="flex flex-col items-center justify-center gap-14 md:gap-10 md:pr-[calc(8px+3.4vw)] sm:gap-4 sm:pl-0">
          <button
            onClick={installESim}
            className={`${
              esimStatus?.esimStatus === "installed"
                ? "invisible sm:order-2"
                : ""
            }  normal-20 relative w-[calc(106px+15.188vw)] rounded-md border border-[#D05929] bg-white py-4 px-3 md:px-6 sm:w-[70vw] sm:py-2`}
          >
            <span className="font-light text-[#383838]">
              Installation Instructions
            </span>
            <MdOutlineArrowForwardIos className="orange absolute top-1/2 right-3 -translate-y-1/2 transition-all" />
            <img
              src={installationDetailsIcon}
              alt=""
              className="orange absolute top-1/2 left-4 w-8 -translate-y-1/2 transition-all md:w-6 sm:left-3 sm:w-4"
            />
          </button>

          <button
            className={`${
              esimStatus?.esimStatus === "installed" ? "sm:order-1 sm:mb-0" : ""
            } normal-20 relative w-[calc(106px+15.188vw)] rounded-md border border-[#D05929] bg-white py-4 px-3  md:px-6 sm:mb-4 sm:w-[70vw] sm:py-2`}
          >
            <a
              className="font-light text-[#383838]"
              href="https://ridemobile.zohodesk.com/portal/en/kb/ridemobile"
              target={"_blank"}
              rel="noreferrer"
            >
              Support
            </a>
            <MdOutlineArrowForwardIos className="orange absolute top-1/2 right-3 -translate-y-1/2 transition-all" />
            <img
              src={supportICon}
              alt=""
              className="orange absolute top-1/2 left-4  w-8 -translate-y-1/2 transition-all md:w-6 sm:left-3 sm:w-4"
            />
          </button>
        </div>
      </div>
    </div>
  );
}
export function Pending({ plans }) {
  const { data, refetch: refetchProductData } = useProductsQuery();
  const profile = useSelector((state) => state.profile);
  const [subscribePlan, resp] = useSubscribePlanMutation();
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  const [refetchEsim, refetchEsimSatus] = useLazyGetEsimStatusQuery();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const { SettingInstructions } = useSettingsInstructions();
  const handleActivatePlan = async (plan) => {
    dispatch(setProductPlanForModal(plan));
    setSelectedPlan(plan);
    const esimStatus = await refetchEsim();
    if (esimStatus?.data.esimStatus !== "installed") {
      return dispatch(setPopupComponent("SimInstallation"));
    }
    const resp = await subscribePlan({
      productId: plan._id,
      body: {
        productId: plan._id,
        transactionId: plan.transactionId,
        planId: plan.productId,
        upcoming: plan.status === "upcoming",
        country: plan.country,
      },
    });
    if (resp.data) {
      profile?.isFirstTimeActivator &&
        dispatch(setPopupComponent("FirstPlanActivatedSuccessfully"));
      refetchProductData();
      MixpanelTrackEvent.planActivation(plan, "Activated");
    } else {
      MixpanelTrackEvent.planActivation(plan, "Failed");
      throw new Error(resp.error.data.message);
    }
  };
  if (!plans?.length)
    return (
      <div classNam e="medium-20 h-64 pt-10 text-center">
        No pending plans
      </div>
    );
  const renderPendingPlans = plans?.map((plan) => {
    return (
      <div className="flex justify-center" key={nanoid()}>
        <div className="normal-28 w-[calc(213px+22.188vw)] space-y-2 rounded-[calc(3px+0.347vw)] bg-[#d05929] p-[calc(13px+1.389vw)] text-white  md:py-[calc(13px+2.604vw)]  md:px-[calc(8px+3.4vw)]  sm:w-[90vw]">
          <div className="medium-32">
            Your Plan: <span className="uppercase">Not active</span>
          </div>
          {/* <div>Small Data Plan -India IGB -Valid for 7 days</div>  {plan.productCategory} - */}
          <div>
            {plan.country} <RenderTotalData data={plan.data}></RenderTotalData>{" "}
            - Valid for {plan.validity} days
          </div>
          <div>
            Purchased on - <DateFormator date={plan.purchaseDate} />
          </div>
          <div>
            This plan will expire unless activated by{" "}
            <DateFormator date={plan.purchaseDate} days={90} />
          </div>
          <div>Auto Refill {plan.isAutoRefillOn ? "ON" : "OFF"}</div>
          <div className={`flex w-full gap-2 sm:flex-col sm:items-center`}>
            <button
              className="normal-20 mt-4 w-[45%] rounded-lg border border-white py-4 text-center  sm:w-[70vw] sm:py-2"
              onClick={() => handleActivatePlan(plan)}
            >
              {resp.status === "pending" || refetchEsimSatus.isFetching ? (
                <BiLoaderAlt
                  className={`mx-auto animate-spin text-xl`}
                ></BiLoaderAlt>
              ) : (
                " Activate Now"
              )}
            </button>
            {profile?.isFirstTimeActivator ? (
              ""
            ) : (
              <button
                className="normal-20 mt-4 w-[45%] rounded-lg border border-white py-4 text-center sm:w-[70vw] sm:py-2"
                onClick={() => SettingInstructions()}
              >
                Settings Instructions
              </button>
            )}
          </div>
        </div>
      </div>
    );
  });
  return <div className="space-y-6">{renderPendingPlans}</div>;
}
