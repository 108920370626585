import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import "../Model.css";
import dots from "../images/Group 389.svg";
import { IoRadioButtonOnOutline, IoRadioButtonOff } from "react-icons/io5";
import useSelectPopupPomponent from "../../../Hooks/use-select-popup-component";
import {
  useDeleteCardMutation,
  useGetStripeCustomerDetails_Query,
  useLazyGetStripeCustomerDetails_Query,
  useUpdateDefaultCardMutation,
} from "../../../utils/store";
import { nanoid } from "@reduxjs/toolkit";
import NestedModal from "../../../components/NestedModal.component";
import EditCard from "../EditCard";

function SavedPaymentMethodesAfterEnableAutoRefill() {
  const [stripeCardDetails, stripeCardDetailsResult] =
    useLazyGetStripeCustomerDetails_Query();

  useEffect(() => {
    stripeCardDetails();
  }, []);

  // const [cardList, setCardList] = useState();
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  const [deleteCard, response] = useDeleteCardMutation();
  // useEffect(() => {
  //   setCardList(stripeCardDetailsResult.data);
  // }, [stripeCardDetailsResult]);

  const handleAddCardClick = () => {
    dispatch(setPopupComponent("AddPaymentMethodForAutoRefill"));
  };

  // if (stripeCardDetailsResult.isError) console.log(stripeCardDetailsResult.error.data.message);
  if (!stripeCardDetailsResult.data) return;
  const renderCards = stripeCardDetailsResult.data?.map((cardObj, index) => {
    return (
      <div
        key={nanoid()}
        className={`relative  flex h-[calc(22px+2.326vw)] items-center rounded-lg border border-[#52B1AE] sm:h-12 ${
          response?.cardList?.id === cardObj.id ? "hidden" : ""
        }`}
      >
        <div className="ml-4 flex flex-col sm:mr-20">
          <div className="normal-18 ">
            **** **** **** {cardObj.card["last4"]}
          </div>
          <div className="normal-12 uppercase opacity-70 ">
            {cardObj.card.brand}
          </div>
          {cardObj.default_source && (
            <>
              <div className="normal-12 w-fit rounded bg-teal-500/30 px-2 py-1">
                <div className="text-[#52b1ae]">Default for Auto-Refill</div>
              </div>
            </>
          )}
        </div>
        <DropdownMenu
          card={cardObj}
          deleteCard={deleteCard}
          index={index}

          // setCardList={setCardList}
          // cardList={cardList}
        />
      </div>
    );
  });
  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col">
      <div className="card-details w-[calc(151px+15.729vw)]  md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className=" w-[calc(141px+15.729vw)] p-[calc(13px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:pt-[calc(14px+2.734vw)] sm:w-full sm:px-8">
        <div className="teal medium-26 sm:text-center">
          Set default payment method for auto refill
        </div>

        <div className="space-y-6 sm:space-y-4">
          <div className="flex justify-end ">
            <button
              className="bg-teal mt-5 h-[calc(15px+1.528vw)] w-[calc(35px+3.611vw)] rounded-lg border border-transparent text-white sm:h-10 sm:w-20"
              onClick={handleAddCardClick}
            >
              Add
            </button>
          </div>
          <div className="max-h-64 space-y-4 overflow-y-auto pb-[calc(40px+1.328vw)] md:max-h-52 md:pb-[calc(40px+2.834vw)]  sm:max-h-48 sm:pb-14">
            {renderCards}
          </div>

          {/* <div className="relative flex h-[calc(22px+2.326vw)] items-center rounded-lg border border-[#52B1AE]">
            <div className="absolute left-4">0</div>
            <div className="ml-[calc(21px+2.222vw)] flex flex-col">
              <div className="normal-18">**** **** **** 7890</div>
              <div className="normal-16 self-end opacity-70">AMEX</div>
            </div>
            <DropdownMenu />
          </div> */}
        </div>
      </div>
    </div>
  );
}
export default SavedPaymentMethodesAfterEnableAutoRefill;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function DropdownMenu({ card, deleteCard, index }) {
  const [isOpen, setIsOpen] = useState(false);
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();

  const handleDeleteCard = () => {
    deleteCard(card.id);
  };

  const [defaultCard] = useUpdateDefaultCardMutation();
  const handleMakeDefalut = async () => {
    const resp = await defaultCard(card.id);
    if (resp.data) dispatch(setPopupComponent("EnableAutoRefillAccept"));
  };

  const userNavigation = [
    { name: "Edit", href: "/", click: () => setIsOpen(true), icon: "" },
    { name: "Delete", href: "/", click: handleDeleteCard, icon: "" },
    {
      name: "Make default for auto refill",
      href: "/",
      click: handleMakeDefalut,
      icon: "",
    },
  ];
  return (
    <Menu as="div" className="absolute right-4">
      <NestedModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <EditCard card={card} />
      </NestedModal>
      <div>
        <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-offset-2">
          <span className="sr-only" data-testid="bell">
            Open user menu
          </span>
          <img src={dots} alt="" className="h-4" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute ${
            index > 1
              ? "-bottom-[calc(11px+1.094vw)]"
              : "-top-[calc(11px+1.094vw)] sm:-top-6"
          } -right-4  z-10 mt-2 origin-top-right whitespace-nowrap rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          {userNavigation.map((item) => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <>
                  {(item.name !== "Make default for auto refill" ||
                    !card.default_source) && (
                    <Link
                      // to={item.href}
                      onClick={item.click}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        card.default_source ? "pr-12" : "",
                        "normal-16 block px-4 py-2 text-gray-700 sm:py-1.5"
                      )}
                    >
                      {item.name}
                    </Link>
                  )}
                </>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
