import "intl-tel-input/build/css/intlTelInput.css";

import { useEffect, useState, Fragment } from "react";
import { useLazyCountryListQuery } from "../utils/store";
import { Listbox, Transition } from "@headlessui/react";
import { RxDividerVertical, RxChevronDown } from "react-icons/rx";
export default function PhoneInputField({
  field,
  form,
  profile,
  isEdit,
  isPopUp,
  isStoreData,
}) {
  const [getAllCountryList, { data: country }] = useLazyCountryListQuery();
  const [selectedCountry, setSelectedCountry] = useState(country && country[0]);
  const [mobile, setMobile] = useState("");

  useEffect(() => {
    getAllCountryList();
  }, []);

  useEffect(() => {
    if (country && !profile) setSelectedCountry(country[0]);
    else setEditFlag();
  }, [country]);

  useEffect(() => {
    if (field.value === "") setMobile("");
  }, [field.value]);

  useEffect(() => {
    form.setFieldValue(
      field.name,
      `${selectedCountry && selectedCountry["dial_code"]}.${mobile}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobile, selectedCountry]);
  useEffect(() => {
    if (isEdit || isStoreData) {
      setEditFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, isStoreData]);

  const setEditFlag = () => {
    const filterd = country?.find((conrty) => {
      return conrty.dial_code === profile.countryCode;
    });
    setSelectedCountry(filterd);
    setMobile(profile?.mobile ? profile.mobile : "");
  };
  return (
    <>
      <div
        className={`relative flex ${
          isPopUp ? "phone-input-country-popup" : "phone-input-country-form"
        }`}
      >
        <Input
          country={country}
          mobile={mobile}
          setMobile={setMobile}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          isEdit={isEdit}
          isPopUp={isPopUp}
          isStoreData={isStoreData}
        />
      </div>
    </>
  );
}

function PhoneInputComponent({ selectedCountry, setSelectedCountry, country }) {
  if (!country) return;
  return (
    <div className="relative z-50">
      <Listbox value={selectedCountry} onChange={setSelectedCountry}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-max  cursor-default bg-inherit py-2  text-left focus:outline-none focus-visible:border-transparent focus-visible:ring-2 focus-visible:ring-transparent focus-visible:ring-opacity-75">
            <span className="flex  h-full items-center text-end text-inherit text-white md:text-black/70 sm:text-black/70">
              <span>{selectedCountry && selectedCountry["dial_code"]}</span>
              <RxChevronDown className="h-full pl-1" />
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              {/* <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-auto mt-1 max-h-60 w-72 overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none md:w-64 sm:w-[70vw]">
              {country?.map((country, countryIdx) => (
                <Listbox.Option
                  key={countryIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 px-4 ${
                      active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                    }`
                  }
                  value={country}
                >
                  {({ selectedCountry }) => (
                    <div className="normal-18 flex items-center gap-2">
                      <img
                        className="h-3"
                        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country.code}.svg`}
                        alt=""
                      />
                      <span
                        className={`block truncate ${
                          selectedCountry ? "font-medium" : "font-normal"
                        }`}
                      >
                        {country.name}
                      </span>
                      <span className="">{country["dial_code"]}</span>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}

function Input({
  mobile,
  setMobile,
  selectedCountry,
  setSelectedCountry,
  country,
  isEdit,
  isPopUp,
}) {
  return (
    <div className={`${isEdit ? "edit-tab" : ""} tab-create-account flex`}>
      <PhoneInputComponent
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        country={country}
        className=""
      />
      <RxDividerVertical className="-ml-2 self-center text-4xl text-gray-500" />
      <input
        placeholder="Mobile Number"
        className={`h-full w-full border-none bg-inherit text-inherit text-white focus:outline-none focus:ring-transparent focus-visible:border-transparent focus-visible:outline-none focus-visible:ring-transparent md:text-black/70 md:placeholder:text-black/[0.65] sm:text-black/70 sm:placeholder:text-black/[0.65]  ${
          isPopUp
            ? "placeholder:text-black/[0.65]"
            : "placeholder:text-white/70 "
        }`}
        value={mobile}
        onChange={(e) => setMobile(e.target.value)}
        type="number"
      ></input>
    </div>
  );
}
