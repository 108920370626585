import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { endpoints, BASE_URL } from "../../config/endpoint.config";

const countryListApi = createApi({
  reducerPath: "countryListApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints(builder) {
    return {
      zoneInfo: builder.query({
        query: () => {
          return {
            url: endpoints.countryList.zoneInfo,
            method: "GET",
          };
        },
      }),
      zoneList: builder.query({
        query: () => {
          return {
            url: endpoints.countryList.zoneList,
            method: "GET",
          };
        },
      }),
      countryList: builder.query({
        query: () => {
          return {
            url: endpoints.countryList.countryList,
            method: "GET",
          };
        },
      }),
      getAllCountry: builder.query({
        query: () => {
          return {
            url: endpoints.countryList.getAllCountry,
            method: "GET",
          };
        },
      }),
      getALlZones: builder.query({
        query: () => {
          return {
            url: endpoints.countryList.getALlZones,
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {
  useCountryListQuery,
  useGetALlZonesQuery,
  useGetAllCountryQuery,
  useZoneInfoQuery,
  useZoneListQuery,
  useLazyCountryListQuery,
} = countryListApi;
export { countryListApi };
