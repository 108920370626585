import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { endpoints, BASE_URL } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const cardsApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      addNewCard: builder.mutation({
        invalidatesTags: ["card"],
        query: (body) => {
          return {
            url: endpoints.cards.addNewCard,
            body: body,
            method: "POST",
          };
        },
      }),
      ///not used
      getStripeCustomerDetails: builder.query({
        query: (data) => {
          return {
            url: endpoints.cards.getStripeCustomerDetails(data),
            method: "GET",
          };
        },
      }),

      updateCard: builder.mutation({
        invalidatesTags: ["card"],
        query: (body) => {
          return {
            url: endpoints.cards.updateCard,
            body: body,
            method: "PUT",
          };
        },
      }),
      getStripeCustomerDetails_: builder.query({
        providesTags: ["card"],
        query: () => {
          return {
            url: endpoints.cards.getStripeCustomerDetails_,
            method: "GET",
          };
        },
      }),

      updateDefaultCard: builder.mutation({
        invalidatesTags: ["card"],
        query: (cardId) => {
          return {
            url: endpoints.cards.updateDefaultCard,
            body: { cardId },
            method: "PUT",
          };
        },
      }),
      deleteCard: builder.mutation({
        invalidatesTags: ["card"],
        query: (cardId) => {
          return {
            url: endpoints.cards.deleteCard,
            body: { cardId },
            method: "DELETE",
          };
        },
      }),
    };
  },
});

export const {
  useAddNewCardMutation,
  useDeleteCardMutation,
  useGetStripeCustomerDetailsQuery,
  useGetStripeCustomerDetails_Query,
  useLazyGetStripeCustomerDetails_Query,
  useUpdateCardMutation,
  useUpdateDefaultCardMutation,
} = cardsApi;
export { cardsApi };
