import React from "react";
import closeButton from "./images/Group 685.svg";
export default function InfoInCountry({ setIsOpen }) {
  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col">
      <div className="info-country  w-[calc(151px+15.729vw)]    md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="w-[calc(151px+15.729vw)] p-[calc(13px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="medium-18 p-4 pt-10 text-center sm:my-[calc(12px+1.250vw)] sm:px-3 sm:pt-4">
          In-Country service provides data coverage within your country; it
          will find cellular data coverage wherever it's available. If you hit a
          dead zone, and any other carrier has a usable signal, Ride Mobile
          connects you.
        </div>

        <button
          className="normal-12 absolute right-6 top-6 flex items-center gap-3  whitespace-nowrap rounded-lg bg-white px-4 py-2 focus-visible:outline-none sm:h-8"
          onClick={() => setIsOpen(false)}
        >
          <img src={closeButton} alt="" className="h-[calc(4px+0.417vw)]"></img>
          {window.innerWidth <= 576 && "Close"}
        </button>
      </div>
    </div>
  );
}
