import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import bars from "../resources/icons/Group482.svg";
import useAuth from "../utils/Auth/authChecker";
import useSelectPopupPomponent from "../Hooks/use-select-popup-component";

import myAccountIcon from "../resources/icons/DropdownMenu/Group 117/Group 117@2x.png";
import managedPlansIcon from "../resources/icons/DropdownMenu/mobile-data/mobile-data@2x.png";
import explorePlansIcon from "../resources/icons/DropdownMenu/shopping-cart (1)-Recovered/shopping-cart (1)-Recovered@2x.png";
import supportIcon from "../resources/icons/DropdownMenu/technical-support (2)/technical-support (2)@2x.png";
import termsIcon from "../resources/icons/DropdownMenu/terms-and-conditions/terms-and-conditions@2x.png";
import policyIcon from "../resources/icons/DropdownMenu/privacy/privacy@2x.png";
import aboutUsIcon from "../resources/icons/DropdownMenu/info.svg";
import contactUsIcon from "../resources/icons/DropdownMenu/Group 821.svg";
import faqIcon from "../resources/icons/DropdownMenu/g3447.svg";
import logoutIcon from "../resources/icons/DropdownMenu/logout/logout@2x.png";
import { RxCross2 } from "react-icons/rx";
import { MixpanelTrackEvent } from "../mixpanelEvents";
import giftIcon from "../resources/icons/gift-card@2x.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DropdownMenu({ left }) {
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  const navigate = useNavigate();
  const { logout, isLogin } = useAuth();
  const location = useLocation();

  const handleLogout = () => {
    logout(location.pathname);
  };

  const handleMyAccountClick = () => {
    dispatch(setPopupComponent("MyAccount"));
  };
  const handleSupportClick = () => {
    // dispatch(setPopupComponent("ContactUs"));
    window.open("https://ridemobile.zohodesk.com/portal/en/kb/ridemobile");
  };
  const handleManagedPlansClick = () => {
    navigate("/manage-my-plans");
    setTimeout(() => {
      var element = document.getElementById("manage-plans-scroll");
      var headerOffset = 108;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }, 200);
  };
  const handleTermAndConditionClick = () => {
    // dispatch(setPopupComponent("TermsAndConditions"));
    window.open("https://www.ridemobile.com/terms-and-conditions");
  };
  const handlePrivacyPolicyClick = () => {
    // dispatch(setPopupComponent("PrivacyPolicy"));
    window.open("https://www.ridemobile.com/privacy-policy");
  };
  const aboutUsClick = () => {
    // dispatch(setPopupComponent("PrivacyPolicy"));
    window.open("https://ridemobile.com/about-us/");
    MixpanelTrackEvent.aboutUsClick(location.pathname + " (Hamburger Menu)");
  };
  const contactUsClick = () => {
    // dispatch(setPopupComponent("PrivacyPolicy"));
    window.open("https://www.ridemobile.com/contact");
    MixpanelTrackEvent.contactUsClick(location.pathname + " (Hamburger Menu)");
  };
  const faqClick = () => {
    // dispatch(setPopupComponent("PrivacyPolicy"));
    window.open("https://ridemobile.zohodesk.com/portal/en/kb/ridemobile/faq");
    MixpanelTrackEvent.faqClick(location.pathname + " (Hamburger Menu)");
  };
  const handleReferClick = () => {
    dispatch(setPopupComponent("ReferAndRide"));
  };

  const userNavigation = [
    {
      name: "My Account",
      click: handleMyAccountClick,
      icon: myAccountIcon,
      loggedInOnly: true,
    },
    {
      name: "Manage Plans",
      href: "/manage-my-plans",
      click: handleManagedPlansClick,
      icon: managedPlansIcon,
      loggedInOnly: true,
    },
    {
      name: "Refer & Ride",
      click: handleReferClick,
      icon: giftIcon,
      loggedInOnly: true,
    },
    {
      name: "Explore Plans",
      click: () => {
        navigate("/");
        setTimeout(() => {
          var element = document.getElementById("explore-plans");
          var headerOffset = 108;
          var elementPosition = element.getBoundingClientRect().top;
          var offsetPosition =
            elementPosition + window.pageYOffset - headerOffset;
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }, 200);
      },
      icon: explorePlansIcon,
    },
    { name: "Support", click: handleSupportClick, icon: supportIcon },
    {
      name: "Terms & Conditions",
      click: handleTermAndConditionClick,
      icon: termsIcon,
    },
    {
      name: "Privacy Policy",
      click: handlePrivacyPolicyClick,
      icon: policyIcon,
    },
    {
      name: "About Us",
      click: aboutUsClick,
      icon: aboutUsIcon,
      smallScreenOnly: true,
    },
    {
      name: "Contact Us",
      click: contactUsClick,
      icon: contactUsIcon,
      smallScreenOnly: true,
    },
    {
      name: "FAQ",
      click: faqClick,
      icon: faqIcon,
      smallScreenOnly: true,
    },

    {
      name: "Log out",
      click: handleLogout,
      icon: logoutIcon,
      loggedInOnly: true,
    },
    {
      name: "cross",
    },
  ];
  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-offset-2">
          <span className="sr-only" data-testid="bell">
            Open user menu
          </span>
          <img
            src={bars}
            alt=""
            className="h-6  w-[calc(12px+1.250vw)] md:w-[33px] sm:-mt-1 sm:h-4 sm:w-[29px]"
          />
        </Menu.Button>
      </div>
      <Transition
        as={"div"}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`${
            left === "0" ? "left-0" : "right-0"
          } absolute z-10  mt-[calc(13px+1.389vw)] w-[calc(117px+12.153vw)] origin-top-right rounded-md bg-white px-4 py-2 pt-10 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:w-[60vw]`}
        >
          {userNavigation.map((item, index) => (
            <Menu.Item key={item.name} onClick={item.click}>
              {({ active, close }) => (
                <div>
                  {item.name === "cross" ? (
                    <RxCross2
                      className={`orange absolute top-5 right-5 cursor-pointer`}
                      onClick={close}
                    />
                  ) : (
                    <div
                      className={`${
                        item.smallScreenOnly ? "hidden md:flex sm:flex" : "flex"
                      } ${
                        !item.loggedInOnly ? "" : isLogin() ? "flex" : "hidden"
                      }  cursor-pointer  items-center gap-4 px-[calc(3px+0.278vw)] py-[calc(5px+0.556vw)] sm:py-4 ${
                        active ? "bg-gray-100" : ""
                      } ${
                        (isLogin() && index !== 0) ||
                        (!isLogin() && index !== 2)
                          ? "border-t"
                          : ""
                      }`}
                    >
                      <img
                        src={item.icon}
                        alt=""
                        className="h-[calc(8px+0.868vw)]  w-[calc(8px+0.868vw)] sm:h-4 sm:w-4"
                      ></img>
                      <div
                        className={classNames(
                          // active ? "bg-gray-100" : "",
                          "normal-18 block font-light text-gray-700"
                        )}
                      >
                        {item.name}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
