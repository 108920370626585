import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  setIsAutoRefill,
  setIsFreeProduct,
  setProductPlanForModal,
  useCreateCheckoutSessionMutation,
  useLazyProductsQuery,
  useProductsQuery,
  useRedeemProductMutation,
} from "../../utils/store";
import ButtonForModal from "./ButtonForModal";

import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import checkmarkIcon from "./images/Group 777.svg";
import NestedModal from "../../components/NestedModal.component";
import VoucherAutoRefill from "./VoucherAutoRefill";
import InfoAutoRefill from "./InfoAutoRefill";
import { BsQuestionCircle } from "react-icons/bs";
import useRideMilesDetails from "../../Hooks/useRideMiles";
import useSetProfileData from "../../Hooks/useSetProfileData";
import RenderTotalData from "../../components/RenderTotalData.component";
import ApiFetchingErrorMsg from "../../components/ApiFetchingErrorMsg.component";
import DisableFreeProductForAutoRefill from "./DisableFreeProductForAutoRefill";

export default function BuyNowWithRideMiles() {
  const [useRideMiles, setUseRideMiles] = useState(true);
  const [isDisableFreeProductOpen, setIsDisableFreeProductOpen] =
    useState(false);
  const { setProfile } = useSetProfileData();
  const { data } = useProductsQuery();
  const [isTotallyRedeemableWithPoints, setIsTotallyRedeemableWithPoints] =
    useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [getUpdatedProducts] = useLazyProductsQuery();
  const [value, setValue] = useState(
    sessionStorage.getItem("isAutoRefillOn") === "true" ? true : false
  );
  sessionStorage.setItem("isAutoRefillOn", value);
  const { productPlanForModal } = useSelector((state) => state.modalState);

  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  const [redeemPoints, response] = useRedeemProductMutation();
  const [createCheckoutSession, resp] = useCreateCheckoutSessionMutation();
  const { usableRideMiles, usableRideMilesValue } = useRideMilesDetails(
    productPlanForModal.priceBundle
  );
  const profile = useSelector((state) => state.profile);
  useEffect(() => {
    if (
      value === true &&
      productPlanForModal.priceBundle != usableRideMilesValue
    )
      setIsOpen(true);
  }, [value]);

  const handleFreeProduct = async () => {
    if (value) await setIsDisableFreeProductOpen(true);
    setIsTotallyRedeemableWithPoints(true);
    setValue(false);
    dispatch(setIsAutoRefill(false));
  };

  useEffect(() => {
    if (productPlanForModal.priceBundle == usableRideMilesValue) {
      handleFreeProduct();
    }
  }, []);

  useEffect(() => {
    if (!useRideMiles) dispatch(setPopupComponent("BuyNow"));
  }, [useRideMiles]);

  const {
    redeemableRideMilesValue,
    redeemableRideMiles,
    remainingRideMiles,
    remainingRideMilesValue,
  } = useRideMilesDetails(productPlanForModal.priceBundle);

  const handleBuyNowClick = async () => {
    sessionStorage.setItem("plan", JSON.stringify(productPlanForModal));

    if (isTotallyRedeemableWithPoints) {
      dispatch(setIsFreeProduct(true));
      const resp = await redeemPoints({
        planId: productPlanForModal.stripeProductId,
        country: productPlanForModal.country,
      });
      if (resp.data) {
        sessionStorage.setItem("ride-miles", JSON.stringify(usableRideMiles));
        getUpdatedProducts();
        if (data?.length === 0) {
          dispatch(setPopupComponent("PaymentSuccessful"));
        }
        if (data?.length) {
          dispatch(setPopupComponent("PurchaseSuccess"));
        }
        setProfile();
        sessionStorage.setItem("plan", JSON.stringify(resp.data));
        dispatch(setProductPlanForModal(resp.data));
      }
    } else {
      const resp = await createCheckoutSession({
        productId: productPlanForModal.stripeProductId,
        body: {
          redeemRideMiles: true,
          rideMiles: usableRideMiles,
        },
      });
      if (resp.data) {
        sessionStorage.setItem("ride-miles", JSON.stringify(usableRideMiles));
        window.location.href = resp.data.url;
      }
      if (resp.error) throw new Error(resp.error.data.message);
    }
  };

  const renderVoucherDiscountApplied = (
    <div>
      <div className="mb-5 flex items-center gap-5 sm:justify-center sm:gap-2">
        <div
          className={`relative  flex-1 text-gray-400  focus-within:text-gray-600 `}
        >
          <div className=" teal normal-16 flex h-[calc(22px+2.326vw)] w-full flex-col justify-center rounded-md border border-[#52B1AE] bg-inherit pl-12 autofill:bg-transparent  focus:border-[#52B1AE]  focus:ring-[#52B1AE]  md:pl-11 sm:h-12 sm:pl-11">
            <div className="medium-16 sm:text-start">Ride Miles</div>
            <div className="flex items-center">
              <span className=" whitespace-nowrap">
                {usableRideMiles} Miles - US ${usableRideMilesValue} OFF
              </span>
            </div>
          </div>
          <img
            src={checkmarkIcon}
            className="absolute top-1/2  left-4 block h-6 -translate-y-1/2 text-sm font-medium text-gray-700 transition-all md:left-3 md:h-5 sm:left-3 sm:h-5"
            alt={""}
          ></img>
        </div>
        <button
          onClick={() => {
            dispatch(setPopupComponent("BuyNow"));
          }}
          className={` medium-20 bg-teal flex h-[calc(22px+2.326vw)] w-[35%] items-center justify-center rounded-lg border border-transparent  py-4 px-4  text-xl font-medium text-white sm:h-12`}
        >
          Remove
        </button>
      </div>
      <div>
        <div className="teal normal-22 invisible pt-2 text-center">OR</div>
        <div className="medium-16 teal">Ride Miles</div>
        <div className="flex gap-16">
          <div className="flex items-center gap-1 rounded-md border border-slate-50 p-2 shadow-md">
            <div className="teal medium-18 ">
              {useRideMiles ? remainingRideMiles : redeemableRideMiles}
            </div>
            <div className="normal-14">
              (US $
              {useRideMiles
                ? remainingRideMilesValue
                : redeemableRideMilesValue}
              )
            </div>
          </div>
          <label className="medium-20 flex items-center gap-3">
            <span className={`${useRideMiles ? "" : "text-[#848484]"}`}>
              Use Ride Miles
            </span>
            <input
              disabled={redeemableRideMiles === 0}
              checked={useRideMiles}
              value={useRideMiles}
              onChange={(e) => {
                setUseRideMiles(!useRideMiles);
              }}
              type={"checkbox"}
              className={`${
                useRideMiles
                  ? "border-[#52B1AE] checked:bg-[#52B1AE] hover:checked:bg-[#52B1AE] focus:checked:bg-[#52B1AE]"
                  : "border-[#848484]"
              } rm-font-overpass -mt-1 bg-inherit focus:ring-transparent  md:-mt-0.5 sm:-mt-0.5`}
            />
          </label>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <NestedModal
        isOpen={isOpen && !isDisableFreeProductOpen}
        setIsOpen={setIsOpen}
        arrowColor="teal"
      >
        <VoucherAutoRefill setIsOpen={setIsOpen} discountType={"Ride Miles"} />
      </NestedModal>
      <NestedModal isOpen={isInfoOpen} setIsOpen={setIsInfoOpen}>
        <InfoAutoRefill setIsOpen={setIsInfoOpen} />
      </NestedModal>
      <NestedModal
        isOpen={isDisableFreeProductOpen}
        setIsOpen={setIsDisableFreeProductOpen}
      >
        <DisableFreeProductForAutoRefill
          setIsOpen={setIsDisableFreeProductOpen}
          type="Ride Miles"
        />
      </NestedModal>
      <div
        className={`${
          isOpen || isInfoOpen || isDisableFreeProductOpen ? "hidden" : "flex"
        }  w-full sm:w-[90vw] sm:flex-col`}
      >
        <div className="save-payment-method relative w-[calc(141px+15.729vw)]   md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
        <div className="w-[calc(151px+18.729vw)] py-[calc(3px+1.389vw)] px-[calc(9px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
          <div className="">
            <div className="teal medium-26 mb-3 sm:mt-5">
              <div className="capitalize">{profile?.firstName}, </div>
              <div>you selected:</div>
            </div>
            <div className="teal medium-26 ">
              {productPlanForModal?.productCategory} $
              {productPlanForModal?.priceBundle?.toFixed(2)}
            </div>
            <div className="normal-22 font-normal text-[#383838] ">
              {productPlanForModal?.country} -{" "}
              <RenderTotalData
                data={productPlanForModal.data}
              ></RenderTotalData>{" "}
              - Valid for {productPlanForModal?.validity} days
            </div>
          </div>
          <div className="mb-[calc(13px+1.389vw)] mt-[calc(8px+0.868vw)] space-y-[calc(8px+0.768vw)]">
            <div className="my-5 flex">
              <label
                className={`${
                  isTotallyRedeemableWithPoints ? "text-gray-400" : "teal"
                } normal-18 flex gap-2 font-light`}
              >
                <input
                  disabled={isTotallyRedeemableWithPoints}
                  checked={value}
                  value={value}
                  onChange={(e) => setValue(!value)}
                  type={"checkbox"}
                  className={`${
                    isTotallyRedeemableWithPoints
                      ? "border-gray-400"
                      : "border-[#52B1AE]"
                  }  mr-[0.83vw] mt-1 border-[#52B1AE] bg-inherit checked:bg-[#52B1AE] hover:checked:bg-[#52B1AE]  focus:ring-transparent focus:checked:bg-[#52B1AE] md:mt-0 sm:mt-0`}
                />
                Enable Auto Refill
              </label>
              <BsQuestionCircle
                className="teal ml-3 mt-1 inline  md:mt-0 sm:mt-0"
                onClick={() => setIsInfoOpen(true)}
              />
            </div>
            {renderVoucherDiscountApplied}
          </div>

          <div className="mt-[calc(20px+2.778vw)] sm:mb-3">
            <ButtonForModal
              color="teal"
              isSubmitting={
                resp.status === "pending" || response.status === "pending"
              }
              onClick={handleBuyNowClick}
            >
              {isTotallyRedeemableWithPoints ? "Continue" : "Buy Now"}
            </ButtonForModal>
          </div>
        </div>
      </div>
    </div>
  );
}
