import React from "react";
// import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import { useUsedForMutation } from "../../utils/store";
import ButtonForModal from "./ButtonForModal";
import NestedModal from "../../components/NestedModal.component";
import { useState } from "react";
import EsimActivation from "./EsimActivation";

export default function InstallationInCountry() {
  const [isOpen, setIsOpen] = useState(false);

  // const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  const [usedFor, resp] = useUsedForMutation();

  return (
    <div>
      <NestedModal isOpen={isOpen} setIsOpen={setIsOpen} arrowColor="orange">
        <EsimActivation setIsOpen={setIsOpen} />
      </NestedModal>
      <div
        className={`${
          isOpen ? "hidden" : "flex "
        } w-full sm:w-[90vw] sm:flex-col`}
      >
        <div className="save-payment-method  w-[calc(151px+15.729vw)]    md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
        <div className="w-[calc(151px+15.729vw)] space-y-4 p-[calc(13px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
          <div className="medium-26 teal">INSTALLATION INSTRUCTIONS</div>

          <div className="hidden">
            <div className="medium-26 teal">In-Country</div>
            <div className="normal-18 teal">
              Extended data coverage within your country
            </div>
          </div>
          <ol className="normal-18 list-inside list-decimal space-y-3">
            <li>
              Take a screenshot of these steps and Settings on the next screens
              to install your Ride Mobile eSIM, or find them in your FAQs
            </li>
            <li>
              Install/activate your eSIM following the instructions on your
              phone
            </li>
            <li>Follow the Settings on the next screens</li>
            <li>
              Return to the Ride Mobile App to activate your plan on the Manage
              Plans screen
            </li>
          </ol>
          <div className="flex gap-5 md:flex-col md:gap-3 sm:mt-4">
            <ButtonForModal
              isSubmitting={resp.status === "pending"}
              color="teal"
              onClick={async () => {
                const resp = await usedFor({
                  geographicScope: "domestic",
                });
                // if (resp) dispatch(setPopupComponent("EsimActivation"));
                if (resp) setIsOpen(true);
              }}
            >
              Install my eSIM
            </ButtonForModal>
          </div>
        </div>
      </div>
    </div>
  );
}
