import React from "react";
import ButtonForModal from "./ButtonForModal";
import { useLazyHistoryQuery } from "../../utils/store/api/vouchersApi";
import { useEffect } from "react";
import DateFormator from "../../components/DateFormator.component";
import { useState } from "react";
import moment from "moment/moment";

export default function RideMilesHistory() {
  const [getHistory, { data }] = useLazyHistoryQuery();
  const [noOfHistoryItems, setNoOfHistoryItems] = useState(5);

  useEffect(() => {
    getHistory();
  }, []);

  if (!data) {
    return;
  }
  const renderHistory = [...data]
    ?.reverse()
    .slice(0, noOfHistoryItems)
    .map((e, index) => {
      return (
        <div
          className="medium-18 flex items-center justify-between gap-1 py-2"
          key={index}
        >
          <div>
            <div>{e?.reason}</div>
            {/* <DateFormator
              className="font-light"
              date={e?.createdAt}
            ></DateFormator> */}
            {moment(e?.createdAt).format("Do MMMM YYYY")}
          </div>
          <div className="orange">{e?.points} Ride Miles</div>
        </div>
      );
    });

  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col">
      <div className="save-payment-method  w-[calc(151px+14.729vw)]    md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="w-[calc(151px+16.729vw)] space-y-4 p-[calc(10px+1.389vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="medium-30 orange text-center">Ride Miles History</div>
        <div
          className={`h-96 divide-y-2 overflow-y-scroll  md:h-72 sm:h-72 ${
            data?.length && "pr-3"
          }`}
        >
          {data?.length ? (
            renderHistory
          ) : (
            <div className="normal-18 mt-20 text-center text-gray-500">
              No history found
            </div>
          )}
        </div>
        {noOfHistoryItems === 5 && data?.length > 5 ? (
          <ButtonForModal
            color="orange"
            onClick={() => {
              setNoOfHistoryItems(data?.length);
            }}
          >
            Show More
          </ButtonForModal>
        ) : data?.length > 5 ? (
          <ButtonForModal
            color="orange"
            onClick={() => {
              setNoOfHistoryItems(5);
            }}
          >
            Show Less
          </ButtonForModal>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
