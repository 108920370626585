import React from "react";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import ButtonForModal from "./ButtonForModal";
import "./Model.css";
export default function AutoRefillOff() {
  const { dispatch, setIsOpenPopup } = useSelectPopupPomponent();
  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col sm:text-center">
      <div className="auto-refill w-[calc(134px+13.924vw)]  md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="normal-18 w-[calc(149px+15.521vw)] space-y-3 p-[calc(20px+1.989vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)]  sm:w-full">
        <div className="teal medium-26 sm:px-10">
          The Auto Refill has been Turned OFF
        </div>
        <div>
          You can turn on Auto Refill at any time from the "Manage Plans" page
          for uninterrupted service
        </div>
        <div className="flex">
          <ButtonForModal
            color="teal"
            onClick={() => {
              dispatch(setIsOpenPopup(false));
            }}
          >
            Got it
          </ButtonForModal>
        </div>
      </div>
    </div>
  );
}
