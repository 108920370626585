import React from "react";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import { useLazyGetStripeCustomerDetails_Query } from "../../utils/store";
import ButtonForModal from "./ButtonForModal";
import "./Model.css";
export default function EnableAutoRefillSavePaymentMethode() {
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  const [getStripeCardDetails, stripeCards] =
    useLazyGetStripeCustomerDetails_Query();

  const handleButtonClick = async () => {
    const cardsList = await getStripeCardDetails();
    const defaultCard = cardsList?.data?.filter((card) => {
      return card.default_source;
    });
    if (!cardsList.data?.length) {
      dispatch(setPopupComponent("AddPaymentMethodForAutoRefill"));
    } else if (!defaultCard?.length) {
      dispatch(setPopupComponent("SavedPaymentMethodesAfterEnableAutoRefill"));
    }
  };
  return (
    <div className="flex w-full sm:w-[90vw] sm:flex-col">
      <div className="auto-refill w-[calc(134px+13.924vw)]  md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
      <div className="normal-18 w-[calc(149px+15.521vw)] space-y-3 p-[calc(20px+1.989vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
        <div className="teal medium-26">Enable Auto Refill</div>
        <div>Great Choice to enable Auto Refill!</div>
        <div>
          To enable auto refill, select a default payment card to ensure
          seamless processing of payments
        </div>
        <div>
          <ButtonForModal color="teal" onClick={handleButtonClick}>
            Save Payment Method
          </ButtonForModal>
        </div>
      </div>
    </div>
  );
}
