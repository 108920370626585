import React from "react";
import ButtonForModal from "./ButtonForModal";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { setGeoScope } from "../../utils/store/Slices/modalStateSlice";
import NestedModal from "../../components/NestedModal.component";
import { useState } from "react";
import InfoInCountry from "./InfoInCountry";
import useSelectPopupPomponent from "../../Hooks/use-select-popup-component";
import InstallationInCountry from "./InstallationInCountry";
import InstallationInstructionsInternationalTravel from "./InstallationInstructionsInternationalTravel";
import { useSelector } from "react-redux";
export default function EsimgeographicScope() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDomesticOpen, setIsDomesticOpen] = useState(false);
  const [isInternationalOpen, setIsInternationalOpen] = useState(false);
  const { dispatch, setPopupComponent } = useSelectPopupPomponent();
  const profile = useSelector((state) => state.profile);

  return (
    <div>
      <NestedModal isOpen={isOpen} setIsOpen={setIsOpen} isBackButton={false}>
        <InfoInCountry setIsOpen={setIsOpen} />
      </NestedModal>
      <NestedModal isOpen={isDomesticOpen} setIsOpen={setIsDomesticOpen}>
        <InstallationInCountry setIsOpen={setIsDomesticOpen} />
      </NestedModal>
      <NestedModal
        isOpen={isInternationalOpen}
        setIsOpen={setIsInternationalOpen}
      >
        <InstallationInstructionsInternationalTravel
          setIsOpen={setIsInternationalOpen}
        />
      </NestedModal>
      <div
        className={`${
          isOpen ? "hidden" : "flex sm:flex-col"
        }  w-full sm:w-[90vw] `}
      >
        <div className="save-payment-method  w-[calc(151px+15.729vw)]    md:w-[calc(120px+23.372vw)] sm:h-[149px] sm:w-full"></div>
        <div className="w-[calc(151px+15.729vw)] space-y-4 p-[calc(20px+1.259vw)] md:w-[calc(135px+26.367vw)] md:px-[calc(11px+2.148vw)] md:py-[calc(14px+2.734vw)] sm:w-full">
          <div className="medium-26 teal">IMPORTANT INFORMATION</div>
          <div className="medium-24 teal">{profile?.firstName}:</div>
          <ol className="normal-18 list-inside list-decimal space-y-3">
            <li>
              Install your Ride Mobile eSIM. <br />
              DO NOT DELETE your eSIM after installation
            </li>
            <li>Follow the Settings Instructions on the next screen</li>
            <li>
              Return to the Ride Mobile App to activate your plan on the Manage
              Plans screen
            </li>
          </ol>
          <div className="relative mx-auto w-[70%]">
            <ButtonForModal
              color="teal"
              onClick={() => {
                dispatch(setGeoScope("domestic"));
                setIsDomesticOpen(true);
                // dispatch(setPopupComponent("InstallationInCountry"));
              }}
            >
              In-Country
            </ButtonForModal>
            <AiOutlineQuestionCircle
              className="teal absolute -right-5 top-1/2 -translate-y-1/2 transition-all "
              onClick={() => {
                setIsOpen(true);
              }}
            />
          </div>
          <div className="mx-auto w-[70%]">
            <ButtonForModal
              onClick={() => {
                dispatch(setGeoScope("international"));
                // dispatch(
                //   setPopupComponent("InstallationInstructionsInternationalTravel")
                // );
                setIsInternationalOpen(true);
              }}
              color="teal"
            >
              International Travel
            </ButtonForModal>
          </div>
        </div>
      </div>
    </div>
  );
}
