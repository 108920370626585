import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { endpoints, BASE_URL } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const supportApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      createSupportRequest: builder.mutation({
        query: (body) => {
          return {
            url: endpoints.support.createSupportRequest,
            body: body,
            method: "POST",
          };
        },
      }),
    };
  },
});

export const { useCreateSupportRequestMutation } = supportApi;
export { supportApi };
