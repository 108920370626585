import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { endpoints, BASE_URL } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const authApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      signin: builder.mutation({
        query: ({ email, password }) => ({
          url: endpoints.auth.signin,
          body: { email, password },
          method: "POST",
        }),
      }),
      signup: builder.mutation({
        query: (body) => {
          return {
            url: endpoints.auth.signup,
            body: body,
            method: "POST",
          };
        },
      }),

      forgotPassword: builder.mutation({
        query: (email) => {
          return {
            url: endpoints.auth.forgot,
            body: { email },
            method: "POST",
          };
        },
      }),
      resetPassword: builder.mutation({
        query: ({ newPassword, verifyPassword, otp }) => {
          return {
            url: endpoints.auth.reset,
            body: { otp, newPassword, verifyPassword },
            method: "POST",
          };
        },
      }),
      otpVerifivation: builder.query({
        query: (data) => {
          return {
            url: endpoints.auth.otpVerify(data),
            method: "GET",
          };
        },
      }),
      changePassword: builder.mutation({
        query: (body) => {
          return {
            url: endpoints.auth.changePassword,
            body: body,
            method: "PUT",
          };
        },
      }),
      resend: builder.mutation({
        query: (email) => {
          return {
            url: endpoints.auth.resend,
            body: { email },
            method: "POST",
          };
        },
      }),
      refreshToken: builder.mutation({
        query: (refreshToken) => {
          return {
            url: endpoints.auth.refreshToken,
            body: refreshToken,
            method: "POST",
          };
        },
      }),
      validateOtp: builder.mutation({
        query: ({ otp, email }) => {
          return {
            url: endpoints.auth.validateOtp,
            body: { otp, email },
            method: "POST",
          };
        },
      }),
    };
  },
});

export const {
  useSigninMutation,
  useSignupMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useLazyOtpVerifivationQuery,
  useChangePasswordMutation,
  useResendMutation,
  useRefreshTokenMutation,
  useValidateOtpMutation,
} = authApi;
export { authApi };
